import { createSelector } from 'reselect';

import { ROLE_ADMIN, ROLE_SUPER_ADMIN } from '@utils/enums/roles';
import { isGraphLoading, isMeReportsLoading } from '@modules/reports/selectors';
import { RootState } from '@store/type';

import { MeState } from './reducer';

export const getMe = ({ me }: RootState): MeState => me;

export const isMeLoading = createSelector(getMe, ({ loading }) => loading);
export const getMeError = createSelector(getMe, ({ error }) => error);
export const getMeItem = createSelector(getMe, ({ item }) => item);
export const getMeRole = createSelector(getMeItem, (item) => item?.rol.name);
export const isAdminOrSuperAdmin = createSelector(getMeRole, (role) => {
  if (!role) return false;
  return [ROLE_ADMIN, ROLE_SUPER_ADMIN].includes(role);
});
export const isReportsLoading = createSelector(
  [isGraphLoading, isMeReportsLoading],
  (graphLoading, reportLoading) => graphLoading || reportLoading,
);
export const getMeAbility = createSelector(getMe, ({ ability }) => ability);
export const isAllowSkipNextPriority = createSelector(
  getMeItem,
  (item) => item?.allowSkipNextPriority,
);
