import { createSelector } from 'reselect';

import { RootState } from '@store/type';
import { NextechState } from './reducer';

export const getNextech = ({ nextech }: RootState): NextechState => nextech;
export const getListNextech = createSelector(getNextech, ({ list }) => list);
export const isListSync = createSelector(getListNextech, ({ sync }) => sync);
export const getListNextechIsLoading = createSelector(
  getListNextech,
  ({ loading }) => loading,
);
export const getListNextechError = createSelector(
  getListNextech,
  ({ error }) => error,
);
export const getListNextechItems = createSelector(
  getListNextech,
  ({ items }) => items,
);
export const getListNextechMeta = createSelector(
  getListNextech,
  ({ meta }) => meta,
);
export const getListNextechCount = createSelector(
  getListNextechMeta,
  ({ totalItems }) => totalItems,
);
export const getListNextechLastUpdated = createSelector(
  getListNextech,
  ({ lastUpdated }) => lastUpdated,
);
export const getModalMatches = createSelector(
  getNextech,
  ({ modalMatches }) => modalMatches,
);
export const getModalMatchesIsOpen = createSelector(
  getModalMatches,
  ({ isOpen }) => isOpen,
);
export const getModalMatchesIsOpenNotFound = createSelector(
  getModalMatchesIsOpen,
  ({ notFound }) => notFound,
);
export const getModalMatchesIsOpenManyMatches = createSelector(
  getModalMatchesIsOpen,
  ({ manyMatches }) => manyMatches,
);
export const getModalMatchesIsLoading = createSelector(
  getModalMatches,
  ({ loading }) => loading,
);
export const getModalMatchesIsSubmitting = createSelector(
  getModalMatches,
  ({ submitting }) => submitting,
);
export const getModalMatchesError = createSelector(
  getModalMatches,
  ({ error }) => error,
);
export const getModalMatchesItems = createSelector(
  getModalMatches,
  ({ items }) => items,
);

export const getSyncItems = createSelector(
  getNextech,
  ({ syncItems }) => syncItems,
);

export const isSyncLeadToNextech = createSelector(
  [getSyncItems, (__: RootState, id: string) => id],
  (syncItems, leadId) => syncItems.includes(leadId),
);
