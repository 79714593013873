import produce, { Draft } from 'immer';

import { ReferringPhysicianType } from 'types/referring-physician';
import { MetaType } from 'types/meta';
import { PhysicianSpecialtyType } from 'types/physician-specialty';
import {
  listReferringPhysicians,
  createReferringPhysician,
  mergeReferringPhysician,
  retrieveReferringPhysician,
  toggleReferringPhysicianMergeModal,
  updateReferringPhysician,
  dropdownReferringPhysicians,
  dropdownPhysicianSpecialties,
  externalDropdownReferringPhysicians,
} from './actions';
import { SET_FILTER_REFERRING_PHYSICIAN } from './types';

export type ReferringPhysiciansState = {
  isLoading: boolean;
  error: string | undefined;
  filter: string | undefined;
  item: ReferringPhysicianType | undefined;
  items: ReferringPhysicianType[];
  showMerge: boolean;
  itemMerge: ReferringPhysicianType | undefined;
  meta: MetaType | undefined;
  dropdown: ReferringPhysicianType[];
  dropdownSpecialties: PhysicianSpecialtyType[];
};

const initialState: ReferringPhysiciansState = {
  isLoading: false,
  error: undefined,
  filter: undefined,
  item: undefined,
  items: [],
  showMerge: false,
  itemMerge: undefined,
  meta: undefined,
  dropdown: [],
  dropdownSpecialties: [],
};

const reducer = produce(
  (draft: Draft<ReferringPhysiciansState>, { type, payload }) => {
    switch (type) {
      // TRIGGER
      case listReferringPhysicians.TRIGGER:
      case createReferringPhysician.TRIGGER:
      case updateReferringPhysician.TRIGGER:
      case mergeReferringPhysician.TRIGGER:
      case dropdownReferringPhysicians.TRIGGER:
      case externalDropdownReferringPhysicians.TRIGGER:
      case dropdownPhysicianSpecialties.TRIGGER:
        draft.isLoading = true;
        draft.error = undefined;
        break;
      case retrieveReferringPhysician.TRIGGER:
        draft.isLoading = true;
        draft.error = undefined;
        draft.item = undefined;
        draft.showMerge = false;
        draft.itemMerge = undefined;
        break;

      // SUCCESS
      case listReferringPhysicians.SUCCESS:
        draft.items = payload.items;
        draft.meta = payload.meta;
        break;
      case retrieveReferringPhysician.SUCCESS:
      case createReferringPhysician.SUCCESS:
      case updateReferringPhysician.SUCCESS:
        draft.item = payload;
        break;
      case dropdownReferringPhysicians.SUCCESS:
      case externalDropdownReferringPhysicians.SUCCESS:
        draft.dropdown = payload;
        break;
      case dropdownPhysicianSpecialties.SUCCESS:
        draft.dropdownSpecialties = payload;
        break;

      // FAILURE
      case listReferringPhysicians.FAILURE:
      case retrieveReferringPhysician.FAILURE:
      case createReferringPhysician.FAILURE:
      case updateReferringPhysician.FAILURE:
      case mergeReferringPhysician.FAILURE:
      case dropdownReferringPhysicians.FAILURE:
      case externalDropdownReferringPhysicians.FAILURE:
      case dropdownPhysicianSpecialties.FAILURE:
        draft.error = payload;
        break;

      // FULFILL
      case listReferringPhysicians.FULFILL:
      case retrieveReferringPhysician.FULFILL:
      case createReferringPhysician.FULFILL:
      case updateReferringPhysician.FULFILL:
      case mergeReferringPhysician.FULFILL:
      case dropdownReferringPhysicians.FULFILL:
      case externalDropdownReferringPhysicians.FULFILL:
      case dropdownPhysicianSpecialties.FULFILL:
        draft.isLoading = false;
        break;
      case SET_FILTER_REFERRING_PHYSICIAN:
        draft.filter = payload;
        break;

      // TOSTRING
      case toggleReferringPhysicianMergeModal.toString():
        draft.showMerge = !draft.showMerge;
        break;
      // no default
    }
  },
  initialState,
);

export default reducer;
