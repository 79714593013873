import produce, { Draft } from 'immer';
import { GroupType } from 'types/group';
import { GroupScriptsType } from 'types/group-scripts';

import { ScriptType } from 'types/script';
import { WidgetType } from 'types/widget';
import { SCRIPT_TYPE_GENERAL } from '@utils/enums/type-scripts';
import {
  listScripts,
  retrieveScript,
  createScript,
  updateScript,
  getGroupScript,
  previewScript,
  listWidgets,
  getLeadScriptById,
  generalScript,
} from './actions';
import { CLEAR_LEAD_SCRIPT } from './types';

export type ScriptsState = {
  loading: boolean;
  loadingLead: boolean;
  loadingScript: boolean;
  loadingPreview: boolean;
  loadingWidgets: boolean;
  error: string | undefined;
  item: ScriptType | undefined;
  group: GroupType | undefined;
  general: ScriptType | undefined;
  preview: ScriptType | undefined;
  groupScripts: GroupScriptsType[];
  items: ScriptType[];
  widgets: WidgetType[];
};

const initialState: ScriptsState = {
  loading: false,
  loadingLead: false,
  loadingScript: false,
  loadingPreview: false,
  loadingWidgets: false,
  error: undefined,
  item: undefined,
  group: undefined,
  general: undefined,
  preview: undefined,
  groupScripts: [],
  items: [],
  widgets: [],
};

const reducer = produce((draft: Draft<ScriptsState>, { type, payload }) => {
  switch (type) {
    case generalScript.TRIGGER:
    case getLeadScriptById.TRIGGER:
      draft.loadingScript = true;
      draft.error = undefined;
      break;
    case previewScript.TRIGGER:
      draft.loadingPreview = true;
      draft.error = undefined;
      break;
    case listScripts.TRIGGER:
    case retrieveScript.TRIGGER:
    case createScript.TRIGGER:
    case updateScript.TRIGGER:
    case getGroupScript.TRIGGER:
      draft.loading = true;
      draft.error = undefined;
      break;
    case listWidgets.TRIGGER:
      draft.loadingWidgets = true;
      draft.error = undefined;
      break;

    // SUCCESS
    case listScripts.SUCCESS:
      draft.groupScripts = payload;
      break;
    case retrieveScript.SUCCESS:
    case createScript.SUCCESS:
    case updateScript.SUCCESS:
      draft.item = payload;
      break;
    case getGroupScript.SUCCESS:
      draft.group = payload;
      break;
    case generalScript.SUCCESS:
      draft.general = payload;
      break;
    case previewScript.SUCCESS:
      draft.preview = payload;
      break;
    case listWidgets.SUCCESS:
      draft.widgets = payload;
      break;
    case getLeadScriptById.SUCCESS:
      if (payload.type === SCRIPT_TYPE_GENERAL) {
        draft.general = payload;
      }
      break;

    // FAILURE
    case listScripts.FAILURE:
    case listWidgets.FAILURE:
    case retrieveScript.FAILURE:
    case createScript.FAILURE:
    case updateScript.FAILURE:
    case getGroupScript.FAILURE:
    case generalScript.FAILURE:
    case previewScript.FAILURE:
    case getLeadScriptById.FAILURE:
      draft.error = payload;
      break;

    // FULFILL
    case generalScript.FULFILL:
    case getLeadScriptById.FULFILL:
      draft.loadingLead = false;
      draft.loadingScript = false;
      break;
    case previewScript.FULFILL:
      draft.loadingPreview = false;
      break;
    case listScripts.FULFILL:
    case retrieveScript.FULFILL:
    case createScript.FULFILL:
    case updateScript.FULFILL:
    case getGroupScript.FULFILL:
      draft.loading = false;
      break;
    case listWidgets.FULFILL:
      draft.loadingWidgets = false;
      break;

    case CLEAR_LEAD_SCRIPT:
      draft.loadingLead = false;
      draft.loadingScript = false;
      draft.loadingPreview = false;
      draft.error = undefined;
      draft.general = undefined;
      draft.preview = undefined;
    // no default
  }
}, initialState);

export default reducer;
