/* eslint-disable import/no-cycle */
/* eslint-disable no-useless-catch */
import { routinePromiseWatcherSaga } from 'redux-saga-routines';
import { all, call } from 'redux-saga/effects';
import { spawn } from 'redux-saga-test-plan/matchers';

// @ts-ignore
import authWatch from '@modules/auth/sagas';
// @ts-ignore
import clinicsWatcher from '@modules/clinics/sagas';
// @ts-ignore
import groupsWatcher from '@modules/groups/sagas';
// @ts-ignore
import insurancesWatcher from '@modules/insurances/sagas';
// @ts-ignore
import leadsWatcher from '@modules/leads/sagas';
// @ts-ignore
import locationsWatcher from '@modules/locations/sagas';
// @ts-ignore
import meWatch from '@modules/me/sagas';
// @ts-ignore
import navWatch from '@modules/nav/sagas';
// @ts-ignore
import reportsWatcher from '@modules/reports/sagas';
// @ts-ignore
import sourcesWatcher from '@modules/sources/sagas';
// @ts-ignore
import scriptsWatcher from '@modules/scripts/sagas';
// @ts-ignore
import timezonesWatcher from '@modules/timezones/sagas';
// @ts-ignore
import usersWatcher from '@modules/users/sagas';
// @ts-ignore
import websocketWatcher from '@modules/websocket/sagas';
// @ts-ignore
import externalWatcher from '@modules/external/sagas';
// @ts-ignore
import leadsSatisfactionWatcher from '@modules/leads-satisfaction/sagas';
// @ts-ignore
import physicianReferralsWatcher from '@modules/physician-referral/sagas';
// @ts-ignore
import referringPhysiciansWatcher from '@modules/referring-physician/sagas';
// @ts-ignore
import rolesWatcher from '@modules/roles/sagas';
// @ts-ignore
import tipsWatcher from '@modules/tips/sagas';
// @ts-ignore
import thingsWatcher from '@modules/things/sagas';
// @ts-ignore
import dashboardWatcher from '@modules/dashboard/sagas';
// @ts-ignore
import campaignsWatcher from '@modules/campaigns/sagas';
// @ts-ignore
import listWatcher from '@modules/list/sagas';
// @ts-ignore
import nextechWatcher from '@modules/nextech/sagas';
// @ts-ignore
import nextPriorityWatcher from '@modules/next-priority/sagas';
// @ts-ignore
import waitListWatcher from '@modules/wait-list/sagas';

function* rootSaga() {
  try {
    yield all([
      // external
      call(routinePromiseWatcherSaga),
      spawn(websocketWatcher),
      spawn(navWatch),
      // app
      call(authWatch),
      call(clinicsWatcher),
      call(groupsWatcher),
      call(meWatch),
      call(leadsWatcher),
      call(locationsWatcher),
      call(insurancesWatcher),
      call(sourcesWatcher),
      call(scriptsWatcher),
      call(reportsWatcher),
      call(timezonesWatcher),
      call(usersWatcher),
      call(externalWatcher),
      call(leadsSatisfactionWatcher),
      call(physicianReferralsWatcher),
      call(referringPhysiciansWatcher),
      call(rolesWatcher),
      call(tipsWatcher),
      call(thingsWatcher),
      call(dashboardWatcher),
      call(campaignsWatcher),
      call(listWatcher),
      call(nextechWatcher),
      call(nextPriorityWatcher),
      call(waitListWatcher),
    ]);
    // eslint-disable-next-line sonarjs/no-useless-catch
  } catch (error) {
    throw error;
  }
}

export default rootSaga;
