import IconUpdate from '@assets/images/icon-update.svg';
import localforage from 'localforage';
import { Button, Col, Container, Row } from 'reactstrap';

const ToastUpdated = () => {
  const handleUpdate = async () => {
    localStorage.clear();
    await localforage.clear();
    window.location.reload();
  };
  return (
    <Container>
      <Row>
        <Col xs={3}>
          <img src={IconUpdate} alt="Updated" />
        </Col>
        <Col xs={9}>
          <Row>
            <Col xs={12}>
              <h6>
                <strong>Update available</strong>
                <br />
                <small>
                  There is a new version available, do you want to update now?
                </small>
              </h6>
            </Col>
            <Col xs={12}>
              <Button
                type="button"
                className="ui__button ui__button--primary btn-lg text-uppercase"
                onClick={handleUpdate}
              >
                UPDATE
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};
export default ToastUpdated;
