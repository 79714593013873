/* eslint-disable no-param-reassign */
import {
  call,
  put,
  takeLatest,
  select,
  debounce,
  take,
  cancel,
  cancelled,
  all,
} from 'redux-saga/effects';

import { isDate } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { push } from 'connected-react-router';

import {
  getLeadItem,
  getNotesTemporal,
  isArchive,
  getChangeGroup,
  getChangeSubgroup,
  getLeadGroupId,
  getReferralStatus,
  getScheduleToSave,
  showModalIntersection,
  getIntersectionComment,
  getIntersectionRating,
} from '@modules/leads/selectors';
import { listGroups } from '@modules/groups/actions';
import {
  listInsurances,
  listInsurancesStates,
  dropdownInsurancesStates,
  dropdownInsurances,
} from '@modules/insurances/actions';
import { listTimezones } from '@modules/timezones/actions';
import { listClinics, dropdownClinics } from '@modules/clinics/actions';
import { listParentSources } from '@modules/sources/actions';
import {
  listReferringPhysicians,
  dropdownReferringPhysicians,
  dropdownPhysicianSpecialties,
} from '@modules/referring-physician/actions';
import { restartNextPriority } from '@modules/next-priority/actions';
import { generalScript, getLeadScriptById } from '@modules/scripts/actions';
import { getCurrentLocation, getFilters } from '@modules/nav/selectors';

import SDK from '@utils/api';
import {
  MSG_LEAD_CHANGE_GROUP,
  MSG_LEAD_CREATED,
  MSG_LEAD_RESTART_TRANSFER,
  MSG_LEAD_UPDATED,
} from '@utils/enums/messages';
import WS from '@utils/api/ws';
import checkFieldsDuplicate from '@utils/scripts/check-fields-duplicate';
import renderCustomValues from '@utils/scripts/render-custom-values';

import {
  messageError,
  messageSuccess,
} from '@views/base-components/toast-messages';

import {
  listLeads,
  createLead,
  updateLead,
  createLeadFromScript,
  updateLeadFromScript,
  searchLead,
  retrieveLead,
  allHistoryLead,
  blockingLead,
  unblockingLead,
  changeGroups,
  createNote,
  viewNotes,
  createBulkNote,
  checkDuplicateLead,
  archiveLead,
  updateReferringPhysicianLead,
  updateReferralStatusLead,
  getInterceptChangeGroup,
  getAllRetrieveLeadInfo,
  toggleSchedule,
  overdue,
  toggleIntersectionModal,
} from './actions';

const TIME_ZONE = 'America/New_York';

export function* listLeadsSaga({ payload }) {
  try {
    yield put(listLeads.request());
    const parameters = {
      search: payload.search,
      group: payload.group,
      subgroup: payload.subgroup,
      list: payload.list,
      campaign: payload.campaign,
      page: payload.page,
      perPage: payload.perPage,
    };
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.leads, 'getLeads'], parameters);
    yield put(listLeads.success(body));
  } catch (error) {
    yield put(listLeads.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(listLeads.fulfill());
  }
}

export function* retrieveLeadSaga({ payload: { leadId } }) {
  try {
    yield put(retrieveLead.request());
    const api = yield call([SDK, 'getApi']);
    const { body, status } = yield call([api.leads, 'getLead'], { id: leadId });
    if (status === 204) {
      yield cancel();
    }
    yield put(retrieveLead.success(body));
  } catch (error) {
    yield put(retrieveLead.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    if (yield cancelled()) {
      messageSuccess('Lead archived');
      yield put(push('/app/home'));
      yield cancel();
    } else {
      yield put(retrieveLead.fulfill());
    }
  }
}

export function* checkDuplicateLeadSaga({ payload: { values } }) {
  try {
    yield put(checkDuplicateLead.request());
    const api = yield call([SDK, 'getApi']);
    const data = checkFieldsDuplicate(values);

    for (const value in data) {
      if (isDate(data[value])) {
        const estDate = utcToZonedTime(data[value], TIME_ZONE);
        data[value] = estDate.toISOString();
      }
    }

    const { body } = yield call([api.leads, 'duplicateLead'], {
      firstName: data?.firstName,
      lastName: data?.lastName,
      birthday: data?.birthday || undefined,
      email: data?.email,
      phoneNumber: data?.cellphone,
    });

    if (body.length > 0) {
      yield put(
        checkDuplicateLead.success({ body, isCheck: true, isModal: true }),
      );
    } else {
      yield put(createLeadFromScript({ values }));
      yield take(retrieveLead.SUCCESS);
      const lead = yield select(getLeadItem);
      yield put(
        checkDuplicateLead.success({
          ...lead,
          body,
          isCheck: false,
          isModal: false,
        }),
      );
    }
  } catch (error) {
    yield put(checkDuplicateLead.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(checkDuplicateLead.fulfill());
  }
}

export function* allHistoryLeadSaga({ payload: { leadId } }) {
  try {
    yield put(allHistoryLead.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.leads, 'findAllContactHistories'], {
      id: leadId,
    });
    yield put(allHistoryLead.success(body));
  } catch (error) {
    yield put(allHistoryLead.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(allHistoryLead.fulfill());
  }
}

export function* createLeadSaga({ payload: { values } }) {
  try {
    yield put(createLead.request());
    const requestBody = {
      ...values,
      primaryInsurance: values?.primaryInsurance
        ? values?.primaryInsurance
        : undefined,
      secondaryInsurance: values?.secondaryInsurance
        ? values?.secondaryInsurance
        : undefined,
      location: values?.location ? values?.location : undefined,
    };

    for (const itemBody in requestBody) {
      if (isDate(requestBody[itemBody])) {
        const estDate = utcToZonedTime(requestBody[itemBody], TIME_ZONE);
        requestBody[itemBody] = estDate.toISOString();
      }
    }

    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.leads, 'newLead'], {}, { requestBody });
    const notes = yield select(getNotesTemporal);
    yield put(createBulkNote({ notes, id: body.id }));
    yield put(createLead.success(body));
    messageSuccess(MSG_LEAD_CREATED);
  } catch (error) {
    yield put(createLead.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(createLead.fulfill());
  }
}

export function* updateLeadSaga({ payload: { values: requestBody } }) {
  try {
    yield put(updateLead.request());
    delete requestBody.scheduled;
    const api = yield call([SDK, 'getApi']);

    for (const itemBody in requestBody) {
      if (isDate(requestBody[itemBody])) {
        const estDate = utcToZonedTime(requestBody[itemBody], TIME_ZONE);
        requestBody[itemBody] = estDate.toISOString();
      }
    }

    const { body } = yield call(
      [api.leads, 'updateLead'],
      { id: requestBody.id },
      { requestBody },
    );
    yield put(updateLead.success(body));
    messageSuccess(MSG_LEAD_UPDATED);
  } catch (error) {
    yield put(updateLead.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(updateLead.fulfill());
  }
}

export function* createLeadFromScriptSaga({ payload: { values } }) {
  try {
    const scriptId = values.id;
    // eslint-disable-next-line no-param-reassign
    delete values.id;
    yield put(createLeadFromScript.request());
    const customValues = renderCustomValues(values);

    for (const customValue in customValues) {
      if (isDate(customValues[customValue])) {
        const estDate = utcToZonedTime(customValues[customValue], TIME_ZONE);
        customValues[customValue] = estDate.toISOString();
      }
    }

    const api = yield call([SDK, 'getApi']);
    const { body } = yield call(
      [api.leads, 'createLeadByScript'],
      {},
      { requestBody: { scriptId, customValues } },
    );
    if (body) {
      yield put(retrieveLead.success(body));
      const notes = yield select(getNotesTemporal);
      yield put(createBulkNote({ notes, id: body.id }));
      yield put(createLeadFromScript.success(body));
      messageSuccess(MSG_LEAD_CREATED);
    }
  } catch (error) {
    yield put(createLeadFromScript.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(createLeadFromScript.fulfill());
  }
}

export function* updateLeadFromScriptSaga({ payload: { values } }) {
  try {
    const { scriptId } = values;
    // eslint-disable-next-line no-param-reassign
    delete values.id;
    yield put(updateLeadFromScript.request());
    const customValues = renderCustomValues(values);

    for (const customValue in customValues) {
      if (isDate(customValues[customValue])) {
        const estDate = utcToZonedTime(customValues[customValue], TIME_ZONE);
        customValues[customValue] = estDate.toISOString();
      }
    }

    const { id } = yield select(getLeadItem);
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call(
      [api.leads, 'updateLeadByScript'],
      { id },
      { requestBody: { scriptId, customValues } },
    );
    yield put(updateLeadFromScript.success(body));
    messageSuccess(MSG_LEAD_UPDATED);
  } catch (error) {
    yield put(updateLeadFromScript.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(updateLeadFromScript.fulfill());
  }
}

export function* searchLeadSaga({ payload: value }) {
  try {
    yield put(searchLead.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.leads, 'searchLead'], { value });
    yield put(searchLead.success(body));
  } catch (error) {
    yield put(searchLead.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(searchLead.fulfill());
  }
}

export function* changeGroupAndSubgroupSaga() {
  try {
    yield put(changeGroups.request());
    const { id } = yield select(getLeadItem);

    const previousGroup = yield select(getLeadGroupId);
    const group = yield select(getChangeGroup);
    const subgroup = yield select(getChangeSubgroup);
    const comment = yield select(getIntersectionComment);
    const rating = yield select(getIntersectionRating);
    const dataSchedule = yield select(getScheduleToSave);
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call(
      [api.leads, 'changeGroups'],
      { id },
      {
        requestBody: {
          group,
          subgroup,
          rating,
          comment,
          schedule: dataSchedule,
        },
      },
    );

    if (body) {
      messageSuccess(MSG_LEAD_CHANGE_GROUP);
      if (body?.group?.id !== previousGroup) {
        yield put(generalScript({ leadId: id }));
      }
      yield put(changeGroups.success(body));
      yield put(toggleSchedule(false));
      const showModal = yield select(showModalIntersection);
      if (showModal === true) {
        yield put(toggleIntersectionModal());
      }
      yield put(restartNextPriority());
    }
  } catch (error) {
    yield put(changeGroups.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(changeGroups.fulfill());
  }
}

export function* blockingLeadSaga({ type, payload: { leadId } }) {
  try {
    const socket = yield call([WS, 'getWs']);
    const socketId = socket.id;
    const isSoftDelete = yield select(isArchive);
    if (!isSoftDelete) {
      const api = yield call([SDK, 'getApi']);
      const { body } = yield call([api.leads, 'blocking'], {
        id: leadId,
        socketId,
      });
      yield put(blockingLead.success(body));
    }
  } catch (error) {
    yield put(blockingLead.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(blockingLead.fulfill());
  }
}

export function* unblockingLeadSaga({ type, payload: { leadId } }) {
  const location = yield select(getCurrentLocation);
  try {
    const isSoftDelete = yield select(isArchive);
    if (!isSoftDelete) {
      const api = yield call([SDK, 'getApi']);
      const { body } = yield call([api.leads, 'unblocking'], { id: leadId });
      yield put(unblockingLead.success(body));
    }
  } catch (error) {
    yield put(unblockingLead.failure(error));
    if (location.pathname !== '/app/home') {
      messageError(error?.response?.body?.message);
    }
  } finally {
    yield put(unblockingLead.fulfill());
  }
}

export function* createNoteSaga({ payload: { values: requestBody } }) {
  try {
    const { leadId, content, screen } = requestBody;
    yield put(createNote.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call(
      [api.leads, 'createNote'],
      { id: leadId },
      { requestBody: { content } },
    );
    yield put(createNote.success({ body, screen }));
    yield put(allHistoryLead({ leadId }));
    messageSuccess(MSG_LEAD_RESTART_TRANSFER);
  } catch (error) {
    yield put(createNote.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(createNote.fulfill());
  }
}

export function* viewNotesSaga({ payload }) {
  try {
    yield put(viewNotes.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.leads, 'getNotes'], { id: payload });
    yield put(viewNotes.success(body));
  } catch (error) {
    yield put(viewNotes.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(viewNotes.fulfill());
  }
}

export function* createBulkNotesSaga({ payload: { notes, id } }) {
  try {
    yield put(createBulkNote.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call(
      [api.notes, 'createBulkNotes'],
      { id },
      { requestBody: notes },
    );
    yield put(createBulkNote.success(body));
  } catch (error) {
    yield put(createBulkNote.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(createBulkNote.fulfill());
  }
}

export function* archiveLeadSaga({ payload: { leadId } }) {
  try {
    yield put(archiveLead.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.leads, 'archiveLead'], {
      id: leadId,
    });
    if (body) {
      yield put(archiveLead.success(true));
      yield put(push(`/app/home`));
    } else {
      yield put(archiveLead.success(false));
    }
  } catch (error) {
    yield put(archiveLead.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(archiveLead.fulfill());
  }
}

export function* updateReferringPhysicianLeadSaga({ payload: { values } }) {
  try {
    yield put(updateReferringPhysicianLead.request());
    const { id } = yield select(getLeadItem);
    const api = yield call([SDK, 'getApi']);
    const requestBody = {
      ...values,
      physicianName: values.physicianName.label,
    };
    const { body } = yield call(
      [api.leads, 'updateReferringPhysicianLead'],
      { id },
      { requestBody },
    );
    yield put(updateReferringPhysicianLead.success(body));
    messageSuccess(MSG_LEAD_UPDATED);
  } catch (error) {
    yield put(updateReferringPhysicianLead.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(updateReferringPhysicianLead.fulfill());
  }
}

export function* updateReferralStatusLeadSaga() {
  try {
    yield put(updateReferralStatusLead.request());
    const { id } = yield select(getLeadItem);
    const status = yield select(getReferralStatus);
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call(
      [api.leads, 'updateReferralStatus'],
      { id },
      { requestBody: status },
    );
    yield put(updateReferralStatusLead.success(body));
    messageSuccess(MSG_LEAD_UPDATED);
  } catch (error) {
    yield put(updateReferralStatusLead.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(updateReferralStatusLead.fulfill());
  }
}

export function* getInterceptChangeGroupSaga() {
  try {
    yield put(getInterceptChangeGroup.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call(
      [api['system-metadata'], 'getKeySystemMetadata'],
      { key: 'intercept_change_group' },
    );
    yield put(getInterceptChangeGroup.success(body));
  } catch (error) {
    yield put(getInterceptChangeGroup.failure(error));
  } finally {
    yield put(getInterceptChangeGroup.fulfill());
  }
}

export function* getLeadIsOverdueSaga({ payload: { id } }) {
  try {
    yield put(overdue.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.leads, 'getLeadIsOverdue'], { id });
    yield put(overdue.success(body));
  } catch (error) {
    yield put(overdue.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(overdue.fulfill());
  }
}

export function* getAllRetrieveLeadInfoSaga({ payload: { leadId } }) {
  const filters = yield select(getFilters);
  yield put(blockingLead({ leadId }));
  yield take(blockingLead.FULFILL);

  if (filters?.tab === 'script-general') {
    yield all([
      put(getLeadScriptById({ leadId })),
      put(generalScript({ leadId })),
      put(dropdownInsurances()),
      put(listInsurancesStates()),
      put(listInsurances()),
      put(listClinics()),
    ]);
  }

  if (filters?.tab === 'referring-physician') {
    yield all([
      put(dropdownReferringPhysicians()),
      put(dropdownPhysicianSpecialties()),
      put(listReferringPhysicians()),
    ]);
  }

  yield put(retrieveLead({ leadId }));
  yield take(retrieveLead.SUCCESS);
  yield all([
    put(listGroups({ id: leadId })),
    put(allHistoryLead({ leadId })),
    put(dropdownClinics()),
    put(dropdownInsurancesStates()),
    put(listTimezones()),
    put(listParentSources()),
    put(getInterceptChangeGroup()),
  ]);
}

export default function* leadsWatcher() {
  yield debounce(500, listLeads.TRIGGER, listLeadsSaga);
  yield takeLatest(createLead.TRIGGER, createLeadSaga);
  yield takeLatest(updateLead.TRIGGER, updateLeadSaga);
  yield takeLatest(createLeadFromScript.TRIGGER, createLeadFromScriptSaga);
  yield takeLatest(updateLeadFromScript.TRIGGER, updateLeadFromScriptSaga);
  yield takeLatest(searchLead.TRIGGER, searchLeadSaga);
  yield takeLatest(retrieveLead.TRIGGER, retrieveLeadSaga);
  yield takeLatest(allHistoryLead.TRIGGER, allHistoryLeadSaga);
  yield takeLatest(blockingLead.TRIGGER, blockingLeadSaga);
  yield takeLatest(unblockingLead.TRIGGER, unblockingLeadSaga);
  yield takeLatest(changeGroups.TRIGGER, changeGroupAndSubgroupSaga);
  yield takeLatest(createNote.TRIGGER, createNoteSaga);
  yield takeLatest(createBulkNote.TRIGGER, createBulkNotesSaga);
  yield takeLatest(viewNotes.TRIGGER, viewNotesSaga);
  yield takeLatest(checkDuplicateLead.TRIGGER, checkDuplicateLeadSaga);
  yield takeLatest(archiveLead.TRIGGER, archiveLeadSaga);
  yield takeLatest(
    updateReferringPhysicianLead.TRIGGER,
    updateReferringPhysicianLeadSaga,
  );
  yield takeLatest(
    updateReferralStatusLead.TRIGGER,
    updateReferralStatusLeadSaga,
  );
  yield takeLatest(
    getInterceptChangeGroup.TRIGGER,
    getInterceptChangeGroupSaga,
  );
  yield takeLatest(getAllRetrieveLeadInfo.TRIGGER, getAllRetrieveLeadInfoSaga);
  yield takeLatest(overdue.TRIGGER, getLeadIsOverdueSaga);
}
