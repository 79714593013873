import produce, { Draft } from 'immer';
import { LeadType } from 'types/lead';
import { MetaType } from 'types/meta';
import { listLeadsSatisfaction } from './actions';

export type LeadsSatisfactionState = {
  isLoading: boolean;
  error: string | undefined;
  items: LeadType[];
  meta: MetaType;
};

const initialState: LeadsSatisfactionState = {
  isLoading: false,
  error: undefined,
  items: [],
  meta: {
    totalItems: 0,
    itemCount: 0,
    itemsPerPage: 10,
    totalPages: 0,
    currentPage: 1,
  },
};

const reducer = produce(
  (draft: Draft<LeadsSatisfactionState>, { type, payload }) => {
    switch (type) {
      case listLeadsSatisfaction.TRIGGER:
        draft.isLoading = true;
        draft.error = undefined;
        break;
      case listLeadsSatisfaction.SUCCESS:
        draft.meta = payload.meta;
        draft.items = payload.items;
        draft.error = undefined;
        break;
      case listLeadsSatisfaction.FAILURE:
        draft.error = payload;
        break;
      case listLeadsSatisfaction.FULFILL:
        draft.isLoading = false;
        break;
      // no default
    }
  },
  initialState,
);

export default reducer;
