import produce, { Draft } from 'immer';
import { TimezoneType } from 'types/timezone';

import { listTimezones, getTimezone } from './actions';

export type TimezonesState = {
  loading: boolean;
  error: string | undefined;
  current: TimezoneType | undefined;
  items: TimezoneType[];
};

const initialState: TimezonesState = {
  loading: false,
  error: undefined,
  current: undefined,
  items: [],
};

const reducer = produce((draft: Draft<TimezonesState>, { type, payload }) => {
  switch (type) {
    case listTimezones.TRIGGER:
    case getTimezone.TRIGGER:
      draft.loading = true;
      draft.error = undefined;
      break;

    // SUCCESS
    case getTimezone.SUCCESS:
      draft.current = payload;
      break;
    case listTimezones.SUCCESS:
      draft.items = payload;
      break;

    // FAILURE
    case listTimezones.FAILURE:
    case getTimezone.FAILURE:
      draft.error = payload;
      break;

    // FULFILL
    case listTimezones.FULFILL:
    case getTimezone.FULFILL:
      draft.loading = false;
      break;
    // no default
  }
}, initialState);

export default reducer;
