import produce, { Draft } from 'immer';
import { closePopup, openPopup } from './actions';

export type IdleState = {
  isOpen: boolean;
};

const initialState: IdleState = {
  isOpen: false,
};

const reducer = produce((draft: Draft<IdleState>, { type }) => {
  switch (type) {
    case openPopup.TRIGGER:
      draft.isOpen = true;
      break;
    case closePopup.TRIGGER:
      draft.isOpen = false;
      break;
    default:
      break;
  }
}, initialState);

export default reducer;
