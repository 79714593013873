const PREFIX = 'REFERRING_PHYSICIANS';
const PREFIX_SPECIALTIES = 'PHYSICIAN_SPECIALTIES';

export const LIST_REFERRING_PHYSICIANS = `${PREFIX}/LIST`;
export const RETRIEVE_REFERRING_PHYSICIAN = `${PREFIX}/RETRIEVE`;
export const CREATE_REFERRING_PHYSICIAN = `${PREFIX}/CREATE`;
export const UPDATE_REFERRING_PHYSICIAN = `${PREFIX}/UPDATE`;
export const SET_FILTER_REFERRING_PHYSICIAN = `${PREFIX}/SET_FILTER`;
export const MODAL_MERGE_REFERRING_PHYSICIAN = `${PREFIX}/MODAL_MERGE`;
export const MERGE_REFERRING_PHYSICIAN = `${PREFIX}/MERGE`;
export const DROPDOWN_REFERRING_PHYSICIANS = `${PREFIX}/DROPDOWN`;
export const EXTERNAL_DROPDOWN_REFERRING_PHYSICIANS = `${PREFIX}/EXTERNAL_DROPDOWN`;

export const DROPDOWN_PHYSICIAN_SPECIALTIES = `${PREFIX_SPECIALTIES}/DROPDOWN`;
