import { createRoutine, bindRoutineToReduxForm } from 'redux-saga-routines';
import {
  LIST_USERS,
  RETRIEVE_USER,
  CREATE_USER,
  UPDATE_USER,
  ENABLED_USER,
  DROPDOWN_USERS,
  DROPDOWN_USER_DEPARTMENTS,
  DROPDOWN_USER_LOCATIONS,
} from './types';

export const listUsers = createRoutine(LIST_USERS);
export const retrieveUser = createRoutine(RETRIEVE_USER);
export const createUser = createRoutine(CREATE_USER);
export const updateUser = createRoutine(UPDATE_USER);
export const enabledUser = createRoutine(ENABLED_USER);
export const dropdownUsers = createRoutine(DROPDOWN_USERS);
export const dropdownUserDepartments = createRoutine(DROPDOWN_USER_DEPARTMENTS);
export const dropdownUserLocations = createRoutine(DROPDOWN_USER_LOCATIONS);

export const bindedCreateUser = bindRoutineToReduxForm(createUser);
export const bindedUpdateUser = bindRoutineToReduxForm(updateUser);
