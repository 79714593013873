const PREFIX = 'INSURANCES';
const PREFIX_STATES = `${PREFIX}_STATES`;
const PREFIX_TYPES = `${PREFIX}_TYPES`;

export const LIST_INSURANCES_STATES = `${PREFIX_STATES}/LIST`;
export const RETRIEVE_INSURANCE_STATE = `${PREFIX_STATES}/RETRIEVE`;
export const CREATE_INSURANCE_STATE = `${PREFIX_STATES}/CREATE`;
export const UPDATE_INSURANCE_STATE = `${PREFIX_STATES}/UPDATE`;
export const SET_FILTER_INSURANCE_STATE = `${PREFIX_STATES}/SET_FILTER`;
export const DROPDOWN_INSURANCES_STATES = `${PREFIX_STATES}/DROPDOWN`;
export const EXTERNAL_DROPDOWN_INSURANCES_STATES = `${PREFIX_STATES}/EXTERNAL_DROPDOWN`;

export const LIST_INSURANCES = `${PREFIX}/LIST`;
export const RETRIEVE_INSURANCE = `${PREFIX}/RETRIEVE`;
export const CREATE_INSURANCE = `${PREFIX}/CREATE`;
export const UPDATE_INSURANCE = `${PREFIX}/UPDATE`;
export const SET_FILTER_INSURANCE = `${PREFIX}/SET_FILTER`;
export const DROPDOWN_INSURANCES = `${PREFIX}/DROPDOWN`;

export const LIST_INSURANCES_TYPES = `${PREFIX_TYPES}/LIST`;
