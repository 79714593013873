const PREFIX = 'GROUPS';
const PREFIX_SUBGROUPS = 'SUBGROUPS';

export const LIST_GROUPS = `${PREFIX}/LIST`;
export const CREATE_GROUP = `${PREFIX}/CREATE`;
export const RETRIEVE_GROUP = `${PREFIX}/RETRIEVE`;
export const UPDATE_GROUP = `${PREFIX}/UPDATE`;
export const DELETE_GROUP = `${PREFIX}/DELETE`;
export const BULK_GROUPS = `${PREFIX}/BULK`;

export const LIST_SUBGROUPS = `${PREFIX_SUBGROUPS}/LIST`;
export const CREATE_SUBGROUP = `${PREFIX_SUBGROUPS}/CREATE`;
export const RETRIEVE_SUBGROUP = `${PREFIX_SUBGROUPS}/RETRIEVE`;
export const UPDATE_SUBGROUP = `${PREFIX_SUBGROUPS}/UPDATE`;
export const DELETE_SUBGROUP = `${PREFIX_SUBGROUPS}/DELETE`;

export const LIST_GROUPS_AND_SUBGROUPS = `${PREFIX}/LIST_GROUPS_AND_SUBGROUPS`;
