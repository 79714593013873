import { call, put, select, takeEvery } from 'redux-saga/effects';
import { getCurrentLocation, getFilters } from '@modules/nav/selectors';
import {
  MSG_NEXTECH_SYNC_SUCCESS,
  MSG_NEXTECH_SYNC_FAILED,
  MSG_NEXTECH_SYNC_FINISHED,
} from '@utils/enums/messages';
import {
  messageError,
  messageSuccess,
} from '@views/base-components/toast-messages';
import SDK from '@utils/api';
import WS from '@utils/api/ws';
import {
  listNextech,
  listSyncInfo,
  listSyncNextech,
  listSyncsNextech,
  modalClean,
  modalOpenSetNotFound,
  modalTriggerNotFound,
  syncManualAll,
  syncManualItemsFinish,
} from './actions';
import { getListNextechItems } from './selectors';

export function* getListNextechSaga() {
  try {
    yield put(listNextech.request());
    const filters = yield select(getFilters);
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.leads, 'getLeadsNextech'], filters);
    yield put(listNextech.success(body));
  } catch (error) {
    yield put(listNextech.failure(error));
  } finally {
    yield put(listNextech.fulfill());
  }
}

export function* getListInfoSaga() {
  try {
    yield put(listSyncInfo.request());

    const api = yield call([SDK, 'getApi']);
    const { body } = yield call(
      [api['system-metadata'], 'getKeySystemMetadata'],
      {
        key: 'nextech',
      },
    );
    yield put(listSyncInfo.success(body));
  } catch (error) {
    yield put(listSyncInfo.failure(error));
  } finally {
    yield put(listSyncInfo.fulfill());
  }
}

export function* syncAllLeadsSaga() {
  try {
    yield put(listSyncsNextech.request());
    const socket = yield call([WS, 'getWs']);
    socket.emit('sync-nextech-all-leads');
    yield put(listSyncsNextech.success());
  } catch (error) {
    yield put(listSyncsNextech.failure(error));
  } finally {
    yield put(listSyncsNextech.fulfill());
  }
}

export function* syncLeadAllNotificationSaga({ payload }) {
  const location = yield select(getCurrentLocation);
  if (
    payload.syncManualAll === false &&
    location.pathname === '/app/nextech-issue'
  ) {
    yield call(messageSuccess, MSG_NEXTECH_SYNC_FINISHED);
  }
}

export function* syncLeadByIdSaga({ payload }) {
  try {
    yield put(listSyncNextech.request());
    const socket = yield call([WS, 'getWs']);
    socket.emit('sync-nextech-lead', { id: payload });
    yield put(listSyncNextech.success());
  } catch (error) {
    yield put(listSyncNextech.failure(error));
  } finally {
    yield put(listSyncNextech.fulfill());
  }
}

export function* syncLeadFinishSaga({ payload }) {
  const location = yield select(getCurrentLocation);
  if (location.pathname === '/app/nextech-issue') {
    if (payload.isSyncing === true) {
      const leads = yield select(getListNextechItems);
      const lead = leads.find((item) => item.id === payload.id);
      yield call(messageSuccess, MSG_NEXTECH_SYNC_SUCCESS);
      if (lead) {
        yield call(listNextech);
      }
    } else {
      yield call(messageError, MSG_NEXTECH_SYNC_FAILED, true);
    }
  }
}

export function* modalNotFoundSaga({ payload }) {
  try {
    yield put(listNextech.request());
    yield put(modalClean());
    yield put(
      modalOpenSetNotFound({
        isOpen: true,
        leadId: payload,
      }),
    );
  } catch (error) {
    yield put(listNextech.failure(error));
  } finally {
    yield put(listNextech.fulfill());
  }
}

export default function* nextechWatcher() {
  yield takeEvery(listNextech.TRIGGER, getListNextechSaga);
  yield takeEvery(listSyncInfo.TRIGGER, getListInfoSaga);
  yield takeEvery(listSyncsNextech.TRIGGER, syncAllLeadsSaga);
  yield takeEvery(listSyncNextech.TRIGGER, syncLeadByIdSaga);
  yield takeEvery(modalTriggerNotFound.toString(), modalNotFoundSaga);
  yield takeEvery(syncManualItemsFinish.toString(), syncLeadFinishSaga);
  yield takeEvery(syncManualAll.toString(), syncLeadAllNotificationSaga);
}
