import { call, put, takeLatest, select, debounce } from 'redux-saga/effects';
import { SubmissionError } from 'redux-form';
import { push } from 'connected-react-router';
import * as Sentry from '@sentry/react';
import LogRocket from 'logrocket';

import { retrieveMe } from '@modules/me/actions';
import { getUserItem } from '@modules/users/selectors';

import SDK from '@utils/api';
import WS from '@utils/api/ws';
import { caslCreateAbilityForUser } from '@utils/casl/ability';
import { messageError } from '@views/base-components/toast-messages';

import { getAuthRefreshToken } from './selectors';
import { authLoginSSO, authLogout, authRefreshToken } from './actions';

export function* authLoginSSOSaga({ payload }) {
  try {
    yield put(authLoginSSO.request());
    const api = yield call([SDK, 'getApi']);
    const requestBody = { token: payload };
    const { body } = yield call([api.auth, 'loginSSO'], {}, { requestBody });
    if (body) {
      const { user, accessToken, refreshToken, permissions } = body;
      const ability = caslCreateAbilityForUser(permissions);

      yield call([Sentry, 'setUser'], user);
      yield call([SDK, 'setToken'], accessToken);
      yield call([WS, 'setToken'], accessToken);
      yield call([LogRocket, 'identify'], user.id, {
        name: `${user.firstName} ${user.lastName}`,
        email: user.email,
        rol: user.rol?.name,
      });
      yield put(retrieveMe.success({ user, ability }));
      yield put(
        authLoginSSO.success({
          accessToken,
          refreshToken,
        }),
      );
      yield user.rol?.setting?.path !== ''
        ? put(push(user.rol?.setting?.path))
        : put(push(`/app/dashboard`));
    }
  } catch (error) {
    yield put(
      authLoginSSO.failure(
        new SubmissionError({ _error: error?.response?.body?.message }),
      ),
    );
    messageError(error?.response?.body?.message);
  } finally {
    yield put(authLoginSSO.fulfill());
  }
}

export function* authRefreshTokenSaga() {
  try {
    yield put(authRefreshToken.request());
    const token = yield select(getAuthRefreshToken);
    if (!token) return;
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call(
      [api.auth, 'refreshToken'],
      {},
      { requestBody: { token } },
    );
    if (body) {
      const { user, accessToken, refreshToken, permissions } = body;
      const ability = caslCreateAbilityForUser(permissions);

      yield call([Sentry, 'setUser'], user);
      yield call([SDK, 'setToken'], refreshToken);
      yield call([WS, 'setToken'], refreshToken);
      yield put(retrieveMe.success({ user, ability }));
      yield put(
        authRefreshToken.success({
          accessToken,
          refreshToken,
        }),
      );
    }
  } catch (error) {
    yield put(
      authRefreshToken.failure(
        new SubmissionError({ _error: error?.response?.body?.message }),
      ),
    );
    messageError(error?.response?.body?.message);
  } finally {
    yield put(authRefreshToken.fulfill());
  }
}

export function* authLogoutSaga() {
  try {
    yield put(authLogout.request());
    const user = yield select(getUserItem);
    if (user) {
      const api = yield call([SDK, 'getApi']);
      yield call([api.auth, 'logout', { id: user.id }]);
    }
    yield call([Sentry, 'setUser'], {});
    yield call([SDK, 'setToken'], false);
    yield call([WS, 'setToken'], false);
    yield put(authLogout.success());
  } catch (error) {
    yield put(authLogout.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(authLogout.fulfill());
    yield put({ type: 'CLEAR_STORE' });
    yield put(push('/'));
  }
}

export default function* authWatch() {
  yield takeLatest(authLoginSSO.TRIGGER, authLoginSSOSaga);
  yield takeLatest(authLogout.TRIGGER, authLogoutSaga);
  // TODO: 60_000 = 1 min
  yield debounce(240_000, authRefreshToken.TRIGGER, authRefreshTokenSaga);
}
