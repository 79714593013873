import { createSelector } from 'reselect';
import {
  addDays,
  addMinutes,
  differenceInMinutes,
  format,
  getMinutes,
  isAfter,
  isBefore,
  isToday,
  set,
} from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

import { getMe } from '@modules/me/selectors';
import { getFilters } from '@modules/nav/selectors';
import { RootState } from '@store/type';
import { FORMAT_DEFAULT_US, FORMAT_DATE_LONG } from '@utils/enums/format';
import {
  TAG_SAVE,
  TAG_GROUP_RESTARTED,
  TAG_GROUP_CHANGED,
} from '@utils/enums/tag';

import { LeadScheduleType } from 'types/lead';
import { LeadsState } from './reducer';

export const getLeads = ({ leads }: RootState): LeadsState => leads;

export const isLeadsLoading = createSelector(
  getLeads,
  ({ loading }) => loading,
);
export const getLeadsError = createSelector(getLeads, ({ error }) => error);
export const getLeadsItems = createSelector(getLeads, ({ items }) => items);
export const getLeadsPagination = createSelector(getLeads, ({ meta }) => meta);
export const getLeadItem = createSelector(getLeads, ({ item }) => item);
export const getLeadFilter = createSelector(getLeads, ({ filter }) => filter);

export const getEmptyState = createSelector(
  [getLeadsItems, getFilters],
  (items, filters) => {
    const { search = '', group = '', subgroup = '' } = filters;
    return [items, search, group, subgroup].every(
      (filter) => filter.length === 0,
    );
  },
);
export const getEmptyStateByFilters = createSelector(
  [getLeadsItems, getFilters],
  (items, filters) => {
    const { search = '', group = '', subgroup = '' } = filters;
    const isFilter = [search, group, subgroup].some(
      (filter) => filter.length > 0,
    );
    return items?.length === 0 && isFilter === true;
  },
);

export const isNotesLoading = createSelector(
  getLeads,
  ({ notes }) => notes.loading,
);
export const getNotesItems = createSelector(
  getLeads,
  ({ notes }) => notes.items,
);
export const isOpenViewNotes = createSelector(
  getLeads,
  ({ notes }) => notes.show,
);
export const getNotesTemporal = createSelector(
  getLeads,
  ({ notes }) => notes.temporal,
);
export const getOpenLeadId = createSelector(
  getLeads,
  ({ notes }) => notes.leadId,
);

export const isDuplicateOpen = createSelector(
  getLeads,
  ({ duplicate }) => duplicate.show,
);
export const getDuplicateItems = createSelector(
  getLeads,
  ({ duplicate }) => duplicate.items,
);

export const isArchiveLoading = createSelector(
  getLeads,
  ({ archive }) => archive.loading,
);
export const isArchive = createSelector(
  getLeads,
  ({ archive }) => archive.archived,
);

export const isScheduling = createSelector(
  getLeads,
  ({ schedule }) => schedule.show,
);
export const getScheduleItem = createSelector(
  getLeads,
  ({ schedule }) => schedule.item,
);
export const getScheduledDate = createSelector(
  getLeads,
  ({ item, histories }) => {
    if (item?.scheduled) {
      return `${format(
        new Date(`${item.scheduled.date}T00:00:00`),
        FORMAT_DATE_LONG,
      )} AT BETWEEN ${item.scheduled.startHour} AND ${item.scheduled.endHour}`;
    }

    let dateNextContact = new Date();
    const latestHistory = histories.items
      .filter(({ tag }) => [TAG_GROUP_CHANGED].includes(tag as string))
      // @ts-ignore
      .sort((a, b) => b.createdAt > a.createdAt)
      .find((_, index) => index === 0);

    if (latestHistory?.createdAt && item?.subgroup?.scheduleDayDifference) {
      const now = new Date();
      const scheduleDayDifference = Number(
        item?.subgroup?.scheduleDayDifference,
      );
      const nowDate = format(now, FORMAT_DEFAULT_US);
      const lastDateChange = format(
        new Date(latestHistory?.createdAt),
        FORMAT_DEFAULT_US,
      );

      if (nowDate === lastDateChange) {
        dateNextContact = addDays(now, scheduleDayDifference);
      }
    }

    return `${format(dateNextContact, FORMAT_DATE_LONG)} AT BETWEEN ${
      item?.subgroup?.scheduleStartHour || '--'
    } AND ${item?.subgroup?.scheduleEndHour || '--'}`;
  },
);

export const getAllHistory = createSelector(getLeads, ({ histories }) =>
  histories.items
    // @ts-ignore
    .filter(({ tag }) => ![TAG_SAVE].includes(tag))
    // @ts-ignore
    .sort((a, b) => b.createdAt > a.createdAt),
);
export const getScriptHistory = createSelector(getLeads, ({ histories }) =>
  histories.items
    // @ts-ignore
    .filter(({ tag }) => [TAG_SAVE].includes(tag))
    // @ts-ignore
    .sort((a, b) => b.createdAt > a.createdAt),
);
export const getGroupHistory = createSelector(getLeads, ({ histories }) =>
  histories.items
    // @ts-ignore
    .filter(({ tag }) => [TAG_GROUP_RESTARTED, TAG_GROUP_CHANGED].includes(tag))
    // @ts-ignore
    .sort((a, b) => b.createdAt > a.createdAt),
);
export const isBlocked = createSelector(
  [getLeads, getMe],
  ({ item }, { item: me }) =>
    item?.blocking && item?.blockingUser?.id !== me?.id,
);
export const isBlockedBy = createSelector(
  [getLeads, getMe],
  ({ item }, { item: me }) =>
    item?.blocking && item?.blockingUser?.id !== me?.id
      ? item?.blockingUser
      : undefined,
);

export const getChangeGroup = createSelector(
  getLeads,
  ({ changeGroupAndSubgroup }) => changeGroupAndSubgroup.groupId,
);
export const getChangeSubgroup = createSelector(
  getLeads,
  ({ changeGroupAndSubgroup }) => changeGroupAndSubgroup.subgroupId,
);
export const getLeadGroupId = createSelector(
  getLeadItem,
  (item) => item?.group?.id,
);
export const getLeadSubgroupId = createSelector(
  getLeadItem,
  (item) => item?.subgroup?.id,
);
export const isChangeGroup = createSelector(
  [getLeadGroupId, getChangeGroup],
  (groupId, changeGroupId) => groupId !== changeGroupId,
);
export const isChangeSubgroup = createSelector(
  [getLeadSubgroupId, getChangeSubgroup],
  (subgroupId, changeSubgroupId) =>
    changeSubgroupId && subgroupId !== changeSubgroupId,
);
export const showButtonChangeGroup = createSelector(
  [isChangeGroup, isChangeSubgroup, getChangeSubgroup],
  (changeGroup, changeSubgroup, changeSubgroupId) =>
    (changeGroup && changeSubgroupId) || changeSubgroup,
);
export const isValidReschedule = createSelector(
  [getLeadSubgroupId, getChangeSubgroup, getScheduleItem],
  (subgroupId, secondaryGroup, infoSchedule) =>
    !infoSchedule?.selectDate ||
    !infoSchedule?.timezone ||
    subgroupId === secondaryGroup ||
    subgroupId === undefined,
);
export const getInterceptChangeGroup = createSelector(
  getLeads,
  ({ changeGroupAndSubgroup }) => changeGroupAndSubgroup.intercept,
);
export const getActionInterceptChangeGroup = createSelector(
  [getInterceptChangeGroup, getChangeSubgroup],
  (interceptChangeGroup, changeSubgroupId) => {
    if (changeSubgroupId === undefined) {
      return;
    }

    let action: string | undefined;
    for (const groupChanges in interceptChangeGroup) {
      if (interceptChangeGroup[groupChanges].includes(changeSubgroupId)) {
        action = groupChanges;
        break;
      }
    }
    return action;
  },
);

export const getReferralStatus = createSelector(
  getLeads,
  ({ referralStatus }) => referralStatus,
);
export const showButtonReferralStatus = createSelector(
  [getLeadItem, getReferralStatus],
  (item, status) => status && item?.medworkReferralStatus !== status,
);

export const isBlockedToSent = createSelector(
  getLeads,
  ({ item }) => !!(item?.medworkAt || item?.medworkId),
);
export const disableByBlock = createSelector(
  [isBlockedToSent, isBlocked, getLeadSubgroupId],
  (isBlockedToSentMed, isBlockedLead, subgroupId) =>
    isBlockedToSentMed || isBlockedLead || subgroupId === undefined,
);

export const getOverdue = createSelector(
  getLeads,
  ({ overdue }) => overdue.item,
);

export const isIntersectionLoading = createSelector(
  getLeads,
  ({ intersection }) => intersection.loading,
);
export const showModalIntersection = createSelector(
  getLeads,
  ({ intersection }) => intersection.show,
);
export const getIntersectionComment = createSelector(
  getLeads,
  ({ intersection }) => intersection.comment,
);
export const getIntersectionRating = createSelector(
  getLeads,
  ({ intersection }) => intersection.rating,
);

export const isCopyPhone = createSelector(
  getLeads,
  ({ copyPhone }) => copyPhone.copied,
);
export const showCopyPhoneTooltip = createSelector(
  getLeads,
  ({ copyPhone }) => copyPhone.show,
);

export const getIsOpenLocationTooltip = createSelector(
  getLeads,
  ({ isOpenLocationTooltip }) => isOpenLocationTooltip,
);

export const getInfoSchedule = createSelector(
  getLeads,
  ({ schedule }) => schedule.item,
);

export const getScheduleTimezone = createSelector(getInfoSchedule, (schedule) =>
  schedule?.timezone ? [schedule.timezone] : undefined,
);
export const getScheduleDate = createSelector(getInfoSchedule, (schedule) => {
  const { selectDate, timezone } = schedule as LeadScheduleType;
  const currentDate = timezone
    ? utcToZonedTime(new Date(), timezone.name)
    : new Date();
  let minTime;
  let maxTime;
  let adjustedDate = selectDate ?? currentDate;

  const isTodaySelected = isToday(adjustedDate);

  if (isTodaySelected) {
    minTime = currentDate;
    maxTime = set(currentDate, { hours: 20, minutes: 0, seconds: 0 });
    const diffInMinutes =
      Math.ceil(differenceInMinutes(adjustedDate, minTime) / 30) * 30;

    if (diffInMinutes < 30 && isBefore(adjustedDate, maxTime)) {
      const currentMinutes = getMinutes(currentDate);
      const minutesToAdd = 30 - (currentMinutes % 30);
      adjustedDate = addMinutes(minTime, minutesToAdd);
    }
  } else {
    minTime = set(adjustedDate, { hours: 7, minutes: 0, seconds: 0 });
    maxTime = set(adjustedDate, { hours: 20, minutes: 0, seconds: 0 });

    if (isBefore(adjustedDate, minTime)) {
      adjustedDate = minTime;
    } else if (isAfter(adjustedDate, maxTime)) {
      adjustedDate = maxTime;
    }
  }

  return {
    currentDate,
    selectDate: selectDate ? adjustedDate : undefined,
    minTime,
    maxTime,
  };
});

export const getScheduleToSave = createSelector(
  [getInfoSchedule, isScheduling],
  (scheduleItem, scheduling) => {
    const { timezone, selectDate, preferredHour } =
      scheduleItem as LeadScheduleType;

    if (!scheduling && !selectDate) {
      return;
    }
    return {
      scheduled: selectDate ? format(selectDate, 'yyyy-MM-dd') : undefined,
      preferredHour,
      timezone: timezone?.name,
    };
  },
);
