import { createRoutine, bindRoutineToReduxForm } from 'redux-saga-routines';
import {
  LIST_INSURANCES_STATES,
  RETRIEVE_INSURANCE_STATE,
  CREATE_INSURANCE_STATE,
  UPDATE_INSURANCE_STATE,
  DROPDOWN_INSURANCES_STATES,
  LIST_INSURANCES,
  RETRIEVE_INSURANCE,
  CREATE_INSURANCE,
  UPDATE_INSURANCE,
  LIST_INSURANCES_TYPES,
  DROPDOWN_INSURANCES,
  EXTERNAL_DROPDOWN_INSURANCES_STATES,
} from './types';

export const listInsurancesStates = createRoutine(LIST_INSURANCES_STATES);
export const retrieveInsuranceState = createRoutine(RETRIEVE_INSURANCE_STATE);
export const createInsuranceState = createRoutine(CREATE_INSURANCE_STATE);
export const updateInsuranceState = createRoutine(UPDATE_INSURANCE_STATE);
export const dropdownInsurancesStates = createRoutine(
  DROPDOWN_INSURANCES_STATES,
);
export const externalDropdownInsurancesStates = createRoutine(
  EXTERNAL_DROPDOWN_INSURANCES_STATES,
);

export const listInsurances = createRoutine(LIST_INSURANCES);
export const retrieveInsurance = createRoutine(RETRIEVE_INSURANCE);
export const createInsurance = createRoutine(CREATE_INSURANCE);
export const updateInsurance = createRoutine(UPDATE_INSURANCE);
export const dropdownInsurances = createRoutine(DROPDOWN_INSURANCES);

export const listInsurancesTypes = createRoutine(LIST_INSURANCES_TYPES);

export const bindedCreateInsuranceState =
  bindRoutineToReduxForm(createInsuranceState);
export const bindedUpdateInsuranceState =
  bindRoutineToReduxForm(updateInsuranceState);
export const bindedCreateInsurance = bindRoutineToReduxForm(createInsurance);
export const bindedUpdateInsurance = bindRoutineToReduxForm(updateInsurance);
