import { call, put, takeLatest } from 'redux-saga/effects';

import SDK from '@utils/api';
import { messageError } from '@views/base-components/toast-messages';

import { listParentSources, listSources, retrieveSource } from './actions';

export function* listSourcesSaga() {
  try {
    yield put(listSources.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.sources, 'getSources']);
    yield put(listSources.success(body));
  } catch (error) {
    yield put(listSources.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(listSources.fulfill());
  }
}
export function* listSourceParentsSaga() {
  try {
    yield put(listParentSources.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api['source-parent'], 'getSourceParents']);
    yield put(listParentSources.success(body));
  } catch (error) {
    yield put(listParentSources.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(listParentSources.fulfill());
  }
}

export function* retrieveSourceSaga({ payload: { id } }) {
  try {
    yield put(retrieveSource.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.sources, 'getSource'], { id });
    yield put(retrieveSource.success(body));
  } catch (error) {
    yield put(retrieveSource.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(retrieveSource.fulfill());
  }
}

export default function* sourcesWatcher() {
  yield takeLatest(listSources.TRIGGER, listSourcesSaga);
  yield takeLatest(retrieveSource.TRIGGER, retrieveSourceSaga);
  yield takeLatest(listParentSources.TRIGGER, listSourceParentsSaga);
}
