const PREFIX = 'USERS';

export const LIST_USERS = `${PREFIX}/LIST`;
export const RETRIEVE_USER = `${PREFIX}/RETRIEVE`;
export const CREATE_USER = `${PREFIX}/CREATE`;
export const UPDATE_USER = `${PREFIX}/UPDATE`;
export const ENABLED_USER = `${PREFIX}/ENABLED`;
export const SET_FILTER_USER = `${PREFIX}/SET_FILTER`;
export const DROPDOWN_USERS = `${PREFIX}/DROPDOWN`;
export const DROPDOWN_USER_DEPARTMENTS = `${PREFIX}/DROPDOWN_DEPARTMENTS`;
export const DROPDOWN_USER_LOCATIONS = `${PREFIX}/DROPDOWN_LOCATIONS`;
export const LIST_AGENTS = `${PREFIX}/LIST_AGENTS`;
