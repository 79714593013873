import { PureAbility } from '@casl/ability';
import produce, { Draft } from 'immer';
import { MeType } from 'types/me';

import { retrieveMe, updateMe } from './actions';

export type MeState = {
  loading: boolean;
  error: string | undefined;
  item: MeType | undefined;
  ability: PureAbility;
};

const initialAvailability = new PureAbility();

export const initialState: MeState = {
  loading: false,
  error: undefined,
  item: undefined,
  ability: initialAvailability,
};

const reducer = produce((draft: Draft<MeState>, { type, payload }) => {
  switch (type) {
    case retrieveMe.TRIGGER:
    case updateMe.TRIGGER:
      draft.loading = true;
      draft.error = undefined;
      break;

    // SUCCESS
    case retrieveMe.SUCCESS:
      draft.item = payload.user;
      draft.ability = payload.ability;
      break;

    // FAILURE
    case retrieveMe.FAILURE:
    case updateMe.FAILURE:
      draft.error = payload;
      break;

    // FULFILL
    case retrieveMe.FULFILL:
    case updateMe.FULFILL:
      draft.loading = false;
      break;
    // no default
  }
}, initialState);

export default reducer;
