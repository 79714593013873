import produce, { Draft } from 'immer';

import { UserType } from 'types/user';
import { UserDepartmentType } from 'types/user-department';
import { UserLocationType } from 'types/user-location';
import { MetaType } from 'types/meta';
import {
  listUsers,
  retrieveUser,
  createUser,
  updateUser,
  enabledUser,
  dropdownUsers,
  dropdownUserDepartments,
  dropdownUserLocations,
} from './actions';
import { SET_FILTER_USER } from './types';

export type UsersState = {
  loading: boolean;
  error: string | undefined;
  item: UserType | undefined;
  filter: string | undefined;
  items: UserType[];
  itemsDepartments: UserDepartmentType[];
  itemsLocations: UserLocationType[];
  meta: MetaType | undefined;
  dropdown: UserType[];
};

const initialState: UsersState = {
  loading: false,
  error: undefined,
  item: undefined,
  filter: undefined,
  items: [],
  itemsDepartments: [],
  itemsLocations: [],
  meta: undefined,
  dropdown: [],
};

const reducer = produce((draft: Draft<UsersState>, { type, payload }) => {
  switch (type) {
    case listUsers.TRIGGER:
    case retrieveUser.TRIGGER:
    case createUser.TRIGGER:
    case updateUser.TRIGGER:
    case enabledUser.TRIGGER:
    case dropdownUsers.TRIGGER:
    case dropdownUserDepartments.TRIGGER:
    case dropdownUserLocations.TRIGGER:
      draft.loading = true;
      draft.error = undefined;
      break;

    // SUCCESS
    case listUsers.SUCCESS:
      draft.items = payload.items;
      draft.meta = payload.meta;
      break;
    case retrieveUser.SUCCESS:
    case createUser.SUCCESS:
    case updateUser.SUCCESS:
      draft.item = payload;
      break;
    case enabledUser.SUCCESS:
      draft.item = undefined;
      break;
    case dropdownUsers.SUCCESS:
      draft.dropdown = payload;
      break;
    case dropdownUserDepartments.SUCCESS:
      draft.itemsDepartments = payload;
      break;
    case dropdownUserLocations.SUCCESS:
      draft.itemsLocations = payload;
      break;

    // FAILURE
    case listUsers.FAILURE:
    case retrieveUser.FAILURE:
    case createUser.FAILURE:
    case updateUser.FAILURE:
    case enabledUser.FAILURE:
    case dropdownUsers.FAILURE:
    case dropdownUserDepartments.FAILURE:
    case dropdownUserLocations.FAILURE:
      draft.error = payload;
      break;

    // FULFILL
    case listUsers.FULFILL:
    case retrieveUser.FULFILL:
    case createUser.FULFILL:
    case updateUser.FULFILL:
    case enabledUser.FULFILL:
    case dropdownUsers.FULFILL:
    case dropdownUserDepartments.FULFILL:
    case dropdownUserLocations.FULFILL:
      draft.loading = false;
      break;
    case SET_FILTER_USER:
      draft.filter = payload;
      break;
    // no default
  }
}, initialState);

export default reducer;
