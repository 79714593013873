import { FC } from 'react';
import classnames from 'classnames';
import { LoadingComponentProps } from 'react-loadable';

type ScreenLoadingProperties = {
  isInitial?: boolean;
};

const ScreenLoading: FC<
  Partial<LoadingComponentProps> & ScreenLoadingProperties
> = ({ isInitial = true }) => (
  <div
    data-testid="test__screen-loader"
    className={classnames(
      'ui__loader align-items-center d-flex justify-content-center pt-5 pb-4',
      { 'ui__loader--wrapper': isInitial },
    )}
  >
    <div data-testid="test__spinner" className="ui__spinner" />
  </div>
);

export default ScreenLoading;
