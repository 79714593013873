import produce, { Draft } from 'immer';

import { createPhysicianReferral, setShowThankYouPage } from './actions';

export type PhysicianReferralsState = {
  loading: boolean;
  error: string | undefined;
  showThankYouPage: boolean;
};

const initialState: PhysicianReferralsState = {
  loading: false,
  error: undefined,
  showThankYouPage: false,
};

const reducer = produce(
  (draft: Draft<PhysicianReferralsState>, { type, payload }) => {
    switch (type) {
      case createPhysicianReferral.TRIGGER:
        draft.loading = true;
        draft.error = undefined;
        break;

      case createPhysicianReferral.SUCCESS:
        draft.showThankYouPage = true;
        break;
      case createPhysicianReferral.FAILURE:
        draft.error = payload;
        break;

      case createPhysicianReferral.FULFILL:
        draft.loading = false;
        break;

      case setShowThankYouPage.toString():
        draft.showThankYouPage = payload;
        break;
      // no default
    }
  },
  initialState,
);

export default reducer;
