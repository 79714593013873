import produce, { Draft } from 'immer';

import { UserType } from 'types/user';
import { DashboardByRole } from 'types/dashboards';

import {
  getCallWidget,
  getDashboardLayout,
  listBirthdaysDashboard,
  listDashboardByRole,
} from './actions';
import { CLEAR_WIDGETS } from './types';

export type DashboardState = {
  loading: boolean;
  error: string | undefined;
  birthdays: UserType[];
  // TODO: any
  dashboard: any;
  dataWidgets: any;
  dashboardsByRole: DashboardByRole[];
};

const initialState: DashboardState = {
  loading: false,
  error: undefined,
  birthdays: [],
  dashboard: [],
  dataWidgets: {},
  dashboardsByRole: [],
};

const reducer = produce((draft: Draft<DashboardState>, { type, payload }) => {
  switch (type) {
    // TRIGGER
    case listBirthdaysDashboard.TRIGGER:
    case getDashboardLayout.TRIGGER:
      draft.loading = true;
      draft.error = undefined;
      draft.dataWidgets = {};
      break;
    case listDashboardByRole.TRIGGER:
      draft.loading = true;
      draft.error = undefined;
      break;
    case getCallWidget.TRIGGER:
      if (payload && payload.id && !draft.dataWidgets[payload.id]) {
        draft.dataWidgets[payload.id] = {
          loading: true,
          error: undefined,
          value: undefined,
        };
      }
      break;
    // SUCCESS
    case getDashboardLayout.SUCCESS:
      draft.dashboard = payload;
      draft.error = undefined;
      break;
    case listBirthdaysDashboard.SUCCESS:
      draft.birthdays = payload;
      draft.error = undefined;
      break;
    case getCallWidget.SUCCESS:
      if (payload && payload.id && draft.dataWidgets[payload.id]) {
        draft.dataWidgets[payload.id].value = payload.body;
      }
      break;
    case listDashboardByRole.SUCCESS:
      draft.dashboardsByRole = payload;
      break;
    // FAILURE
    case listBirthdaysDashboard.FAILURE:
    case getDashboardLayout.FAILURE:
    case listDashboardByRole.FAILURE:
      draft.error = payload;
      break;
    case getCallWidget.FAILURE:
      if (draft.dataWidgets[payload.id]) {
        draft.dataWidgets[payload.id] = Object.assign(
          draft.dataWidgets[payload.id],
          { error: payload.error },
        );
      }
      break;
    // FULFILL
    case listBirthdaysDashboard.FULFILL:
    case getDashboardLayout.FULFILL:
    case listDashboardByRole.FULFILL:
      draft.loading = false;
      break;
    case getCallWidget.FULFILL:
      if (draft.dataWidgets[payload.id]) {
        draft.dataWidgets[payload.id] = Object.assign(
          draft.dataWidgets[payload.id],
          { loading: false },
        );
      }
      break;
    // toString
    case CLEAR_WIDGETS:
      draft.dataWidgets = {};
      if (draft.dashboard && draft.dashboard.widgets) {
        draft.dashboard.widgets = [];
      }
      break;
    // no default
  }
}, initialState);

export default reducer;
