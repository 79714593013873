import { createRoutine, bindRoutineToReduxForm } from 'redux-saga-routines';
import {
  LIST_THINGS,
  RETRIEVE_THING,
  ALL_THINGS,
  CREATE_THING,
  UPDATE_THING,
  DELETE_THING,
} from './types';

export const listThings = createRoutine(LIST_THINGS);
export const retrieveThing = createRoutine(RETRIEVE_THING);
export const allThings = createRoutine(ALL_THINGS);
export const createThing = createRoutine(CREATE_THING);
export const updateThing = createRoutine(UPDATE_THING);
export const deleteThing = createRoutine(DELETE_THING);

export const bindedCreateThing = bindRoutineToReduxForm(createThing);
export const bindedUpdateThing = bindRoutineToReduxForm(updateThing);
