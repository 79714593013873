/* eslint-disable no-param-reassign */
import produce, { Draft } from 'immer';
import { ReportType } from 'types/reports';
import {
  getPerformanceGraph,
  getMeReports,
  getSubReports,
  setPeriodReport,
} from './actions';

export type ReportsState = {
  loadingReport: boolean;
  loadingGraph: boolean;
  loadingSubReport: boolean;
  error: string | undefined;
  graph: any;
  reports: ReportType | undefined;
  subReports: ReportType | undefined;
  periodReport: string | undefined;
};

const initialState: ReportsState = {
  loadingReport: false,
  loadingGraph: false,
  loadingSubReport: false,
  error: undefined,
  graph: [],
  reports: undefined,
  subReports: undefined,
  periodReport: undefined,
};

const reducer = produce((draft: Draft<ReportsState>, { type, payload }) => {
  switch (type) {
    case getPerformanceGraph.TRIGGER:
      draft.loadingGraph = true;
      draft.error = undefined;
      break;
    case getMeReports.TRIGGER:
      draft.loadingReport = true;
      draft.error = undefined;
      break;
    case getSubReports.TRIGGER:
      draft.loadingSubReport = true;
      break;

    // SUCCESS
    case getPerformanceGraph.SUCCESS:
      draft.graph = payload;
      break;
    case getMeReports.SUCCESS:
      draft.reports = payload;
      break;
    case getSubReports.SUCCESS:
      draft.subReports = payload;
      break;

    // FAILURE
    case getPerformanceGraph.FAILURE:
    case getMeReports.FAILURE:
    case getSubReports.FAILURE:
      draft.error = payload;
      break;

    // FULFILL
    case getPerformanceGraph.FULFILL:
      draft.loadingGraph = false;
      break;
    case getMeReports.FULFILL:
      draft.loadingReport = false;
      break;
    case getSubReports.FULFILL:
      draft.loadingSubReport = false;
      break;
    case setPeriodReport.toString():
      draft.periodReport = payload;
      break;
    // no default
  }
}, initialState);

export default reducer;
