import produce, { Draft } from 'immer';

import { CampaignType } from 'types/campaign';
import {
  listCampaigns,
  retrieveCampaign,
  createCampaign,
  updateCampaign,
} from './actions';
import { SET_FILTER_CAMPAIGN } from './types';

export type CampaignState = {
  loading: boolean;
  error: string | undefined;
  item: CampaignType | undefined;
  filter: string | undefined;
  items: CampaignType[];
};

const initialState: CampaignState = {
  loading: false,
  error: undefined,
  item: undefined,
  filter: undefined,
  items: [],
};

const reducer = produce((draft: Draft<CampaignState>, { type, payload }) => {
  switch (type) {
    case listCampaigns.TRIGGER:
    case retrieveCampaign.TRIGGER:
    case createCampaign.TRIGGER:
    case updateCampaign.TRIGGER:
      draft.loading = true;
      draft.error = undefined;
      break;

    // SUCCESS
    case listCampaigns.SUCCESS:
      draft.items = payload;
      break;
    case retrieveCampaign.SUCCESS:
    case createCampaign.SUCCESS:
    case updateCampaign.SUCCESS:
      draft.item = payload;
      break;

    // FAILURE
    case listCampaigns.FAILURE:
    case retrieveCampaign.FAILURE:
    case createCampaign.FAILURE:
    case updateCampaign.FAILURE:
      draft.error = payload;
      break;

    // FULFILL
    case listCampaigns.FULFILL:
    case retrieveCampaign.FULFILL:
    case createCampaign.FULFILL:
    case updateCampaign.FULFILL:
      draft.loading = false;
      break;
    case SET_FILTER_CAMPAIGN:
      draft.filter = payload;
      break;
    // no default
  }
}, initialState);

export default reducer;
