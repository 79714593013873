import produce, { Draft } from 'immer';

import { ThingType } from 'types/thing';
import {
  listThings,
  retrieveThing,
  allThings,
  createThing,
  updateThing,
  deleteThing,
} from './actions';
import { SET_FILTER_THING } from './types';

export type ThingsState = {
  loading: boolean;
  error: string | undefined;
  item: ThingType | undefined;
  all: ThingType[];
  defaultThing: ThingType[];
  filter: string | undefined;
  items: ThingType[];
};

const initialState: ThingsState = {
  loading: false,
  error: undefined,
  item: undefined,
  defaultThing: [],
  all: [],
  filter: undefined,
  items: [],
};

const reducer = produce((draft: Draft<ThingsState>, { type, payload }) => {
  switch (type) {
    case listThings.TRIGGER:
    case retrieveThing.TRIGGER:
    case allThings.TRIGGER:
    case createThing.TRIGGER:
    case updateThing.TRIGGER:
    case deleteThing.TRIGGER:
      draft.loading = true;
      draft.error = undefined;
      break;

    // SUCCESS
    case listThings.SUCCESS:
      draft.items = payload;
      break;
    case retrieveThing.SUCCESS:
    case createThing.SUCCESS:
    case updateThing.SUCCESS:
      draft.item = payload;
      break;
    case allThings.SUCCESS:
      draft.all = payload;
      break;
    case deleteThing.SUCCESS:
      draft.item = undefined;
      break;

    // FAILURE
    case listThings.FAILURE:
    case retrieveThing.FAILURE:
    case allThings.FAILURE:
    case createThing.FAILURE:
    case updateThing.FAILURE:
    case deleteThing.FAILURE:
      draft.error = payload;
      break;

    // FULFILL
    case listThings.FULFILL:
    case retrieveThing.FULFILL:
    case allThings.FULFILL:
    case createThing.FULFILL:
    case updateThing.FULFILL:
    case deleteThing.FULFILL:
      draft.loading = false;
      break;
    case SET_FILTER_THING:
      draft.filter = payload;
      break;
    // no default
  }
}, initialState);

export default reducer;
