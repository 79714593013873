import { createRoutine, bindRoutineToReduxForm } from 'redux-saga-routines';
import {
  LIST_CLINICS,
  RETRIEVE_CLINIC,
  CREATE_CLINIC,
  UPDATE_CLINIC,
  DROPDOWN_CLINICS,
  EXTERNAL_DROPDOWN_CLINICS,
} from './types';

export const listClinics = createRoutine(LIST_CLINICS);
export const retrieveClinic = createRoutine(RETRIEVE_CLINIC);
export const createClinic = createRoutine(CREATE_CLINIC);
export const updateClinic = createRoutine(UPDATE_CLINIC);
export const dropdownClinics = createRoutine(DROPDOWN_CLINICS);
export const externalDropdownClinics = createRoutine(EXTERNAL_DROPDOWN_CLINICS);

export const bindedCreateClinic = bindRoutineToReduxForm(createClinic);
export const bindedUpdateClinic = bindRoutineToReduxForm(updateClinic);
