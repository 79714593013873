import { call, put, takeLatest } from 'redux-saga/effects';

import SDK from '@utils/api';
import { MSG_CLINIC_CREATED, MSG_CLINIC_UPDATED } from '@utils/enums/messages';
import {
  messageError,
  messageSuccess,
} from '@views/base-components/toast-messages';

import {
  listClinics,
  retrieveClinic,
  createClinic,
  updateClinic,
  dropdownClinics,
  externalDropdownClinics,
} from './actions';

export function* listClinicsSaga({ payload }) {
  try {
    yield put(listClinics.request());
    const parameters = {
      search: payload.search,
      page: payload.page,
      perPage: payload.perPage,
    };
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.clinics, 'getClinics'], parameters);
    yield put(listClinics.success(body));
  } catch (error) {
    yield put(listClinics.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(listClinics.fulfill());
  }
}

export function* retrieveClinicSaga({ payload: { clinicId } }) {
  try {
    yield put(retrieveClinic.request());
    const parameters = { id: clinicId };
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.clinics, 'retrieveClinic'], parameters);
    yield put(retrieveClinic.success(body));
  } catch (error) {
    yield put(retrieveClinic.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(retrieveClinic.fulfill());
  }
}

export function* createClinicSaga({ payload: { values: requestBody } }) {
  try {
    yield put(createClinic.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call(
      [api.clinics, 'createClinic'],
      {},
      { requestBody },
    );
    yield put(createClinic.success(body));
    messageSuccess(MSG_CLINIC_CREATED);
  } catch (error) {
    yield put(createClinic.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(createClinic.fulfill());
  }
}

export function* updateClinicSaga({ payload: { values: requestBody } }) {
  try {
    yield put(updateClinic.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call(
      [api.clinics, 'updateClinic'],
      { id: requestBody.id },
      { requestBody },
    );
    yield put(updateClinic.success(body));
    messageSuccess(MSG_CLINIC_UPDATED);
  } catch (error) {
    yield put(updateClinic.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(updateClinic.fulfill());
  }
}

export function* dropdownClinicsSaga() {
  try {
    yield put(dropdownClinics.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.clinics, 'listDropdownClinics'], {});
    yield put(dropdownClinics.success(body));
  } catch (error) {
    yield put(dropdownClinics.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(dropdownClinics.fulfill());
  }
}

export function* externalDropdownClinicsSaga() {
  try {
    yield put(externalDropdownClinics.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call(
      [api.external, 'externalListDropdownClinics'],
      {},
    );
    yield put(externalDropdownClinics.success(body));
  } catch (error) {
    yield put(externalDropdownClinics.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(externalDropdownClinics.fulfill());
  }
}

export default function* clinicsWatcher() {
  yield takeLatest(listClinics.TRIGGER, listClinicsSaga);
  yield takeLatest(retrieveClinic.TRIGGER, retrieveClinicSaga);
  yield takeLatest(createClinic.TRIGGER, createClinicSaga);
  yield takeLatest(updateClinic.TRIGGER, updateClinicSaga);
  yield takeLatest(dropdownClinics.TRIGGER, dropdownClinicsSaga);
  yield takeLatest(
    externalDropdownClinics.TRIGGER,
    externalDropdownClinicsSaga,
  );
}
