import Loadable from 'react-loadable';

import {
  dropdownClinics,
  externalDropdownClinics,
  listClinics,
  retrieveClinic,
} from '@modules/clinics/actions';
import {
  listGroups,
  listGroupsAndSubgroups,
  retrieveGroup,
  retrieveSubgroup,
} from '@modules/groups/actions';
import {
  dropdownInsurances,
  dropdownInsurancesStates,
  externalDropdownInsurancesStates,
  listInsurances,
  listInsurancesStates,
  listInsurancesTypes,
  retrieveInsurance,
  retrieveInsuranceState,
} from '@modules/insurances/actions';
import { listLeads, getAllRetrieveLeadInfo } from '@modules/leads/actions';
import { listLocations, retrieveLocation } from '@modules/locations/actions';
import {
  getGroupScript,
  listScripts,
  listWidgets,
  retrieveScript,
  generalScript,
} from '@modules/scripts/actions';
import { getMeReports, getPerformanceGraph } from '@modules/reports/actions';
import { listTimezones } from '@modules/timezones/actions';
import {
  dropdownUserDepartments,
  dropdownUserLocations,
  listUsers,
  retrieveUser,
} from '@modules/users/actions';
import { listParentSources } from '@modules/sources/actions';
import listLeadsSatisfaction from '@modules/leads-satisfaction/actions';
import {
  dropdownPhysicianSpecialties,
  dropdownReferringPhysicians,
  externalDropdownReferringPhysicians,
  listReferringPhysicians,
  retrieveReferringPhysician,
} from '@modules/referring-physician/actions';
import {
  dropdownRoles,
  dropdownRolesModules,
  listRoles,
  listRolesActions,
  listRolesModules,
  retrieveRole,
  retrieveRoleModule,
} from '@modules/roles/actions';
import { listTips, retrieveTip } from '@modules/tips/actions';
import { listThings, allThings, retrieveThing } from '@modules/things/actions';
import { retrieveMe } from '@modules/me/actions';
import {
  clearWidgets,
  getDashboardLayout,
  listBirthdaysDashboard,
  listDashboardByRole,
} from '@modules/dashboard/actions';
import { listCampaigns } from '@modules/campaigns/actions';
import { allList } from '@modules/list/actions';
import { listNextech, listSyncInfo } from '@modules/nextech/actions';
import { listWaitList } from '@modules/wait-list/actions';
import { ROLE_MODULES } from '@utils/enums/roles';

import ScreenLoading from '@views/base-components/screen-loading';
import Routes from './interface';

const Root = Loadable({
  loader: () => import('views/screens/auth/login'),
  // @ts-ignore
  loading: ScreenLoading,
});

const ReferralForm = Loadable({
  // eslint-disable-next-line unicorn/no-null
  loader: () => import('@views/screens/physician-referral'),
  // @ts-ignore
  loading: ScreenLoading,
});

const ContainerApp = Loadable({
  loader: () => import('views/containers/app'),
  // @ts-ignore
  loading: ScreenLoading,
});

const Home = Loadable({
  loader: () => import('@views/screens/home'),
  // @ts-ignore
  loading: ScreenLoading,
});

const Dashboard = Loadable({
  loader: () => import('@views/screens/my-dashboard'),
  // @ts-ignore
  loading: ScreenLoading,
});

const LeadProfile = Loadable({
  loader: () => import('views/screens/lead/profile'),
  // @ts-ignore
  loading: ScreenLoading,
});

const NewLead = Loadable({
  loader: () => import('views/screens/lead/new'),
  // @ts-ignore
  loading: ScreenLoading,
});

const LeadSatisfaction = Loadable({
  loader: () => import('views/screens/leads-satisfaction'),
  // @ts-ignore
  loading: ScreenLoading,
});

const Reports = Loadable({
  loader: () => import('views/screens/reports'),
  // @ts-ignore
  loading: ScreenLoading,
});

const NotFound = Loadable({
  loader: () => import('views/screens/not-found'),
  // @ts-ignore
  loading: ScreenLoading,
});

const NotPermissions = Loadable({
  loader: () => import('views/screens/not-permissions'),
  // @ts-ignore
  loading: ScreenLoading,
});

// Administrator;
const AdminContainer = Loadable({
  loader: () => import('views/containers/admin'),
  // @ts-ignore
  loading: ScreenLoading,
});

const AdminHome = Loadable({
  loader: () => import('@views/screens/admin/home'),
  // @ts-ignore
  loading: ScreenLoading,
});

const AdminGroups = Loadable({
  loader: () => import('views/screens/admin/groups'),
  // @ts-ignore
  loading: ScreenLoading,
});

const AdminNewGroup = Loadable({
  loader: () => import('views/screens/admin/groups/new'),
  // @ts-ignore
  loading: ScreenLoading,
});

const AdminViewGroup = Loadable({
  loader: () => import('views/screens/admin/groups/view'),
  // @ts-ignore
  loading: ScreenLoading,
});

const AdminNewSubgroup = Loadable({
  loader: () => import('views/screens/admin/subgroups/new'),
  // @ts-ignore
  loading: ScreenLoading,
});

const AdminEditSubgroup = Loadable({
  loader: () => import('views/screens/admin/subgroups/edit'),
  // @ts-ignore
  loading: ScreenLoading,
});

const AdminScripts = Loadable({
  loader: () => import('views/screens/admin/scripts'),
  // @ts-ignore
  loading: ScreenLoading,
});

const AdminNewScript = Loadable({
  loader: () => import('views/screens/admin/scripts/new'),
  // @ts-ignore
  loading: ScreenLoading,
});

const AdminEditScript = Loadable({
  loader: () => import('views/screens/admin/scripts/edit'),
  // @ts-ignore
  loading: ScreenLoading,
});

const AdminUsers = Loadable({
  loader: () => import('views/screens/admin/users/list'),
  // @ts-ignore
  loading: ScreenLoading,
});

const AdminNewUser = Loadable({
  loader: () => import('views/screens/admin/users/new'),
  // @ts-ignore
  loading: ScreenLoading,
});

const AdminEditUser = Loadable({
  loader: () => import('views/screens/admin/users/edit'),
  // @ts-ignore
  loading: ScreenLoading,
});

const AdminClinics = Loadable({
  loader: () => import('views/screens/admin/clinics/list'),
  // @ts-ignore
  loading: ScreenLoading,
});

const AdminNewClinic = Loadable({
  loader: () => import('views/screens/admin/clinics/new'),
  // @ts-ignore
  loading: ScreenLoading,
});

const AdminEditClinic = Loadable({
  loader: () => import('views/screens/admin/clinics/view'),
  // @ts-ignore
  loading: ScreenLoading,
});

const AdminNewLocation = Loadable({
  loader: () => import('views/screens/admin/locations/new'),
  // @ts-ignore
  loading: ScreenLoading,
});

const AdminEditLocation = Loadable({
  loader: () => import('views/screens/admin/locations/edit'),
  // @ts-ignore
  loading: ScreenLoading,
});

const AdminInsurancesStates = Loadable({
  loader: () => import('views/screens/admin/insurances/states/list'),
  // @ts-ignore
  loading: ScreenLoading,
});

const AdminNewInsuranceState = Loadable({
  loader: () => import('views/screens/admin/insurances/states/new'),
  // @ts-ignore
  loading: ScreenLoading,
});

const AdminEditInsuranceState = Loadable({
  loader: () => import('views/screens/admin/insurances/states/view'),
  // @ts-ignore
  loading: ScreenLoading,
});

const AdminNewInsurance = Loadable({
  loader: () => import('views/screens/admin/insurances/new'),
  // @ts-ignore
  loading: ScreenLoading,
});

const AdminEditInsurance = Loadable({
  loader: () => import('views/screens/admin/insurances/edit'),
  // @ts-ignore
  loading: ScreenLoading,
});

const AdminReferringPhysicians = Loadable({
  loader: () => import('views/screens/admin/referring-physicians/list'),
  // @ts-ignore
  loading: ScreenLoading,
});

const AdminNewReferringPhysician = Loadable({
  loader: () => import('views/screens/admin/referring-physicians/new'),
  // @ts-ignore
  loading: ScreenLoading,
});

const AdminEditReferringPhysician = Loadable({
  loader: () => import('views/screens/admin/referring-physicians/edit'),
  // @ts-ignore
  loading: ScreenLoading,
});

const AdminRoles = Loadable({
  loader: () => import('views/screens/admin/roles/list'),
  // @ts-ignore
  loading: ScreenLoading,
});

const AdminNewRoles = Loadable({
  loader: () => import('views/screens/admin/roles/new'),
  // @ts-ignore
  loading: ScreenLoading,
});

const AdminEditRoles = Loadable({
  loader: () => import('@views/screens/admin/roles/view'),
  // @ts-ignore
  loading: ScreenLoading,
});

const AdminRoleModules = Loadable({
  loader: () => import('views/screens/admin/roles/modules/list'),
  // @ts-ignore
  loading: ScreenLoading,
});

const AdminNewRoleModules = Loadable({
  loader: () => import('@views/screens/admin/roles/modules/new'),
  // @ts-ignore
  loading: ScreenLoading,
});

const AdminEditRoleModules = Loadable({
  loader: () => import('@views/screens/admin/roles/modules/edit'),
  // @ts-ignore
  loading: ScreenLoading,
});

const AdminTips = Loadable({
  loader: () => import('@views/screens/admin/tips/list'),
  // @ts-ignore
  loading: ScreenLoading,
});

const AdminNewTips = Loadable({
  loader: () => import('@views/screens/admin/tips/new'),
  // @ts-ignore
  loading: ScreenLoading,
});

const AdminEditTips = Loadable({
  loader: () => import('@views/screens/admin/tips/edit'),
  // @ts-ignore
  loading: ScreenLoading,
});

const AdminNoticeBoard = Loadable({
  loader: () => import('@views/screens/admin/notice-board/list'),
  // @ts-ignore
  loading: ScreenLoading,
});

const AdminNewNoticeBoard = Loadable({
  loader: () => import('@views/screens/admin/notice-board/new'),
  // @ts-ignore
  loading: ScreenLoading,
});

const AdminEditNoticeBoard = Loadable({
  loader: () => import('@views/screens/admin/notice-board/edit'),
  // @ts-ignore
  loading: ScreenLoading,
});

const NewLeadNextechIssue = Loadable({
  loader: () => import('@views/screens/nextech-issue'),
  // @ts-ignore
  loading: ScreenLoading,
});

const AdminViewWaitList = Loadable({
  loader: () => import('@views/screens/admin/wait-list'),
  // @ts-ignore
  loading: ScreenLoading,
});

const routes: Routes[] = [
  {
    path: '/',
    component: Root,
    exact: true,
    published: true,
  },
  {
    path: '/login',
    component: Root,
    exact: true,
    published: true,
  },
  {
    path: '/app',
    component: ContainerApp,
    restricted: true,
    actions: [listDashboardByRole],
    permissions: ROLE_MODULES.Clients,
    routes: [
      {
        path: '/app',
        component: Dashboard,
        exact: true,
        permissions: ROLE_MODULES.Leads,
        actions: [listGroupsAndSubgroups, listLeads],
      },
      {
        path: '/app/home',
        component: Home,
        exact: true,
        permissions: ROLE_MODULES.Leads,
        actions: [listGroupsAndSubgroups, listCampaigns, listLeads, allList],
      },
      {
        path: '/app/dashboard',
        component: Dashboard,
        exact: true,
        permissions: ROLE_MODULES.Reports,
        actions: [
          clearWidgets,
          retrieveMe,
          listBirthdaysDashboard,
          allThings,
          getDashboardLayout,
        ],
      },
      {
        path: '/app/reports',
        component: Reports,
        exact: true,
        permissions: ROLE_MODULES.Reports,
        actions: [getMeReports, getPerformanceGraph],
      },
      {
        path: '/app/lead',
        component: NewLead,
        exact: true,
        permissions: ROLE_MODULES.Leads,
        actions: [
          generalScript,
          dropdownClinics,
          dropdownInsurancesStates,
          dropdownInsurances,
          dropdownReferringPhysicians,
          dropdownPhysicianSpecialties,
          listTimezones,
          listParentSources,
          listInsurancesStates,
        ],
      },
      {
        path: '/app/lead/:leadId',
        component: LeadProfile,
        exact: true,
        permissions: ROLE_MODULES.Leads,
        actions: [getAllRetrieveLeadInfo],
      },
      {
        path: '/app/leads-satisfaction',
        component: LeadSatisfaction,
        exact: true,
        permissions: ROLE_MODULES.Satisfaction,
        actions: [listLeadsSatisfaction],
        defaultFilters: {
          page: 1,
          perPage: 10,
        },
      },
      {
        path: '/app/nextech-issue',
        component: NewLeadNextechIssue,
        exact: true,
        permissions: ROLE_MODULES.Leads,
        actions: [
          listGroupsAndSubgroups,
          listCampaigns,
          listNextech,
          listSyncInfo,
        ],
        defaultFilters: {
          page: 1,
          perPage: 10,
        },
      },
    ],
  },
  {
    path: '/admin',
    component: AdminContainer,
    restricted: true,
    permissions: ROLE_MODULES.Clients,
    actions: [],
    routes: [
      {
        path: '/admin/home',
        component: AdminHome,
        exact: true,
        permissions: ROLE_MODULES.Clients,
      },
      {
        path: '/admin/groups',
        component: AdminGroups,
        exact: true,
        permissions: ROLE_MODULES.Groups,
        actions: [listGroups],
      },
      {
        path: '/admin/groups/new',
        component: AdminNewGroup,
        exact: true,
        permissions: ROLE_MODULES.Groups,
        actions: [listGroups],
      },
      {
        path: '/admin/groups/:groupId/edit',
        component: AdminViewGroup,
        exact: true,
        permissions: ROLE_MODULES.Groups,
        actions: [listGroups, retrieveGroup],
      },
      {
        path: '/admin/groups/:groupId/subgroups/new',
        component: AdminNewSubgroup,
        exact: true,
        permissions: ROLE_MODULES.Groups,
        actions: [retrieveGroup],
      },
      {
        path: '/admin/groups/:groupId/subgroups/:subgroupId/edit',
        component: AdminEditSubgroup,
        exact: true,
        permissions: ROLE_MODULES.Groups,
        actions: [retrieveGroup, retrieveSubgroup],
      },
      {
        path: '/admin/scripts',
        component: AdminScripts,
        exact: true,
        permissions: ROLE_MODULES.Scripts,
        actions: [listScripts],
      },
      {
        path: '/admin/scripts/new/:typeCall/:groupId',
        component: AdminNewScript,
        exact: true,
        permissions: ROLE_MODULES.Scripts,
        actions: [listWidgets, getGroupScript],
      },
      {
        path: '/admin/scripts/:scriptId/edit',
        component: AdminEditScript,
        exact: true,
        permissions: ROLE_MODULES.Scripts,
        actions: [listWidgets, retrieveScript],
      },
      {
        path: '/admin/users',
        component: AdminUsers,
        exact: true,
        permissions: ROLE_MODULES.Users,
        actions: [listUsers],
        defaultFilters: {
          search: '',
        },
      },
      {
        path: '/admin/users/new',
        component: AdminNewUser,
        exact: true,
        permissions: ROLE_MODULES.Users,
        actions: [
          listTimezones,
          dropdownRoles,
          dropdownUserDepartments,
          dropdownUserLocations,
        ],
      },
      {
        path: '/admin/users/:userId/edit',
        component: AdminEditUser,
        exact: true,
        permissions: ROLE_MODULES.Users,
        actions: [
          retrieveUser,
          dropdownRoles,
          listTimezones,
          dropdownUserDepartments,
          dropdownUserLocations,
        ],
      },
      {
        path: '/admin/clinics',
        component: AdminClinics,
        exact: true,
        permissions: ROLE_MODULES.Clinics,
        actions: [listClinics],
        defaultFilters: {
          search: '',
        },
      },
      {
        path: '/admin/clinics/new',
        component: AdminNewClinic,
        exact: true,
        permissions: ROLE_MODULES.Clinics,
        actions: [],
      },
      {
        path: '/admin/clinics/:clinicId/edit',
        component: AdminEditClinic,
        exact: true,
        permissions: ROLE_MODULES.Clinics,
        actions: [retrieveClinic, listLocations],
        defaultFilters: {
          search: '',
        },
      },
      {
        path: '/admin/clinics/:clinicId/locations/new',
        component: AdminNewLocation,
        exact: true,
        permissions: ROLE_MODULES.Locations,
        actions: [retrieveClinic, listTimezones, listCampaigns],
      },
      {
        path: '/admin/clinics/:clinicId/locations/:locationId/edit',
        component: AdminEditLocation,
        exact: true,
        permissions: ROLE_MODULES.Locations,
        actions: [
          retrieveLocation,
          retrieveClinic,
          listTimezones,
          listCampaigns,
        ],
      },
      {
        path: '/admin/states',
        component: AdminInsurancesStates,
        exact: true,
        permissions: ROLE_MODULES.States,
        actions: [listInsurancesStates],
        defaultFilters: {
          search: '',
        },
      },
      {
        path: '/admin/states/new',
        component: AdminNewInsuranceState,
        exact: true,
        permissions: ROLE_MODULES.States,
        actions: [],
      },
      {
        path: '/admin/states/:stateId/edit',
        component: AdminEditInsuranceState,
        exact: true,
        permissions: ROLE_MODULES.States,
        actions: [retrieveInsuranceState, listInsurances],
        defaultFilters: {
          search: '',
        },
      },
      {
        path: '/admin/states/:stateId/insurances/new',
        component: AdminNewInsurance,
        exact: true,
        permissions: ROLE_MODULES.Insurances,
        actions: [retrieveInsuranceState, listInsurancesTypes],
      },
      {
        path: '/admin/states/:stateId/insurances/:insuranceId/edit',
        component: AdminEditInsurance,
        exact: true,
        permissions: ROLE_MODULES.Insurances,
        actions: [
          retrieveInsurance,
          retrieveInsuranceState,
          listInsurancesTypes,
        ],
      },
      {
        path: '/admin/referring-physicians',
        component: AdminReferringPhysicians,
        exact: true,
        permissions: ROLE_MODULES.ReferringPhysicians,
        actions: [listReferringPhysicians],
        defaultFilters: {
          search: '',
          showDisabled: true,
        },
      },
      {
        path: '/admin/referring-physicians/new',
        component: AdminNewReferringPhysician,
        exact: true,
        permissions: ROLE_MODULES.ReferringPhysicians,
        actions: [dropdownPhysicianSpecialties],
      },
      {
        path: '/admin/referring-physicians/:physicianId/edit',
        component: AdminEditReferringPhysician,
        exact: true,
        permissions: ROLE_MODULES.ReferringPhysicians,
        actions: [
          retrieveReferringPhysician,
          listReferringPhysicians,
          dropdownPhysicianSpecialties,
        ],
        defaultFilters: {
          showDisabled: true,
        },
      },
      {
        path: '/admin/roles',
        component: AdminRoles,
        exact: true,
        permissions: ROLE_MODULES.Roles,
        actions: [listRoles],
        defaultFilters: {
          search: '',
        },
      },
      {
        path: '/admin/roles/new',
        component: AdminNewRoles,
        exact: true,
        permissions: ROLE_MODULES.Roles,
        actions: [],
      },
      {
        path: '/admin/roles/:roleId/edit',
        component: AdminEditRoles,
        exact: true,
        permissions: ROLE_MODULES.Roles,
        actions: [retrieveRole, dropdownRolesModules, listRolesActions],
      },
      {
        path: '/admin/role-modules',
        component: AdminRoleModules,
        exact: true,
        permissions: ROLE_MODULES.Roles,
        actions: [listRolesModules],
        defaultFilters: {
          search: '',
        },
      },
      {
        path: '/admin/role-modules/new',
        component: AdminNewRoleModules,
        exact: true,
        permissions: ROLE_MODULES.Roles,
        actions: [],
      },
      {
        path: '/admin/role-modules/:moduleId/edit',
        component: AdminEditRoleModules,
        exact: true,
        permissions: ROLE_MODULES.Roles,
        actions: [retrieveRoleModule],
      },
      {
        path: '/admin/daily-tips',
        component: AdminTips,
        exact: true,
        permissions: ROLE_MODULES.Tips,
        actions: [listTips],
        defaultFilters: {
          search: '',
        },
      },
      {
        path: '/admin/daily-tips/new',
        component: AdminNewTips,
        exact: true,
        permissions: ROLE_MODULES.Tips,
        actions: [],
      },
      {
        path: '/admin/daily-tips/:tipId/edit',
        component: AdminEditTips,
        exact: true,
        permissions: ROLE_MODULES.Tips,
        actions: [retrieveTip],
      },
      {
        path: '/admin/notice-board',
        component: AdminNoticeBoard,
        exact: true,
        permissions: ROLE_MODULES.Tips,
        actions: [listThings],
        defaultFilters: {
          search: '',
        },
      },
      {
        path: '/admin/notice-board/new',
        component: AdminNewNoticeBoard,
        exact: true,
        permissions: ROLE_MODULES.Tips,
        actions: [dropdownUserDepartments, dropdownUserLocations],
      },
      {
        path: '/admin/notice-board/:thingId/edit',
        component: AdminEditNoticeBoard,
        exact: true,
        permissions: ROLE_MODULES.Tips,
        actions: [
          retrieveThing,
          dropdownUserDepartments,
          dropdownUserLocations,
        ],
      },
      {
        path: '/admin/wait-list',
        component: AdminViewWaitList,
        exact: true,
        permissions: ROLE_MODULES.Reports,
        actions: [listWaitList],
      },
    ],
  },
  {
    path: '/not-permissions',
    component: NotPermissions,
    exact: true,
    restricted: true,
  },
  {
    path: '/physician-referral',
    component: ReferralForm,
    exact: true,
    published: true,
    actions: [
      externalDropdownClinics,
      externalDropdownInsurancesStates,
      externalDropdownReferringPhysicians,
    ],
  },
  {
    path: '*',
    component: NotFound,
    name: '404',
  },
];

export default routes;
