/* eslint-disable no-param-reassign */
/* eslint-disable sonarjs/no-duplicated-branches */
/* eslint-disable no-case-declarations */
import produce, { Draft } from 'immer';

import { SCREEN_LEAD_PROFILE } from '@utils/enums/screen';
import { ContactHistoryType } from 'types/contact-history';
import { LeadOverdueType, LeadScheduleType, LeadType } from 'types/lead';
import { NoteType } from 'types/note';
import { MetaType } from 'types/meta';
import {
  listLeads,
  createLead,
  updateLead,
  createLeadFromScript,
  updateLeadFromScript,
  retrieveLead,
  allHistoryLead,
  blockingLead,
  unblockingLead,
  reschedule,
  changeGroups,
  createNote,
  viewNotes,
  closeNotes,
  createTemporalNote,
  clearTemporaryNotes,
  checkDuplicateLead,
  toggleDuplicateLeadModal,
  archiveLead,
  changeGroup,
  changeSubgroup,
  updateReferringPhysicianLead,
  setReferralStatus,
  updateReferralStatusLead,
  getInterceptChangeGroup,
  setSchedule,
  toggleSchedule,
  overdue,
  toggleIntersectionModal,
  setIntersectionComment,
  setIntersectionRating,
  lockedLead,
  unlockedLead,
  updatedChangeGroupsLead,
  updatedDemographicLead,
  updatedNotesLead,
  toggleIsPhoneCopied,
  togglePhoneCopyTooltip,
  toggleLocationTooltip,
} from './actions';
import { CLEAR_LEAD } from './types';

export type LeadsState = {
  loading: boolean;
  error: string | undefined;
  items: LeadType[];
  item: LeadType | undefined;
  filter: string | undefined;
  meta: MetaType | undefined;
  histories: {
    loading: boolean;
    error: string | undefined;
    items: ContactHistoryType[];
  };
  notes: {
    loading: boolean;
    error: string | undefined;
    items: NoteType[];
    show: boolean;
    temporal: Partial<NoteType>[];
    leadId: string | undefined;
  };
  duplicate: {
    loading: boolean;
    error: string | undefined;
    items: LeadType[];
    show: boolean;
  };
  archive: {
    loading: boolean;
    error: string | undefined;
    archived: boolean;
  };
  schedule: {
    loading: boolean;
    error: string | undefined;
    item: LeadScheduleType | undefined;
    show: boolean;
  };
  changeGroupAndSubgroup: {
    loading: boolean;
    error: string | undefined;
    groupId: string | undefined;
    subgroupId: string | undefined;
    successful: boolean;
    intercept: Record<string, string[]>;
  };
  referralStatus: string | undefined;
  overdue: {
    loading: boolean;
    error: string | undefined;
    item: LeadOverdueType | undefined;
  };
  intersection: {
    loading: boolean;
    error: string | undefined;
    show: boolean;
    comment: string;
    rating: number | undefined;
  };
  copyPhone: {
    loading: boolean;
    error: string | undefined;
    copied: boolean;
    show: boolean;
  };
  isOpenLocationTooltip: boolean;
};

const initialState: LeadsState = {
  loading: false,
  error: undefined,
  items: [],
  item: undefined,
  filter: undefined,
  meta: undefined,
  histories: {
    loading: false,
    error: undefined,
    items: [],
  },
  archive: {
    loading: false,
    error: undefined,
    archived: false,
  },
  notes: {
    loading: false,
    error: undefined,
    items: [],
    show: false,
    temporal: [],
    leadId: undefined,
  },
  duplicate: {
    loading: false,
    error: undefined,
    items: [],
    show: false,
  },
  schedule: {
    loading: false,
    error: undefined,
    item: {
      scheduled: new Date(),
      selectDate: undefined,
      preferredHour: '',
      timezone: undefined,
    },
    show: false,
  },
  changeGroupAndSubgroup: {
    loading: false,
    error: undefined,
    groupId: undefined,
    subgroupId: undefined,
    successful: false,
    intercept: {},
  },
  referralStatus: undefined,
  overdue: {
    loading: false,
    error: undefined,
    item: undefined,
  },
  intersection: {
    loading: false,
    error: undefined,
    show: false,
    comment: '',
    rating: undefined,
  },
  copyPhone: {
    loading: false,
    error: undefined,
    copied: false,
    show: false,
  },
  isOpenLocationTooltip: false,
};

// eslint-disable-next-line sonarjs/cognitive-complexity
const reducer = produce((draft: Draft<LeadsState>, { type, payload }) => {
  // eslint-disable-next-line sonarjs/max-switch-cases
  switch (type) {
    case listLeads.TRIGGER:
    case retrieveLead.TRIGGER:
    case createLead.TRIGGER:
    case updateLead.TRIGGER:
    case createLeadFromScript.TRIGGER:
    case updateLeadFromScript.TRIGGER:
    case blockingLead.TRIGGER:
    case unblockingLead.TRIGGER:
    case checkDuplicateLead.TRIGGER:
    case updateReferringPhysicianLead.TRIGGER:
    case updateReferralStatusLead.TRIGGER:
      draft.loading = true;
      draft.error = undefined;
      draft.intersection.loading = true;
      draft.intersection.error = undefined;
      break;
    case changeGroups.TRIGGER:
      draft.changeGroupAndSubgroup.loading = true;
      draft.changeGroupAndSubgroup.error = undefined;
      draft.intersection.loading = true;
      draft.intersection.error = undefined;
      break;
    case reschedule.TRIGGER:
      draft.schedule.loading = true;
      draft.schedule.error = undefined;
      break;
    case createNote.TRIGGER:
      draft.notes.loading = true;
      draft.notes.error = undefined;
      break;
    case viewNotes.TRIGGER:
      draft.notes.loading = true;
      draft.notes.error = undefined;
      draft.notes.leadId = payload;
      break;
    case allHistoryLead.TRIGGER:
      draft.histories.loading = true;
      draft.histories.error = undefined;
      break;
    case archiveLead.TRIGGER:
      draft.archive.loading = true;
      draft.archive.error = undefined;
      break;
    case overdue.TRIGGER:
      draft.overdue.loading = true;
      draft.overdue.error = undefined;
      break;

    // SUCCESS
    case listLeads.SUCCESS:
      draft.items = payload.items;
      draft.meta = payload.meta;
      draft.item = undefined;
      draft.changeGroupAndSubgroup.groupId = undefined;
      draft.changeGroupAndSubgroup.subgroupId = undefined;
      draft.histories.items = [];
      draft.referralStatus = undefined;
      break;
    case retrieveLead.SUCCESS:
    case createLead.SUCCESS:
      draft.item = payload;
      draft.histories.items = payload.contactHistories;
      draft.archive.archived = false;
      draft.changeGroupAndSubgroup.groupId = payload?.group?.id;
      draft.changeGroupAndSubgroup.subgroupId = payload?.subgroup?.id;
      draft.changeGroupAndSubgroup.successful = false;
      draft.duplicate.show = false;
      draft.duplicate.items = [];
      draft.intersection.loading = false;
      draft.intersection.show = false;
      draft.intersection.comment = '';
      draft.intersection.rating = undefined;
      break;
    case updateLead.SUCCESS:
      draft.item = payload;
      draft.archive.archived = false;
      draft.changeGroupAndSubgroup.groupId = payload?.group?.id;
      draft.changeGroupAndSubgroup.subgroupId = payload?.subgroup?.id;
      draft.intersection.loading = false;
      draft.intersection.show = false;
      draft.intersection.comment = '';
      draft.intersection.rating = undefined;
      break;
    case updateLeadFromScript.SUCCESS:
      draft.item = payload;
      draft.archive.archived = false;
      break;
    case updateReferringPhysicianLead.SUCCESS:
      draft.item = payload;
      break;
    case updateReferralStatusLead.SUCCESS:
      draft.item = payload;
      draft.referralStatus = payload?.medworkReferralStatus;
      break;
    case createNote.SUCCESS:
      if (payload.screen !== SCREEN_LEAD_PROFILE) {
        draft?.notes?.items?.unshift(payload.body);
      }
      break;
    case viewNotes.SUCCESS:
      draft.notes.items = payload;
      draft.notes.show = true;
      break;
    case allHistoryLead.SUCCESS:
      draft.histories.items = payload;
      break;
    case changeGroups.SUCCESS:
      draft.item = payload;
      draft.changeGroupAndSubgroup.groupId = payload?.group?.id;
      draft.changeGroupAndSubgroup.subgroupId = payload?.subgroup?.id;
      draft.changeGroupAndSubgroup.successful = true;
      draft.intersection.loading = false;
      draft.intersection.show = false;
      draft.intersection.rating = undefined;
      draft.intersection.comment = '';
      break;
    case blockingLead.SUCCESS:
    case unblockingLead.SUCCESS:
      draft.item = { ...draft.item, ...payload };
      break;
    case checkDuplicateLead.SUCCESS:
      draft.duplicate.items = payload?.body;
      draft.duplicate.show = payload.isModal;
      break;
    case archiveLead.SUCCESS:
      draft.archive.archived = payload;
      break;
    case overdue.SUCCESS:
      draft.overdue.item = payload;
      break;

    // FAILURE
    case listLeads.FAILURE:
    case retrieveLead.FAILURE:
    case allHistoryLead.FAILURE:
    case createLead.FAILURE:
    case updateLead.FAILURE:
    case createLeadFromScript.FAILURE:
    case updateLeadFromScript.FAILURE:
    case reschedule.FAILURE:
    case blockingLead.FAILURE:
    case unblockingLead.FAILURE:
    case createNote.FAILURE:
    case updateReferringPhysicianLead.FAILURE:
    case updateReferralStatusLead.FAILURE:
      draft.error = payload;
      draft.intersection.error = payload;
      break;
    case viewNotes.FAILURE:
      draft.error = payload;
      draft.notes.show = false;
      break;
    case checkDuplicateLead.FAILURE:
      draft.error = payload;
      draft.duplicate.show = false;
      break;
    case archiveLead.FAILURE:
      draft.error = payload;
      draft.archive.archived = false;
      break;
    case changeGroups.FAILURE:
      draft.error = payload;
      draft.changeGroupAndSubgroup.successful = false;
      break;
    case overdue.FAILURE:
      draft.overdue.item = undefined;
      break;

    // FULFILL
    case listLeads.FULFILL:
    case retrieveLead.FULFILL:
    case createLead.FULFILL:
    case updateLead.FULFILL:
    case createLeadFromScript.FULFILL:
    case updateLeadFromScript.FULFILL:
    case blockingLead.FULFILL:
    case unblockingLead.FULFILL:
    case updateReferringPhysicianLead.FULFILL:
    case updateReferralStatusLead.FULFILL:
      draft.loading = false;
      draft.intersection.loading = false;
      break;
    case changeGroups.FULFILL:
      draft.changeGroupAndSubgroup.loading = false;
      break;
    case reschedule.FULFILL:
      draft.schedule.loading = false;
      break;
    case allHistoryLead.FULFILL:
      draft.histories.loading = false;
      break;
    case createNote.FULFILL:
    case viewNotes.FULFILL:
      draft.notes.loading = false;
      break;
    case archiveLead.FULFILL:
      draft.archive.loading = false;
      break;
    case overdue.FULFILL:
      draft.overdue.loading = false;
      break;

    // TO_STRING
    case CLEAR_LEAD:
      draft = initialState;
      break;
    case lockedLead.toString():
    case unlockedLead.toString():
      const index = draft?.items?.findIndex((item) => item.id === payload.id);
      if (index !== -1) {
        draft.items[index] = { ...draft.items[index], ...payload };
      }
      if (draft?.item?.id === payload.id) {
        draft.item = { ...draft.item, ...payload };
      }
      break;
    case updatedChangeGroupsLead.toString():
      if (draft?.item?.id === payload.id) {
        draft.changeGroupAndSubgroup.groupId =
          payload?.group?.id ?? draft.changeGroupAndSubgroup.groupId;
        draft.changeGroupAndSubgroup.subgroupId =
          payload?.subgroup?.id ?? draft.changeGroupAndSubgroup.subgroupId;
        draft.histories.items =
          payload?.contactHistories ?? draft.histories.items;
      }
      break;
    case updatedDemographicLead.toString():
      if (draft?.item?.id === payload.id) {
        draft.item = { ...draft.item, ...payload };
      }
      break;
    case updatedNotesLead.toString():
      if (draft?.item?.id === payload.id) {
        draft?.item?.notes?.push(payload.note);
      }
      break;
    case closeNotes.toString():
      draft.notes.items = [];
      draft.notes.show = false;
      draft.notes.leadId = undefined;
      break;
    case createTemporalNote.toString():
      draft.notes.temporal.push(payload);
      break;
    case clearTemporaryNotes.toString():
      draft.notes.temporal = [];
      break;
    case toggleDuplicateLeadModal.toString():
      draft.duplicate.show = false;
      break;
    case changeGroup.toString():
      draft.changeGroupAndSubgroup.groupId = payload;
      draft.changeGroupAndSubgroup.subgroupId = undefined;
      break;
    case changeSubgroup.toString():
      draft.changeGroupAndSubgroup.subgroupId = payload;
      break;
    case toggleSchedule.toString():
      draft.schedule.show = payload;
      draft.schedule.item = initialState.schedule.item;
      draft.changeGroupAndSubgroup.groupId = draft.item?.group?.id;
      draft.changeGroupAndSubgroup.subgroupId = draft.item?.subgroup?.id;
      break;
    case setSchedule.toString():
      draft.schedule.item = { ...draft.schedule.item, ...payload };
      break;
    case setReferralStatus.toString():
      draft.referralStatus = payload;
      break;
    case getInterceptChangeGroup.SUCCESS:
      draft.changeGroupAndSubgroup.intercept = payload?.metadata;
      break;
    case toggleIntersectionModal.toString():
      draft.intersection.show = !draft.intersection.show;
      break;
    case setIntersectionComment.toString():
      draft.intersection.comment = payload;
      break;
    case setIntersectionRating.toString():
      draft.intersection.rating = payload;
      break;
    case toggleIsPhoneCopied.toString():
      draft.copyPhone.copied = !draft.copyPhone.copied;
      break;
    case togglePhoneCopyTooltip.toString():
      draft.copyPhone.show = !draft.copyPhone.show;
      break;
    case toggleLocationTooltip.toString():
      draft.isOpenLocationTooltip = !draft.isOpenLocationTooltip;
      break;
    // no default
  }
}, initialState);

export default reducer;
