import { createRoutine } from 'redux-saga-routines';
import { createAction } from 'redux-actions';
import {
  LEAD_NEXT_PRIORITY,
  WORK_NEXT_PRIORITY,
  MODAL_END_WAIT_LIST_NEXT_PRIORITY,
  RESTART_NEXT_PRIORITY,
} from './types';

export const retrieveLeadNextPriority = createRoutine(LEAD_NEXT_PRIORITY);

export const toggleEndWaitListNextPriorityModal = createAction(
  MODAL_END_WAIT_LIST_NEXT_PRIORITY,
);
export const workNextPriority = createAction(WORK_NEXT_PRIORITY);

export const restartNextPriority = createAction(RESTART_NEXT_PRIORITY);
