import { call, put, takeLatest, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { destroy } from 'redux-form';

import SDK from '@utils/api';
import { SCRIPT_TYPE_GENERAL } from '@utils/enums/type-scripts';

import { messageError } from '@views/base-components/toast-messages';
import { clearLead, unblockingLead } from '@modules/leads/actions';
import { getLeadItem } from '@modules/leads/selectors';
import { clearLeadScript } from '@modules/scripts/actions';

import { getNextPriorityLeadId } from './selectors';
import { retrieveLeadNextPriority } from './actions';

export function* retrieveLeadNextPrioritySaga() {
  const currentLead = yield select(getLeadItem);
  try {
    yield put(retrieveLeadNextPriority.request());
    const leadId = yield select(getNextPriorityLeadId);
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.leads, 'findNextPriority'], { leadId });
    if (body) {
      if (currentLead?.id) {
        yield put(unblockingLead({ leadId: currentLead?.id }));
      }
      yield put(clearLead());
      yield put(clearLeadScript());
      yield put(destroy(SCRIPT_TYPE_GENERAL));
      yield put(destroy());
      yield put(
        retrieveLeadNextPriority.success({
          leadId: body,
          work: true,
          status: false,
        }),
      );
      yield put(push(`/app/lead/${body}`));
    } else {
      yield put(
        retrieveLeadNextPriority.success({
          leadId: undefined,
          work: false,
          status: true,
        }),
      );
    }
  } catch (error) {
    yield put(retrieveLeadNextPriority.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(retrieveLeadNextPriority.fulfill());
  }
}

export default function* nextPriorityWatcher() {
  yield takeLatest(
    retrieveLeadNextPriority.TRIGGER,
    retrieveLeadNextPrioritySaga,
  );
}
