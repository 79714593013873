import { createSelector } from 'reselect';

import { RootState } from '@store/type';
import { AuthState } from './reducer';

export const getAuth = ({ auth }: RootState): AuthState => auth;

export const getAuthIsLoggedIn = createSelector(
  getAuth,
  ({ isLoggedIn }) => isLoggedIn,
);
export const isAuthLoading = createSelector(getAuth, ({ loading }) => loading);
export const getAuthToken = createSelector(getAuth, ({ token }) => token);
export const getAuthRefreshToken = createSelector(
  getAuth,
  ({ refreshToken }) => refreshToken,
);
export const getAuthError = createSelector(getAuth, ({ error }) => error);
