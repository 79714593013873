import { call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import SDK from '@utils/api';
import {
  MSG_USER_CREATED,
  MSG_USER_UPDATED,
  MSG_USER_DELETED,
} from '@utils/enums/messages';
import {
  messageError,
  messageSuccess,
} from '@views/base-components/toast-messages';

import {
  listUsers,
  retrieveUser,
  createUser,
  updateUser,
  enabledUser,
  dropdownUsers,
  dropdownUserDepartments,
  dropdownUserLocations,
} from './actions';

export function* listUsersSaga({ payload }) {
  try {
    yield put(listUsers.request());
    const parameters = {
      search: payload.search,
      page: payload.page,
      perPage: payload.perPage,
    };
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.users, 'listUsers'], parameters);
    yield put(listUsers.success(body));
  } catch (error) {
    yield put(listUsers.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(listUsers.fulfill());
  }
}

export function* retrieveUserSaga({ payload: { userId } }) {
  try {
    yield put(retrieveUser.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.users, 'retrieveUser'], { id: userId });
    yield put(retrieveUser.success(body));
  } catch (error) {
    yield put(retrieveUser.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(retrieveUser.fulfill());
  }
}

export function* createUserSaga({ payload: { values } }) {
  try {
    yield put(createUser.request());
    const api = yield call([SDK, 'getApi']);
    const requestBody = {
      ...values,
      username: values.email,
    };
    const { body } = yield call([api.users, 'createUser'], {}, { requestBody });
    yield put(createUser.success(body));
    messageSuccess(MSG_USER_CREATED);
  } catch (error) {
    yield put(createUser.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(createUser.fulfill());
  }
}

export function* updateUserSaga({ payload: { values: requestBody } }) {
  try {
    yield put(updateUser.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call(
      [api.users, 'updateUser'],
      { id: requestBody.id },
      { requestBody },
    );
    yield put(updateUser.success(body));
    messageSuccess(MSG_USER_UPDATED);
  } catch (error) {
    yield put(updateUser.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(updateUser.fulfill());
  }
}

export function* enabledUserSaga({ payload: { id } }) {
  try {
    yield put(enabledUser.request());
    const requestBody = {
      availability: false,
      connected: false,
      enabled: false,
    };
    const api = yield call([SDK, 'getApi']);
    yield call([api.users, 'enabledUser'], { id }, { requestBody });
    yield put(enabledUser.success());
    yield put(push(`/admin/users`));
    messageSuccess(MSG_USER_DELETED);
  } catch (error) {
    yield put(enabledUser.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(enabledUser.fulfill());
  }
}

export function* dropdownUsersSaga() {
  try {
    yield put(dropdownUsers.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.users, 'listDropdownUsers'], {});
    yield put(dropdownUsers.success(body));
  } catch (error) {
    yield put(dropdownUsers.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(dropdownUsers.fulfill());
  }
}

export function* dropdownUserDepartmentsSaga({ payload: { search } }) {
  try {
    yield put(dropdownUserDepartments.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.users, 'listUserDepartments'], { search });
    yield put(dropdownUserDepartments.success(body));
  } catch (error) {
    yield put(dropdownUserDepartments.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(dropdownUserDepartments.fulfill());
  }
}

export function* dropdownUserLocationsSaga({ payload: { search } }) {
  try {
    yield put(dropdownUserLocations.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.users, 'listUserLocations'], { search });
    yield put(dropdownUserLocations.success(body));
  } catch (error) {
    yield put(dropdownUserLocations.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(dropdownUserLocations.fulfill());
  }
}

export default function* usersWatcher() {
  yield takeLatest(listUsers.TRIGGER, listUsersSaga);
  yield takeLatest(retrieveUser.TRIGGER, retrieveUserSaga);
  yield takeLatest(createUser.TRIGGER, createUserSaga);
  yield takeLatest(updateUser.TRIGGER, updateUserSaga);
  yield takeLatest(enabledUser.TRIGGER, enabledUserSaga);
  yield takeLatest(dropdownUsers.TRIGGER, dropdownUsersSaga);
  yield takeLatest(
    dropdownUserDepartments.TRIGGER,
    dropdownUserDepartmentsSaga,
  );
  yield takeLatest(dropdownUserLocations.TRIGGER, dropdownUserLocationsSaga);
}
