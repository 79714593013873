import { call, put, takeLatest } from 'redux-saga/effects';

import SDK from '@utils/api';
import {
  MSG_CAMPAIGN_CREATED,
  MSG_CAMPAIGN_UPDATED,
} from '@utils/enums/messages';
import {
  messageError,
  messageSuccess,
} from '@views/base-components/toast-messages';

import {
  listCampaigns,
  retrieveCampaign,
  createCampaign,
  updateCampaign,
} from './actions';

export function* listCampaignsSaga({ payload: { search } }) {
  try {
    yield put(listCampaigns.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.campaigns, 'getCampaigns']);
    yield put(listCampaigns.success(body));
  } catch (error) {
    yield put(listCampaigns.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(listCampaigns.fulfill());
  }
}

export function* retrieveCampaignSaga({ payload: { clinicId } }) {
  try {
    yield put(retrieveCampaign.request());
    const parameters = { id: clinicId };
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call(
      [api.campaigns, 'retrieveCampaign'],
      parameters,
    );
    yield put(retrieveCampaign.success(body));
  } catch (error) {
    yield put(retrieveCampaign.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(retrieveCampaign.fulfill());
  }
}

export function* createCampaignSaga({ payload: { values: requestBody } }) {
  try {
    yield put(createCampaign.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call(
      [api.campaigns, 'createCampaign'],
      {},
      { requestBody },
    );
    yield put(createCampaign.success(body));
    messageSuccess(MSG_CAMPAIGN_CREATED);
  } catch (error) {
    yield put(createCampaign.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(createCampaign.fulfill());
  }
}

export function* updateCampaignSaga({ payload: { values: requestBody } }) {
  try {
    yield put(updateCampaign.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call(
      [api.campaigns, 'updateCampaign'],
      { id: requestBody.id },
      { requestBody },
    );
    yield put(updateCampaign.success(body));
    messageSuccess(MSG_CAMPAIGN_UPDATED);
  } catch (error) {
    yield put(updateCampaign.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(updateCampaign.fulfill());
  }
}

export default function* campaignsWatcher() {
  yield takeLatest(listCampaigns.TRIGGER, listCampaignsSaga);
  yield takeLatest(retrieveCampaign.TRIGGER, retrieveCampaignSaga);
  yield takeLatest(createCampaign.TRIGGER, createCampaignSaga);
  yield takeLatest(updateCampaign.TRIGGER, updateCampaignSaga);
}
