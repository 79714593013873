/* eslint-disable no-case-declarations */
import produce, { Draft } from 'immer';
import { LeadType, Meta } from 'types/lead';
import {
  listNextech,
  modalSetClosed,
  modalOpenSetNotFound,
  modalSelectItem,
  modalInputNextechId,
  modalClean,
  listSyncInfo,
  syncNextechLastUpdated,
  syncManualAll,
  syncManualItems,
  syncManualItemsFinish,
} from './actions';

export type NextechState = {
  syncItems: string[];
  list: {
    sync: boolean;
    lastUpdated: Date | undefined;
    loading: boolean;
    error: string | Error | undefined;
    items: LeadType[];
    meta: Meta;
  };
  modalMatches: {
    isOpen: {
      notFound: boolean;
      manyMatches: boolean;
    };
    loading: boolean;
    error: string | Error | undefined;
    items: LeadType[];
    submitting: boolean;
    selectLeadId: string | undefined;
    selectedItem: string | undefined;
    inputNextechId: string | undefined;
  };
};

const initialState: NextechState = {
  syncItems: [],
  list: {
    sync: false,
    lastUpdated: undefined,
    loading: false,
    error: undefined,
    items: [],
    meta: {
      totalItems: 0,
      itemCount: 0,
      itemsPerPage: 10,
      totalPages: 0,
      currentPage: 1,
    },
  },
  modalMatches: {
    isOpen: {
      notFound: false,
      manyMatches: false,
    },
    loading: false,
    error: undefined,
    items: [],
    submitting: false,
    selectLeadId: undefined,
    selectedItem: undefined,
    inputNextechId: undefined,
  },
};

const reducer = produce((draft: Draft<NextechState>, { type, payload }) => {
  switch (type) {
    case listNextech.TRIGGER:
      draft.list.loading = true;
      draft.list.error = undefined;
      break;
    case listNextech.SUCCESS:
      draft.list.items = payload.items;
      draft.list.meta = payload.meta;
      break;
    case listNextech.FAILURE:
      draft.list.error = payload;
      break;
    case listNextech.FULFILL:
      draft.list.loading = false;
      break;
    case modalSetClosed.toString():
      draft.modalMatches.isOpen = {
        notFound: false,
        manyMatches: false,
      };
      break;
    case modalOpenSetNotFound.toString():
      draft.modalMatches.isOpen = {
        notFound: payload.isOpen,
        manyMatches: false,
      };
      draft.modalMatches.selectLeadId = payload.leadId;
      break;
    case modalSelectItem.toString():
      draft.modalMatches.selectedItem = payload;
      break;
    case modalInputNextechId.toString():
      draft.modalMatches.inputNextechId = payload;
      break;
    case modalClean.toString():
      draft.modalMatches = initialState.modalMatches;
      break;
    case listSyncInfo.SUCCESS:
      draft.list.lastUpdated = payload?.metadata?.lastUpdate;
      draft.list.sync = payload?.metadata?.syncManualAll ?? false;
      draft.syncItems = payload?.metadata?.syncManualItems ?? [];
      break;
    case syncManualAll.toString():
      draft.list.sync = payload.syncManualAll ?? false;
      break;
    case syncManualItems.toString():
      draft.syncItems = payload.items ?? [];
      break;
    case syncManualItemsFinish.toString():
      draft.syncItems = draft.syncItems.filter(
        (id: string) => id !== payload.id,
      );
      break;
    case syncNextechLastUpdated.toString():
      draft.list.lastUpdated = payload;
      break;
    default:
      break;
  }
}, initialState);

export default reducer;
