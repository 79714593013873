import { createRoutine, bindRoutineToReduxForm } from 'redux-saga-routines';
import { createAction } from 'redux-actions';
import {
  LIST_SCRIPTS,
  LIST_WIDGETS,
  RETRIEVE_SCRIPT,
  CREATE_SCRIPT,
  UPDATE_SCRIPT,
  GET_GROUP_SCRIPT,
  GENERAL_SCRIPT,
  PREVIEW_SCRIPT,
  CLEAR_LEAD_SCRIPT,
  GET_LEAD_SCRIPT_BY_ID,
} from './types';

export const listScripts = createRoutine(LIST_SCRIPTS);
export const retrieveScript = createRoutine(RETRIEVE_SCRIPT);
export const createScript = createRoutine(CREATE_SCRIPT);
export const updateScript = createRoutine(UPDATE_SCRIPT);
export const getGroupScript = createRoutine(GET_GROUP_SCRIPT);
export const generalScript = createRoutine(GENERAL_SCRIPT);
export const previewScript = createRoutine(PREVIEW_SCRIPT);
export const listWidgets = createRoutine(LIST_WIDGETS);
export const getLeadScriptById = createRoutine(GET_LEAD_SCRIPT_BY_ID);

export const clearLeadScript = createAction(CLEAR_LEAD_SCRIPT);

export const bindedCreateScript = bindRoutineToReduxForm(createScript);
export const bindedUpdateScript = bindRoutineToReduxForm(updateScript);
