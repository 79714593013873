import { createRoutine, bindRoutineToReduxForm } from 'redux-saga-routines';
import {
  LIST_LOCATIONS,
  RETRIEVE_LOCATION,
  CREATE_LOCATION,
  UPDATE_LOCATION,
  DROPDOWN_LOCATIONS,
} from './types';

export const listLocations = createRoutine(LIST_LOCATIONS);
export const retrieveLocation = createRoutine(RETRIEVE_LOCATION);
export const createLocation = createRoutine(CREATE_LOCATION);
export const updateLocation = createRoutine(UPDATE_LOCATION);
export const dropdownLocations = createRoutine(DROPDOWN_LOCATIONS);

export const bindedCreateLocation = bindRoutineToReduxForm(createLocation);
export const bindedUpdateLocation = bindRoutineToReduxForm(updateLocation);
