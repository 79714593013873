import produce, { Draft } from 'immer';

import { updateFiltersFromUrl } from './actions';

export type NavState = {
  filters: Record<string, string>;
  prevFilters: Record<string, string>;
};

const initialState: NavState = {
  filters: {},
  prevFilters: {},
};

export default produce((draft: Draft<NavState>, action) => {
  // eslint-disable-next-line sonarjs/no-small-switch
  switch (action.type) {
    case updateFiltersFromUrl.toString():
      draft.prevFilters = draft.filters;
      draft.filters = action.payload;
      break;
    // no default
  }
}, initialState);
