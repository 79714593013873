import { call, put, takeLatest } from 'redux-saga/effects';
import { goBack } from 'connected-react-router';

import SDK from '@utils/api';
import { MSG_THING_CREATED, MSG_THING_UPDATED } from '@utils/enums/messages';
import {
  messageError,
  messageSuccess,
} from '@views/base-components/toast-messages';

import {
  listThings,
  retrieveThing,
  allThings,
  createThing,
  updateThing,
  deleteThing,
} from './actions';

export function* listThingsSaga({ payload: { search } }) {
  try {
    yield put(listThings.request());
    const parameters = { search };
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.tips, 'listThings'], parameters);
    yield put(listThings.success(body));
  } catch (error) {
    yield put(listThings.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(listThings.fulfill());
  }
}

export function* retrieveThingSaga({ payload: { thingId } }) {
  try {
    yield put(retrieveThing.request());
    const parameters = { id: thingId };
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.tips, 'retrieveThing'], parameters);
    yield put(retrieveThing.success(body));
  } catch (error) {
    yield put(retrieveThing.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(retrieveThing.fulfill());
  }
}

export function* allThingsSaga() {
  try {
    yield put(allThings.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.tips, 'allThings'], {});
    yield put(allThings.success(body));
  } catch (error) {
    yield put(allThings.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(allThings.fulfill());
  }
}

export function* createThingSaga({ payload: { values: requestBody } }) {
  try {
    yield put(createThing.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.tips, 'createThing'], {}, { requestBody });
    yield put(createThing.success({ body }));
    messageSuccess(MSG_THING_CREATED);
  } catch (error) {
    yield put(createThing.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(createThing.fulfill());
  }
}

export function* updateThingSaga({ payload: { values: requestBody } }) {
  try {
    yield put(updateThing.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call(
      [api.tips, 'updateThing'],
      { id: requestBody.id },
      { requestBody },
    );
    yield put(updateThing.success({ body }));
    messageSuccess(MSG_THING_UPDATED);
  } catch (error) {
    yield put(updateThing.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(updateThing.fulfill());
  }
}

export function* deleteThingSaga({ payload }) {
  try {
    yield put(deleteThing.request());
    const api = yield call([SDK, 'getApi']);
    yield call([api.tips, 'deleteThing'], { id: payload });
    yield put(deleteThing.success());
    yield put(goBack());
    messageSuccess(MSG_THING_UPDATED);
  } catch (error) {
    yield put(deleteThing.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(deleteThing.fulfill());
  }
}

export default function* thingsWatcher() {
  yield takeLatest(listThings.TRIGGER, listThingsSaga);
  yield takeLatest(retrieveThing.TRIGGER, retrieveThingSaga);
  yield takeLatest(allThings.TRIGGER, allThingsSaga);
  yield takeLatest(createThing.TRIGGER, createThingSaga);
  yield takeLatest(updateThing.TRIGGER, updateThingSaga);
  yield takeLatest(deleteThing.TRIGGER, deleteThingSaga);
}
