import { Container, Row, Col, Button } from 'reactstrap';
import localforage from 'localforage';

// @ts-ignore
import image from '@assets/images/EMPTY_STATES_500.svg';

const ErrorCallback = () => {
  const handleUpdate = async () => {
    localStorage.clear();
    await localforage.clear();
    window.location.reload();
  };
  return (
    <Container className="not-found h-100" fluid tag="section">
      <Row className="align-items-center h-100 justify-content-center">
        <Col xs={12} md={4}>
          <img src={image} alt="Medwork - Not Found 404" />
        </Col>
        <Col xs={12} md={3}>
          <h2 className="empty-state__title mb-3">
            Oops, an unexpected error has occurred
          </h2>
          <Button
            className="ui__button ui__button--default btn btn-block text-uppercase"
            onClick={handleUpdate}
          >
            Try Again
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default ErrorCallback;
