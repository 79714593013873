import { call, put, takeLatest } from 'redux-saga/effects';

import SDK from '@utils/api';
import { messageError } from '@views/base-components/toast-messages';

import { unsuscribeEmail } from './actions';

export function* unsuscribeEmailSaga({ payload }) {
  try {
    yield put(unsuscribeEmail.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call(
      [api.external, 'externalUpdateEmailPreferences'],
      { token: payload },
      { requestBody: { enable: false } },
    );
    yield put(unsuscribeEmail.success(body));
  } catch (error) {
    yield put(unsuscribeEmail.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(unsuscribeEmail.fulfill());
  }
}

export default function* externalWatcher() {
  yield takeLatest(unsuscribeEmail.TRIGGER, unsuscribeEmailSaga);
}
