const PREFIX = 'NEXTECH';

export const LIST_NEXTECH = `${PREFIX}/LIST`;
export const LIST_SYNCS_NEXTECH = `${PREFIX}/SYNCS_LIST`;
export const LIST_SYNC_NEXTECH = `${PREFIX}/SYNC_LIST`;

export const MODAL_OPEN_SET_NOT_FOUND = `${PREFIX}/MODAL_OPEN_SET_NOT_FOUND`;
export const MODAL_SET_CLOSED = `${PREFIX}/MODAL_SET_CLOSED`;
export const MODAL_SELECT_ITEM = `${PREFIX}/MODAL_SELECT_ITEM`;
export const MODAL_INPUT_NEXTECH_ID = `${PREFIX}/MODAL_INPUT_NEXTECH_ID`;
export const LIST_SYNC_INFO = `${PREFIX}/LIST_SYNC_INFO`;

export const MODAL_TRIGGER_NOT_FOUND = `${PREFIX}/MODAL_TRIGGER_NOT_FOUND`;
export const MODAL_CLEAN = `${PREFIX}/MODAL_CLEAN`;

export const SYNC_NEXTECH_LAST_UPDATED = `${PREFIX}/SYNC_NEXTECH_LAST_UPDATED`;
export const SYNC_MANUAL_ALL = `${PREFIX}/SYNC_MANUAL_ALL`;
export const SYNC_MANUAL_ITEMS = `${PREFIX}/SYNC_MANUAL_ITEMS`;
export const SYNC_MANUAL_ITEMS_FINISH = `${PREFIX}/SYNC_MANUAL_ITEMS_FINISH`;
