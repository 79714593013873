import { createSelector } from 'reselect';

import { RootState } from '@store/type';

export const getRouter = ({ router }: RootState) => router;
export const getNav = ({ nav }: RootState) => nav;

export const getCurrentLocation = createSelector(
  getRouter,
  ({ location }) => location,
);
export const getFilters = createSelector(getNav, ({ filters }) => filters);
export const getPreviousFilters = createSelector(
  getNav,
  ({ prevFilters }) => prevFilters,
);
