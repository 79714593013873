/* eslint-disable sonarjs/no-duplicate-string */
import { call, put, takeLatest } from 'redux-saga/effects';

import SDK from '@utils/api';
import {
  messageError,
  messageSuccess,
} from '@views/base-components/toast-messages';

import {
  MSG_REFERRING_PHYSICIAN_CREATED,
  MSG_REFERRING_PHYSICIAN_UPDATED,
} from '@utils/enums/messages';
import {
  createReferringPhysician,
  listReferringPhysicians,
  mergeReferringPhysician,
  retrieveReferringPhysician,
  updateReferringPhysician,
  dropdownReferringPhysicians,
  dropdownPhysicianSpecialties,
  externalDropdownReferringPhysicians,
} from './actions';

export function* listReferringPhysicianSaga({ payload }) {
  try {
    yield put(listReferringPhysicians.request());
    const parameters = {
      search: payload.search,
      page: payload.page,
      perPage: payload.perPage,
      showDisabled: payload.showDisabled,
    };
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call(
      [api['referring-physicians'], 'getReferringPhysicians'],
      parameters,
    );
    yield put(listReferringPhysicians.success(body));
  } catch (error) {
    yield put(listReferringPhysicians.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(listReferringPhysicians.fulfill());
  }
}

export function* retrieveReferringPhysicianSaga({ payload: { physicianId } }) {
  try {
    yield put(retrieveReferringPhysician.request());
    const parameters = { id: physicianId };
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call(
      [api['referring-physicians'], 'retrieveReferringPhysician'],
      parameters,
    );
    yield put(retrieveReferringPhysician.success(body));
  } catch (error) {
    yield put(retrieveReferringPhysician.failure(error));
    messageError(error?.response?.body.message);
  } finally {
    yield put(retrieveReferringPhysician.fulfill());
  }
}

export function* createReferringPhysicianSaga({
  payload: { values: requestBody },
}) {
  try {
    yield put(createReferringPhysician.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call(
      [api['referring-physicians'], 'createReferringPhysician'],
      { id: requestBody.id },
      { requestBody },
    );
    yield put(createReferringPhysician.success({ body }));
    messageSuccess(MSG_REFERRING_PHYSICIAN_CREATED);
  } catch (error) {
    yield put(createReferringPhysician.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(createReferringPhysician.fulfill());
  }
}

export function* updateReferringPhysicianSaga({
  payload: { values: requestBody },
}) {
  try {
    yield put(updateReferringPhysician.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call(
      [api['referring-physicians'], 'updateReferringPhysician'],
      { id: requestBody.id },
      { requestBody },
    );
    yield put(updateReferringPhysician.success({ body }));
    messageSuccess(MSG_REFERRING_PHYSICIAN_UPDATED);
  } catch (error) {
    yield put(updateReferringPhysician.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(updateReferringPhysician.fulfill());
  }
}

export function* mergeReferringPhysicianSaga({
  payload: { values: requestBody },
}) {
  try {
    yield put(mergeReferringPhysician.request());
    const api = yield call([SDK, 'getApi']);
    yield call(
      [api['referring-physicians'], 'mergeReferringPhysician'],
      { id: requestBody.id },
      { requestBody },
    );
    yield put(mergeReferringPhysician.success());
    messageSuccess(MSG_REFERRING_PHYSICIAN_UPDATED);
  } catch (error) {
    yield put(mergeReferringPhysician.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(mergeReferringPhysician.fulfill());
  }
}

export function* dropdownReferringPhysiciansSaga() {
  try {
    yield put(dropdownReferringPhysicians.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call(
      [api['referring-physicians'], 'listDropdownReferringPhysicians'],
      {},
    );
    yield put(dropdownReferringPhysicians.success(body));
  } catch (error) {
    yield put(dropdownReferringPhysicians.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(dropdownReferringPhysicians.fulfill());
  }
}

export function* externalDropdownReferringPhysiciansSaga() {
  try {
    yield put(externalDropdownReferringPhysicians.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call(
      [api.external, 'externalListDropdownReferringPhysicians'],
      {},
    );
    yield put(externalDropdownReferringPhysicians.success(body));
  } catch (error) {
    yield put(externalDropdownReferringPhysicians.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(externalDropdownReferringPhysicians.fulfill());
  }
}

export function* dropdownPhysicianSpecialtiesSaga() {
  try {
    yield put(dropdownPhysicianSpecialties.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call(
      [api['referring-physicians'], 'listDropdownPhysicianSpecialties'],
      {},
    );
    yield put(dropdownPhysicianSpecialties.success(body));
  } catch (error) {
    yield put(dropdownPhysicianSpecialties.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(dropdownPhysicianSpecialties.fulfill());
  }
}

export default function* referringPhysiciansWatcher() {
  yield takeLatest(listReferringPhysicians.TRIGGER, listReferringPhysicianSaga);
  yield takeLatest(
    retrieveReferringPhysician.TRIGGER,
    retrieveReferringPhysicianSaga,
  );
  yield takeLatest(
    createReferringPhysician.TRIGGER,
    createReferringPhysicianSaga,
  );
  yield takeLatest(
    updateReferringPhysician.TRIGGER,
    updateReferringPhysicianSaga,
  );
  yield takeLatest(
    mergeReferringPhysician.TRIGGER,
    mergeReferringPhysicianSaga,
  );
  yield takeLatest(
    dropdownReferringPhysicians.TRIGGER,
    dropdownReferringPhysiciansSaga,
  );
  yield takeLatest(
    dropdownPhysicianSpecialties.TRIGGER,
    dropdownPhysicianSpecialtiesSaga,
  );
  yield takeLatest(
    externalDropdownReferringPhysicians.TRIGGER,
    externalDropdownReferringPhysiciansSaga,
  );
}
