/* eslint-disable unicorn/prevent-abbreviations */
import { call, fork, spawn, take, takeLeading } from 'redux-saga/effects';
import { put } from 'redux-saga-test-plan/matchers';
import WS from 'utils/api/ws';

import { authLogout } from 'modules/auth/actions';
import { connectWS } from './actions';

function* connectWSSaga() {
  const socket = yield call([WS, 'getWs']);
  if (socket) {
    try {
      const channelSession = yield call([WS, 'createChannel']);
      yield fork(disconnectWSOnLogoutSaga);
      while (true) {
        const actionSession = yield take(channelSession);
        yield put(actionSession);
      }
    } catch (error) {
      socket.close();
      throw error;
    }
  }
}

export function* disconnectWSOnLogoutSaga() {
  const socket = yield call([WS, 'getWs']);
  try {
    while (true) {
      yield take(authLogout);
      socket.off('disconnect', () => {});
      socket.disconnect();
    }
  } catch {
    socket.disconnect();
  } finally {
    socket.disconnect();
  }
}

export default function* websocketWatch() {
  yield takeLeading(connectWS.toString(), connectWSSaga);
  yield spawn(connectWSSaga);
}
