const PREFIX = 'ROLES';

export const LIST_ROLES = `${PREFIX}/LIST`;
export const RETRIEVE_ROLE = `${PREFIX}/RETRIEVE`;
export const CREATE_ROLE = `${PREFIX}/CREATE`;
export const UPDATE_ROLE = `${PREFIX}/UPDATE`;
export const SET_FILTER_ROLE = `${PREFIX}/SET_FILTER`;
export const DROPDOWN_ROLES = `${PREFIX}/DROPDOWN`;

export const LIST_ROLES_ACTIONS = `${PREFIX}/LIST_ACTIONS`;
export const SAVE_ROLE_PERMISSION = `${PREFIX}/SAVE_PERMISSION`;

export const LIST_ROLES_MODULES = `${PREFIX}/LIST_MODULES`;
export const RETRIEVE_ROLE_MODULE = `${PREFIX}/RETRIEVE_MODULE`;
export const CREATE_ROLE_MODULE = `${PREFIX}/CREATE_MODULE`;
export const UPDATE_ROLE_MODULE = `${PREFIX}/UPDATE_MODULE`;
export const DROPDOWN_ROLES_MODULES = `${PREFIX}/DROPDOWN_MODULES`;
