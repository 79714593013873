import { createAction } from 'redux-actions';
import {
  CONNECT_WS,
  DISCONNECT_WS,
  NEW_LISTENER_WS,
  UPDATE_LAST_ACTIVITY,
} from './types';

export const connectWS = createAction(CONNECT_WS);
export const disconnectWS = createAction(DISCONNECT_WS);
export const newListenerWS = createAction(NEW_LISTENER_WS);
export const updateLastActivity = createAction(UPDATE_LAST_ACTIVITY);
