/* eslint-disable sonarjs/no-small-switch */
import produce, { Draft } from 'immer';

import { toggleModalAlert, updateServiceWorker } from './actions';

export type AppState = {
  serviceWorkerUpdated: boolean;
  modal: {
    alert: boolean;
  };
};

const initialState: AppState = {
  serviceWorkerUpdated: false,
  modal: {
    alert: false,
  },
};

const reducer = produce((draft: Draft<AppState>, { type }) => {
  switch (type) {
    case updateServiceWorker.toString():
      draft.serviceWorkerUpdated = true;
      break;
    case toggleModalAlert.toString():
      draft.modal.alert = !draft.modal.alert;
      break;
    // no default
  }
}, initialState);

export default reducer;
