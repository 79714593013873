/* eslint-disable no-param-reassign */
import produce, { Draft } from 'immer';

import { SourceType } from 'types/source';
import { SourceParentType } from 'types/source-parent';
import { listParentSources, listSources, retrieveSource } from './actions';

export type SourceState = {
  loading: boolean;
  error: string | undefined;
  item: SourceType | undefined;
  items: SourceType[];
  parentItems: SourceParentType[];
};

const initialState: SourceState = {
  loading: false,
  error: undefined,
  item: undefined,
  items: [],
  parentItems: [],
};

const reducer = produce((draft: Draft<SourceState>, { type, payload }) => {
  switch (type) {
    case listSources.TRIGGER:
    case retrieveSource.TRIGGER:
      draft.loading = true;
      draft.error = undefined;
      break;

    // SUCCESS
    case listSources.SUCCESS:
      draft.items = payload;
      break;
    case retrieveSource.SUCCESS:
      draft.item = payload;
      break;
    case listParentSources.SUCCESS:
      draft.parentItems = payload;
      break;

    // FAILURE
    case listSources.FAILURE:
    case retrieveSource.FAILURE:
      draft.error = payload;
      break;

    // FULFILL
    case listSources.FULFILL:
    case retrieveSource.FULFILL:
      draft.loading = false;
      break;
    // no default
  }
}, initialState);

export default reducer;
