import { RootState } from '@store/type';
import { createSelector } from 'reselect';
import { UsersState } from './reducer';

export const getUsers = ({ users }: RootState): UsersState => users;

export const getUsersError = createSelector(getUsers, ({ error }) => error);
export const getUsersItems = createSelector(getUsers, ({ items }) => items);
export const getUsersPagination = createSelector(getUsers, ({ meta }) => meta);
export const getUserItem = createSelector(getUsers, ({ item }) => item);
export const getUserFilter = createSelector(getUsers, ({ filter }) => filter);
export const isUserLoading = createSelector(getUsers, ({ loading }) => loading);
export const getUsersDropdown = createSelector(
  getUsers,
  ({ dropdown }) => dropdown,
);
export const getOptionsUserDepartments = createSelector(
  getUsers,
  ({ itemsDepartments }) =>
    itemsDepartments.map(({ id, name }) => ({ value: id, label: name })),
);
export const getOptionsUserLocations = createSelector(
  getUsers,
  ({ itemsLocations }) =>
    itemsLocations.map(({ id, name }) => ({ value: id, label: name })),
);
