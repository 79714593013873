import produce, { Draft } from 'immer';

import { TipType } from 'types/tip';
import { MetaType } from 'types/meta';
import {
  listTips,
  retrieveTip,
  createTip,
  updateTip,
  randomTip,
} from './actions';
import { SET_FILTER_TIP } from './types';

export type TipsState = {
  loading: boolean;
  error: string | undefined;
  item: TipType | undefined;
  tip: TipType | undefined;
  filter: string | undefined;
  items: TipType[];
  meta: MetaType | undefined;
};

const initialState: TipsState = {
  loading: false,
  error: undefined,
  item: undefined,
  tip: undefined,
  filter: undefined,
  items: [],
  meta: undefined,
};

const reducer = produce((draft: Draft<TipsState>, { type, payload }) => {
  switch (type) {
    case listTips.TRIGGER:
    case retrieveTip.TRIGGER:
    case createTip.TRIGGER:
    case updateTip.TRIGGER:
    case randomTip.TRIGGER:
      draft.loading = true;
      draft.error = undefined;
      break;

    // SUCCESS
    case listTips.SUCCESS:
      draft.items = payload.items;
      draft.meta = payload.meta;
      break;
    case retrieveTip.SUCCESS:
    case createTip.SUCCESS:
    case updateTip.SUCCESS:
      draft.item = payload;
      break;
    case randomTip.SUCCESS:
      draft.tip = payload;
      break;

    // FAILURE
    case listTips.FAILURE:
    case retrieveTip.FAILURE:
    case createTip.FAILURE:
    case updateTip.FAILURE:
    case randomTip.FAILURE:
      draft.error = payload;
      break;

    // FULFILL
    case listTips.FULFILL:
    case retrieveTip.FULFILL:
    case createTip.FULFILL:
    case updateTip.FULFILL:
    case randomTip.FULFILL:
      draft.loading = false;
      break;
    case SET_FILTER_TIP:
      draft.filter = payload;
      break;
    // no default
  }
}, initialState);

export default reducer;
