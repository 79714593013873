import { bindRoutineToReduxForm, createRoutine } from 'redux-saga-routines';
import {
  LIST_ROLES,
  RETRIEVE_ROLE,
  CREATE_ROLE,
  UPDATE_ROLE,
  DROPDOWN_ROLES,
  LIST_ROLES_MODULES,
  RETRIEVE_ROLE_MODULE,
  CREATE_ROLE_MODULE,
  UPDATE_ROLE_MODULE,
  DROPDOWN_ROLES_MODULES,
  LIST_ROLES_ACTIONS,
  SAVE_ROLE_PERMISSION,
} from './types';

export const listRoles = createRoutine(LIST_ROLES);
export const retrieveRole = createRoutine(RETRIEVE_ROLE);
export const createRole = createRoutine(CREATE_ROLE);
export const updateRole = createRoutine(UPDATE_ROLE);
export const dropdownRoles = createRoutine(DROPDOWN_ROLES);

export const listRolesModules = createRoutine(LIST_ROLES_MODULES);
export const retrieveRoleModule = createRoutine(RETRIEVE_ROLE_MODULE);
export const createRoleModule = createRoutine(CREATE_ROLE_MODULE);
export const updateRoleModule = createRoutine(UPDATE_ROLE_MODULE);
export const dropdownRolesModules = createRoutine(DROPDOWN_ROLES_MODULES);

export const listRolesActions = createRoutine(LIST_ROLES_ACTIONS);
export const saveRolePermission = createRoutine(SAVE_ROLE_PERMISSION);

export const bindedCreateRole = bindRoutineToReduxForm(createRole);
export const bindedUpdateRole = bindRoutineToReduxForm(updateRole);
export const bindedCreateRoleModule = bindRoutineToReduxForm(createRoleModule);
export const bindedUpdateRoleModule = bindRoutineToReduxForm(updateRoleModule);
export const bindedSaveRolePermission =
  bindRoutineToReduxForm(saveRolePermission);
