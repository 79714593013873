import { call, put, takeLatest } from 'redux-saga/effects';

import SDK from '@utils/api';
import {
  MSG_ROLE_CREATED,
  MSG_ROLE_UPDATED,
  MSG_ROLE_MODULE_SAVE,
} from '@utils/enums/messages';
import {
  messageError,
  messageSuccess,
} from '@views/base-components/toast-messages';

import {
  listRoles,
  retrieveRole,
  createRole,
  updateRole,
  dropdownRoles,
  dropdownRolesModules,
  listRolesModules,
  retrieveRoleModule,
  createRoleModule,
  updateRoleModule,
  listRolesActions,
  saveRolePermission,
} from './actions';

export function* listRolesSaga({ payload }) {
  try {
    yield put(listRoles.request());
    const parameters = {
      search: payload.search,
      page: payload.page,
      perPage: payload.perPage,
    };
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.roles, 'listRoles'], parameters);
    yield put(listRoles.success(body));
  } catch (error) {
    yield put(listRoles.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(listRoles.fulfill());
  }
}

export function* retrieveRoleSaga({ payload: { roleId } }) {
  try {
    yield put(retrieveRole.request());
    const parameters = { id: roleId };
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.roles, 'retrieveRole'], parameters);
    yield put(retrieveRole.success(body));
  } catch (error) {
    yield put(retrieveRole.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(retrieveRole.fulfill());
  }
}

export function* createRoleSaga({ payload: { values: requestBody } }) {
  try {
    yield put(createRole.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.roles, 'createRole'], {}, { requestBody });
    yield put(createRole.success({ role: body }));
    messageSuccess(MSG_ROLE_CREATED);
  } catch (error) {
    yield put(createRole.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(createRole.fulfill());
  }
}

export function* updateRoleSaga({ payload: { values: requestBody } }) {
  try {
    yield put(updateRole.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call(
      [api.roles, 'updateRole'],
      { id: requestBody.id },
      { requestBody },
    );
    yield put(updateRole.success({ role: body }));
    messageSuccess(MSG_ROLE_UPDATED);
  } catch (error) {
    yield put(updateRole.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(updateRole.fulfill());
  }
}

export function* dropdownRolesSaga() {
  try {
    yield put(dropdownRoles.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.roles, 'listDropdownRoles'], {});
    yield put(dropdownRoles.success(body));
  } catch (error) {
    yield put(dropdownRoles.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(dropdownRoles.fulfill());
  }
}

export function* listRolesActionsSaga() {
  try {
    yield put(listRolesActions.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.roles, 'listRolesActions']);
    yield put(listRolesActions.success(body));
  } catch (error) {
    yield put(listRolesActions.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(listRolesActions.fulfill());
  }
}

export function* listRolesModulesSaga({ payload }) {
  try {
    yield put(listRolesModules.request());
    const parameters = {
      search: payload.search,
      page: payload.page,
      perPage: payload.perPage,
    };
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.roles, 'listRolesModules'], parameters);
    yield put(listRolesModules.success(body));
  } catch (error) {
    yield put(listRolesModules.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(listRolesModules.fulfill());
  }
}

export function* retrieveRoleModuleSaga({ payload: { moduleId } }) {
  try {
    yield put(retrieveRoleModule.request());
    const parameters = { id: moduleId };
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.roles, 'retrieveRoleModule'], parameters);
    yield put(retrieveRoleModule.success(body));
  } catch (error) {
    yield put(retrieveRoleModule.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(retrieveRoleModule.fulfill());
  }
}

export function* createRoleModuleSaga({ payload: { values: requestBody } }) {
  try {
    yield put(createRoleModule.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call(
      [api.roles, 'createRoleModule'],
      {},
      { requestBody },
    );
    yield put(createRoleModule.success({ module: body }));
    messageSuccess(MSG_ROLE_MODULE_SAVE);
  } catch (error) {
    yield put(createRoleModule.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(createRoleModule.fulfill());
  }
}

export function* dropdownRolesModulesSaga() {
  try {
    yield put(dropdownRolesModules.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.roles, 'listDropdownRolesModules'], {});
    yield put(dropdownRolesModules.success(body));
  } catch (error) {
    yield put(dropdownRolesModules.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(dropdownRolesModules.fulfill());
  }
}

export function* updateRoleModuleSaga({ payload: { values: requestBody } }) {
  try {
    yield put(updateRoleModule.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call(
      [api.roles, 'updateRoleModule'],
      { id: requestBody.id },
      { requestBody },
    );
    yield put(updateRoleModule.success({ module: body }));
    messageSuccess(MSG_ROLE_MODULE_SAVE);
  } catch (error) {
    yield put(updateRoleModule.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(updateRoleModule.fulfill());
  }
}

export function* saveRolePermissionSaga({ payload: { values: requestBody } }) {
  try {
    yield put(saveRolePermission.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call(
      [api.roles, 'saveRolePermissions'],
      { id: requestBody.id },
      { requestBody },
    );
    yield put(saveRolePermission.success(body));
    messageSuccess(MSG_ROLE_MODULE_SAVE);
  } catch (error) {
    yield put(saveRolePermission.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(saveRolePermission.fulfill());
  }
}

export default function* rolesWatcher() {
  yield takeLatest(listRoles.TRIGGER, listRolesSaga);
  yield takeLatest(retrieveRole.TRIGGER, retrieveRoleSaga);
  yield takeLatest(createRole.TRIGGER, createRoleSaga);
  yield takeLatest(updateRole.TRIGGER, updateRoleSaga);
  yield takeLatest(dropdownRoles.TRIGGER, dropdownRolesSaga);
  yield takeLatest(listRolesActions.TRIGGER, listRolesActionsSaga);
  yield takeLatest(listRolesModules.TRIGGER, listRolesModulesSaga);
  yield takeLatest(retrieveRoleModule.TRIGGER, retrieveRoleModuleSaga);
  yield takeLatest(createRoleModule.TRIGGER, createRoleModuleSaga);
  yield takeLatest(updateRoleModule.TRIGGER, updateRoleModuleSaga);
  yield takeLatest(dropdownRolesModules.TRIGGER, dropdownRolesModulesSaga);
  yield takeLatest(saveRolePermission.TRIGGER, saveRolePermissionSaga);
}
