import produce, { Draft } from 'immer';

import { ClinicType } from 'types/clinic';
import { MetaType } from 'types/meta';
import {
  listClinics,
  retrieveClinic,
  createClinic,
  updateClinic,
  dropdownClinics,
  externalDropdownClinics,
} from './actions';
import { SET_FILTER_CLINIC } from './types';

export type ClinicsState = {
  loading: boolean;
  error: string | undefined;
  item: ClinicType | undefined;
  filter: string | undefined;
  items: ClinicType[];
  meta: MetaType | undefined;
  dropdown: ClinicType[];
};

const initialState: ClinicsState = {
  loading: false,
  error: undefined,
  item: undefined,
  filter: undefined,
  items: [],
  meta: undefined,
  dropdown: [],
};

const reducer = produce((draft: Draft<ClinicsState>, { type, payload }) => {
  switch (type) {
    case listClinics.TRIGGER:
    case retrieveClinic.TRIGGER:
    case createClinic.TRIGGER:
    case updateClinic.TRIGGER:
    case dropdownClinics.TRIGGER:
    case externalDropdownClinics.TRIGGER:
      draft.loading = true;
      draft.error = undefined;
      break;

    // SUCCESS
    case listClinics.SUCCESS:
      draft.items = payload.items;
      draft.meta = payload.meta;
      break;
    case retrieveClinic.SUCCESS:
    case createClinic.SUCCESS:
    case updateClinic.SUCCESS:
      draft.item = payload;
      break;
    case dropdownClinics.SUCCESS:
    case externalDropdownClinics.SUCCESS:
      draft.dropdown = payload;
      break;

    // FAILURE
    case listClinics.FAILURE:
    case retrieveClinic.FAILURE:
    case createClinic.FAILURE:
    case updateClinic.FAILURE:
    case dropdownClinics.FAILURE:
    case externalDropdownClinics.FAILURE:
      draft.error = payload;
      break;

    // FULFILL
    case listClinics.FULFILL:
    case retrieveClinic.FULFILL:
    case createClinic.FULFILL:
    case updateClinic.FULFILL:
    case dropdownClinics.FULFILL:
    case externalDropdownClinics.FULFILL:
      draft.loading = false;
      break;
    case SET_FILTER_CLINIC:
      draft.filter = payload;
      break;
    // no default
  }
}, initialState);

export default reducer;
