import { applyMiddleware, compose, createStore } from 'redux';
import { persistStore } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'connected-react-router';
import * as Sentry from '@sentry/react';
import LogRocket from 'logrocket';

import history from './history';
import reducer from './reducer';
import saga from './saga';

const sagaMiddleware = createSagaMiddleware();
const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

const composeEnhancers =
  typeof window === 'object' &&
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose;

const middlewares = [
  sagaMiddleware,
  routerMiddleware(history),
  LogRocket.reduxMiddleware(),
];

const enhancers = [applyMiddleware(...middlewares)];

const store = createStore(
  reducer,
  composeEnhancers(...enhancers, sentryReduxEnhancer),
);

const persistor = persistStore(store);

sagaMiddleware.run(saga);

export { store, persistor };
