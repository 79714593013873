import { createAction } from 'redux-actions';
import { createRoutine, bindRoutineToReduxForm } from 'redux-saga-routines';
import {
  LIST_LEADS,
  CREATE_LEAD,
  UPDATE_LEAD,
  CREATE_LEAD_FROM_SCRIPT,
  UPDATE_LEAD_FROM_SCRIPT,
  UPDATED_LEADS,
  SEARCH_LEAD,
  RETRIEVE_LEAD,
  ALL_HISTORY_LEAD,
  BLOCKING_LEAD,
  UNBLOCKING_LEAD,
  RESCHEDULE,
  CHANGE_GROUPS,
  CREATE_NOTE,
  VIEW_NOTES,
  CLEAR_LEAD,
  CLOSE_NOTES,
  CREATE_TEMPORAL_NOTE,
  CLEAR_TEMPORAL_NOTES,
  CREATE_BULK_TEMPORAL,
  CHECK_DUPLICATE_LEAD,
  MODAL_DUPLICATE_LEAD,
  ARCHIVE_LEAD,
  CHANGE_GROUP,
  CHANGE_SUBGROUP,
  UPDATE_REFERRING_PHYSICIAN_LEAD,
  UPDATE_REFERRAL_STATUS_LEAD,
  SET_REFERRAL_STATUS_LEAD,
  GET_INTERCEPTION_CHANGE_GROUP,
  GET_ALL_RETRIEVE_LEAD_INFO,
  SET_SCHEDULE,
  TOGGLE_SCHEDULE,
  GET_IS_OVERDUE,
  MODAL_INTERSECTION,
  SET_INTERSECTION_COMMENT,
  SET_INTERSECTION_RATING,
  LOCKED_LEAD,
  UNLOCKED_LEAD,
  UPDATED_CHANGE_GROUPS_LEAD,
  UPDATED_DEMOGRAPHICS_LEAD,
  UPDATED_NOTES_LEAD,
  TOGGLE_IS_PHONE_COPIED,
  TOGGLE_PHONE_COPY_TOOLTIP,
  TOGGLE_LOCATION_TOOLTIP,
} from './types';

export const listLeads = createRoutine(LIST_LEADS);
export const createLead = createRoutine(CREATE_LEAD);
export const updateLead = createRoutine(UPDATE_LEAD);
export const createLeadFromScript = createRoutine(CREATE_LEAD_FROM_SCRIPT);
export const updateLeadFromScript = createRoutine(UPDATE_LEAD_FROM_SCRIPT);
export const searchLead = createRoutine(SEARCH_LEAD);
export const retrieveLead = createRoutine(RETRIEVE_LEAD);
export const getAllRetrieveLeadInfo = createRoutine(GET_ALL_RETRIEVE_LEAD_INFO);

export const allHistoryLead = createRoutine(ALL_HISTORY_LEAD);
export const blockingLead = createRoutine(BLOCKING_LEAD);
export const unblockingLead = createRoutine(UNBLOCKING_LEAD);
export const reschedule = createRoutine(RESCHEDULE);
export const changeGroups = createRoutine(CHANGE_GROUPS);
export const viewNotes = createRoutine(VIEW_NOTES);
export const createNote = createRoutine(CREATE_NOTE);
export const createBulkNote = createRoutine(CREATE_BULK_TEMPORAL);
export const checkDuplicateLead = createRoutine(CHECK_DUPLICATE_LEAD);
export const archiveLead = createRoutine(ARCHIVE_LEAD);
export const updateReferringPhysicianLead = createRoutine(
  UPDATE_REFERRING_PHYSICIAN_LEAD,
);
export const updateReferralStatusLead = createRoutine(
  UPDATE_REFERRAL_STATUS_LEAD,
);
export const getInterceptChangeGroup = createRoutine(
  GET_INTERCEPTION_CHANGE_GROUP,
);
export const overdue = createRoutine(GET_IS_OVERDUE);

export const clearLead = createAction(CLEAR_LEAD);
export const closeNotes = createAction(CLOSE_NOTES);
export const createTemporalNote = createAction(CREATE_TEMPORAL_NOTE);
export const clearTemporaryNotes = createAction(CLEAR_TEMPORAL_NOTES);
export const toggleDuplicateLeadModal = createAction(MODAL_DUPLICATE_LEAD);
export const changeGroup = createAction(CHANGE_GROUP);
export const changeSubgroup = createAction(CHANGE_SUBGROUP);
export const setReferralStatus = createAction(SET_REFERRAL_STATUS_LEAD);
export const toggleSchedule = createAction(TOGGLE_SCHEDULE);
export const setSchedule = createAction(SET_SCHEDULE);
export const toggleIntersectionModal = createAction(MODAL_INTERSECTION);
export const setIntersectionComment = createAction(SET_INTERSECTION_COMMENT);
export const setIntersectionRating = createAction(SET_INTERSECTION_RATING);
export const toggleIsPhoneCopied = createAction(TOGGLE_IS_PHONE_COPIED);
export const togglePhoneCopyTooltip = createAction(TOGGLE_PHONE_COPY_TOOLTIP);
export const toggleLocationTooltip = createAction(TOGGLE_LOCATION_TOOLTIP);

// WS
export const updatedLeads = createAction(UPDATED_LEADS);
export const lockedLead = createAction(LOCKED_LEAD);
export const unlockedLead = createAction(UNLOCKED_LEAD);
export const updatedChangeGroupsLead = createAction(UPDATED_CHANGE_GROUPS_LEAD);
export const updatedDemographicLead = createAction(UPDATED_DEMOGRAPHICS_LEAD);
export const updatedNotesLead = createAction(UPDATED_NOTES_LEAD);

export const bindedCreateLead = bindRoutineToReduxForm(createLead);
export const bindedUpdateLead = bindRoutineToReduxForm(updateLead);
export const bindedCreateLeadFromScript =
  bindRoutineToReduxForm(createLeadFromScript);
export const bindedUpdateLeadFromScript =
  bindRoutineToReduxForm(updateLeadFromScript);
export const bindedChangeGroup = bindRoutineToReduxForm(changeGroups);
export const bindedCreateNote = bindRoutineToReduxForm(createNote);
export const bindedCheckDuplicateLead =
  bindRoutineToReduxForm(checkDuplicateLead);
export const bindedUpdateReferringPhysicianLead = bindRoutineToReduxForm(
  updateReferringPhysicianLead,
);
