export const ROLE_ADMIN = 'Admin';
export const ROLE_SUPER_ADMIN = 'Super Admin';

export const ROLE_ACTIONS = {
  Manage: 'manage',
  Create: 'create',
  Read: 'read',
  Update: 'update',
  Delete: 'delete',
};

export const ROLE_MODULES = {
  Clients: 'Clients',
  Clinics: 'Clinics',
  CustomConditionals: 'CustomConditionals',
  CustomFields: 'CustomFields',
  Groups: 'Groups',
  Insurances: 'Insurances',
  Satisfaction: 'Satisfaction',
  Leads: 'Leads',
  Locations: 'Locations',
  Notes: 'Notes',
  Reports: 'Reports',
  Roles: 'Roles',
  Scripts: 'Scripts',
  States: 'States',
  SourceParent: 'SourceParent',
  Sources: 'Sources',
  Timezones: 'Timezones',
  Users: 'Users',
  Widgets: 'Widgets',
  PhysicianReferrals: 'PhysicianReferrals',
  ReferringPhysicians: 'ReferringPhysicians',
  Tips: 'Tips',
  ViewAdministrator: 'ViewAdministrator',
};
