import { createRoutine, bindRoutineToReduxForm } from 'redux-saga-routines';
import {
  LIST_CAMPAIGNS,
  RETRIEVE_CAMPAIGN,
  CREATE_CAMPAIGN,
  UPDATE_CAMPAIGN,
} from './types';

export const listCampaigns = createRoutine(LIST_CAMPAIGNS);
export const retrieveCampaign = createRoutine(RETRIEVE_CAMPAIGN);
export const createCampaign = createRoutine(CREATE_CAMPAIGN);
export const updateCampaign = createRoutine(UPDATE_CAMPAIGN);

export const bindedCreateCampaign = bindRoutineToReduxForm(createCampaign);
export const bindedUpdateCampaign = bindRoutineToReduxForm(updateCampaign);
