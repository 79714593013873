import produce, { Draft } from 'immer';

import { GroupType } from 'types/group';
import {
  listGroups,
  listGroupsAndSubgroups,
  createGroup,
  retrieveGroup,
  updateGroup,
  deleteGroup,
  bulkGroups,
  createSubgroup,
  retrieveSubgroup,
  updateSubgroup,
  deleteSubgroup,
} from './actions';

export type GroupsState = {
  loading: boolean;
  error: string | undefined;
  item: GroupType | undefined;
  subItem: GroupType | undefined;
  items: GroupType[];
  itemsFilter: GroupType[];
};

const initialState: GroupsState = {
  loading: false,
  error: undefined,
  item: undefined,
  subItem: undefined,
  items: [],
  itemsFilter: [],
};

const reducer = produce((draft: Draft<GroupsState>, { type, payload }) => {
  switch (type) {
    case listGroups.TRIGGER:
    case listGroupsAndSubgroups.TRIGGER:
    case createGroup.TRIGGER:
    case retrieveGroup.TRIGGER:
    case updateGroup.TRIGGER:
    case deleteGroup.TRIGGER:
    case bulkGroups.TRIGGER:
    case createSubgroup.TRIGGER:
    case retrieveSubgroup.TRIGGER:
    case updateSubgroup.TRIGGER:
    case deleteSubgroup.TRIGGER:
      draft.loading = true;
      draft.error = undefined;
      break;

    // SUCCESS
    case listGroups.SUCCESS:
    case bulkGroups.SUCCESS:
      draft.items = payload;
      break;
    case listGroupsAndSubgroups.SUCCESS:
      draft.itemsFilter = payload;
      break;
    case createGroup.SUCCESS:
    case updateGroup.SUCCESS:
    case deleteGroup.SUCCESS:
      draft.item = payload;
      draft.subItem = undefined;
      break;
    case retrieveGroup.SUCCESS:
      draft.item = payload;
      break;
    case createSubgroup.SUCCESS:
    case retrieveSubgroup.SUCCESS:
    case updateSubgroup.SUCCESS:
      draft.subItem = payload;
      break;
    case deleteSubgroup.SUCCESS:
      draft.subItem = undefined;
      break;

    // FAILURE
    case listGroups.FAILURE:
    case listGroupsAndSubgroups.FAILURE:
    case createGroup.FAILURE:
    case retrieveGroup.FAILURE:
    case updateGroup.FAILURE:
    case deleteGroup.FAILURE:
    case bulkGroups.FAILURE:
    case createSubgroup.FAILURE:
    case retrieveSubgroup.FAILURE:
    case updateSubgroup.FAILURE:
    case deleteSubgroup.FAILURE:
      draft.error = payload;
      break;

    // FULFILL
    case listGroups.FULFILL:
    case listGroupsAndSubgroups.FULFILL:
    case createGroup.FULFILL:
    case retrieveGroup.FULFILL:
    case updateGroup.FULFILL:
    case deleteGroup.FULFILL:
    case bulkGroups.FULFILL:
    case createSubgroup.FULFILL:
    case retrieveSubgroup.FULFILL:
    case updateSubgroup.FULFILL:
    case deleteSubgroup.FULFILL:
      draft.loading = false;
      break;
    // no default
  }
}, initialState);

export default reducer;
