import { bindRoutineToReduxForm, createRoutine } from 'redux-saga-routines';
import {
  LIST_GROUPS,
  LIST_SUBGROUPS,
  LIST_GROUPS_AND_SUBGROUPS,
  CREATE_GROUP,
  RETRIEVE_GROUP,
  UPDATE_GROUP,
  DELETE_GROUP,
  BULK_GROUPS,
  CREATE_SUBGROUP,
  RETRIEVE_SUBGROUP,
  UPDATE_SUBGROUP,
  DELETE_SUBGROUP,
} from './types';

export const listGroups = createRoutine(LIST_GROUPS);
export const listSubgroups = createRoutine(LIST_SUBGROUPS);
export const listGroupsAndSubgroups = createRoutine(LIST_GROUPS_AND_SUBGROUPS);
export const createGroup = createRoutine(CREATE_GROUP);
export const retrieveGroup = createRoutine(RETRIEVE_GROUP);
export const updateGroup = createRoutine(UPDATE_GROUP);
export const deleteGroup = createRoutine(DELETE_GROUP);
export const bulkGroups = createRoutine(BULK_GROUPS);
export const createSubgroup = createRoutine(CREATE_SUBGROUP);
export const retrieveSubgroup = createRoutine(RETRIEVE_SUBGROUP);
export const updateSubgroup = createRoutine(UPDATE_SUBGROUP);
export const deleteSubgroup = createRoutine(DELETE_SUBGROUP);

export const bindedCreateGroup = bindRoutineToReduxForm(createGroup);
export const bindedUpdateGroup = bindRoutineToReduxForm(updateGroup);
export const bindedCreateSubgroup = bindRoutineToReduxForm(createSubgroup);
export const bindedUpdateSubgroup = bindRoutineToReduxForm(updateSubgroup);
