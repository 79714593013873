import { toast } from 'react-toastify';
import classnames from 'classnames';
import ToastUpdated from '@views/components/toast-updated';
import ToastIcon from '@views/components/toast-icon';

const config = {
  position: toast.POSITION.BOTTOM_RIGHT,
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
  progress: undefined,
};

const iconType = (success: boolean) => (
  <div
    data-testid="test__toast-message"
    className={classnames('ui__toast', {
      'ui__toast--success': success,
      'ui__toast--error': !success,
    })}
  >
    <i
      className={classnames({
        'icon-Atom_Icon-Circular-done': success,
        'icon-Atom_Icon-Warning': !success,
      })}
    />
  </div>
);

export const messageSuccess = (message: string, isUnique = false) => {
  Object.assign(config, { toastId: isUnique ? Math.random() : message });
  toast.success(message, { ...config, icon: iconType(true) });
};

export const messageError = (message: string, isUnique = false) => {
  Object.assign(config, { toastId: isUnique ? Math.random() : message });
  toast.error(message, { ...config, icon: iconType(false) });
};

export const messageUpdated = () => {
  toast(<ToastUpdated />, {
    isLoading: false,
    closeOnClick: false,
    position: 'bottom-right',
    closeButton: false,
    hideProgressBar: true,
    autoClose: false,
    toastId: 'toast-updated',
  });
};

export const toastIcon = (icon: string, message: string) => {
  toast(<ToastIcon icon={icon} message={message} />, {
    position: 'bottom-right',
    closeButton: true,
    hideProgressBar: true,
    toastId: 'toast-icon',
  });
};
