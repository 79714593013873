import produce, { Draft } from 'immer';
import { unsuscribeEmail } from './actions';

export type ExternalState = {
  loading: boolean;
  error: string | undefined;
};

const initialState: ExternalState = {
  loading: false,
  error: undefined,
};

const reducer = produce((draft: Draft<ExternalState>, { type, payload }) => {
  switch (type) {
    case unsuscribeEmail.TRIGGER:
      draft.loading = true;
      draft.error = undefined;
      break;

    // FAILURE
    case unsuscribeEmail.FAILURE:
      draft.error = payload;
      break;

    // FULFILL
    case unsuscribeEmail.FULFILL:
      draft.loading = false;
      break;
    // no default
  }
}, initialState);

export default reducer;
