import { call, put, takeLatest } from 'redux-saga/effects';

import SDK from '@utils/api';
import { MSG_TIP_CREATED, MSG_TIP_UPDATED } from '@utils/enums/messages';
import {
  messageError,
  messageSuccess,
} from '@views/base-components/toast-messages';

import {
  listTips,
  retrieveTip,
  createTip,
  updateTip,
  randomTip,
} from './actions';

export function* listTipsSaga({ payload }) {
  try {
    yield put(listTips.request());
    const parameters = {
      search: payload.search,
      page: payload.page,
      perPage: payload.perPage,
    };
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.tips, 'listTips'], parameters);
    yield put(listTips.success(body));
  } catch (error) {
    yield put(listTips.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(listTips.fulfill());
  }
}

export function* randomTipSaga() {
  try {
    yield put(randomTip.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.tips, 'getTipRandom'], {});
    yield put(randomTip.success(body));
  } catch (error) {
    yield put(randomTip.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(randomTip.fulfill());
  }
}

export function* retrieveTipSaga({ payload: { tipId } }) {
  try {
    yield put(retrieveTip.request());
    const parameters = { id: tipId };
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.tips, 'retrieveTip'], parameters);
    yield put(retrieveTip.success(body));
  } catch (error) {
    yield put(retrieveTip.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(retrieveTip.fulfill());
  }
}

export function* createTipSaga({ payload: { values: requestBody } }) {
  try {
    yield put(createTip.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.tips, 'createTip'], {}, { requestBody });
    yield put(createTip.success({ body }));
    messageSuccess(MSG_TIP_CREATED);
  } catch (error) {
    yield put(createTip.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(createTip.fulfill());
  }
}

export function* updateTipSaga({ payload: { values: requestBody } }) {
  try {
    yield put(updateTip.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call(
      [api.tips, 'updateTip'],
      { id: requestBody.id },
      { requestBody },
    );
    yield put(updateTip.success({ body }));
    messageSuccess(MSG_TIP_UPDATED);
  } catch (error) {
    yield put(updateTip.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(updateTip.fulfill());
  }
}

export default function* tipsWatcher() {
  yield takeLatest(listTips.TRIGGER, listTipsSaga);
  yield takeLatest(retrieveTip.TRIGGER, retrieveTipSaga);
  yield takeLatest(createTip.TRIGGER, createTipSaga);
  yield takeLatest(updateTip.TRIGGER, updateTipSaga);
  yield takeLatest(randomTip.TRIGGER, randomTipSaga);
}
