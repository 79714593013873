const PREFIX = 'SCRIPTS';

export const LIST_SCRIPTS = `${PREFIX}/LIST`;
export const RETRIEVE_SCRIPT = `${PREFIX}/RETRIEVE`;
export const CREATE_SCRIPT = `${PREFIX}/CREATE`;
export const UPDATE_SCRIPT = `${PREFIX}/UPDATE`;
export const LIST_WIDGETS = `${PREFIX}/LIST_WIDGETS`;
export const GET_GROUP_SCRIPT = `${PREFIX}/GET_GROUP`;
export const GENERAL_SCRIPT = `${PREFIX}/GENERAL`;
export const PREVIEW_SCRIPT = `${PREFIX}/PREVIEW`;

export const CREATE_LEAD_FROM_SCRIPT = `${PREFIX}/CREATE_LEAD`;
export const SAVE_SCRIPT = `${PREFIX}/SAVE`;
export const CLEAR_LEAD_SCRIPT = `${PREFIX}/CLEAR_LEAD`;
export const GET_LEAD_SCRIPT_BY_ID = `${PREFIX}/GET_LEAD`;
