import { call, put, takeLatest } from 'redux-saga/effects';

import SDK from '@utils/api';
import { messageError } from '@views/base-components/toast-messages';

import { listTimezones, getTimezone } from './actions';

export function* listTimezonesSaga() {
  try {
    yield put(listTimezones.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.timezones, 'getTimezones']);
    yield put(listTimezones.success(body));
  } catch (error) {
    yield put(listTimezones.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(listTimezones.fulfill());
  }
}

export function* getTimezoneSaga({ payload: { id } }) {
  try {
    yield put(getTimezone.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.timezones, 'getTimezone'], { id });
    yield put(getTimezone.success(body));
  } catch (error) {
    yield put(getTimezone.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(getTimezone.fulfill());
  }
}

export default function* timezonesWatcher() {
  yield takeLatest(listTimezones.TRIGGER, listTimezonesSaga);
  yield takeLatest(getTimezone.TRIGGER, getTimezoneSaga);
}
