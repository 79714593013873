import produce, { Draft } from 'immer';

import {
  restartNextPriority,
  retrieveLeadNextPriority,
  toggleEndWaitListNextPriorityModal,
  workNextPriority,
} from './actions';

export type NextPriorityState = {
  loading: boolean;
  error: string | undefined;
  leadId: string | undefined;
  showModalEndWaitList: boolean;
  work: boolean;
};

const initialState: NextPriorityState = {
  loading: false,
  error: undefined,
  leadId: undefined,
  showModalEndWaitList: false,
  work: false,
};

const reducer = produce(
  (draft: Draft<NextPriorityState>, { type, payload }) => {
    switch (type) {
      case retrieveLeadNextPriority.TRIGGER:
        draft.loading = true;
        draft.error = undefined;
        break;

      // SUCCESS
      case retrieveLeadNextPriority.SUCCESS:
        draft.loading = false;
        draft.leadId = payload.leadId;
        draft.work = payload.work;
        draft.showModalEndWaitList = payload.status;
        break;

      // FAILURE
      case retrieveLeadNextPriority.FAILURE:
        draft.error = payload;
        break;

      // FULFILL
      case retrieveLeadNextPriority.FULFILL:
        draft.loading = false;
        break;

      // TO_STRING
      case toggleEndWaitListNextPriorityModal.toString():
        draft.showModalEndWaitList = !draft.showModalEndWaitList;
        break;
      case workNextPriority.toString():
        draft.work = !draft.work;
        break;
      case restartNextPriority.toString():
        draft.leadId = initialState.leadId;
        draft.work = initialState.work;
        break;
      // no default
    }
  },
  initialState,
);

export default reducer;
