import produce, { Draft } from 'immer';
import { RoleType } from 'types/role';

import { RoleModuleType } from 'types/role-module';
import { RoleActionType } from 'types/role-action';
import { MetaType } from 'types/meta';

import {
  listRoles,
  retrieveRole,
  createRole,
  updateRole,
  dropdownRoles,
  listRolesModules,
  retrieveRoleModule,
  createRoleModule,
  updateRoleModule,
  dropdownRolesModules,
  listRolesActions,
  saveRolePermission,
} from './actions';
import { SET_FILTER_ROLE } from './types';

export type RolesState = {
  loading: boolean;
  error: string | undefined;
  item: RoleType | undefined;
  items: RoleType[];
  filter: string | undefined;
  itemsActions: RoleActionType[];
  isLoadingModule: boolean;
  itemsModules: RoleModuleType[];
  itemModule: RoleModuleType | undefined;
  isLoadingPermissions: boolean;
  meta: MetaType | undefined;
  dropdown: RoleType[];
  dropdownModules: RoleModuleType[];
};

const initialState: RolesState = {
  loading: false,
  error: undefined,
  item: undefined,
  items: [],
  filter: undefined,
  itemsActions: [],
  isLoadingModule: false,
  itemsModules: [],
  itemModule: undefined,
  isLoadingPermissions: false,
  meta: undefined,
  dropdown: [],
  dropdownModules: [],
};

const reducer = produce((draft: Draft<RolesState>, { type, payload }) => {
  switch (type) {
    case listRoles.TRIGGER:
    case createRole.TRIGGER:
    case updateRole.TRIGGER:
    case dropdownRoles.TRIGGER:
    case listRolesActions.TRIGGER:
      draft.loading = true;
      draft.error = undefined;
      break;
    case retrieveRole.TRIGGER:
      draft.loading = true;
      draft.itemsActions = [];
      draft.itemsModules = [];
      draft.error = undefined;
      break;
    case listRolesModules.TRIGGER:
    case createRoleModule.TRIGGER:
    case updateRoleModule.TRIGGER:
    case dropdownRolesModules.TRIGGER:
      draft.isLoadingModule = true;
      draft.error = undefined;
      break;
    case retrieveRoleModule.TRIGGER:
      draft.isLoadingModule = true;
      draft.itemModule = undefined;
      draft.error = undefined;
      break;
    case saveRolePermission.TRIGGER:
      draft.isLoadingPermissions = true;
      draft.error = undefined;
      break;

    // SUCCESS
    case listRoles.SUCCESS:
      draft.items = payload.items;
      draft.meta = payload.meta;
      break;
    case retrieveRole.SUCCESS:
    case saveRolePermission.SUCCESS:
      draft.item = payload;
      break;
    case createRole.SUCCESS:
    case updateRole.SUCCESS:
      draft.item = payload.role;
      break;
    case dropdownRoles.SUCCESS:
      draft.dropdown = payload;
      break;
    case listRolesActions.SUCCESS:
      draft.itemsActions = payload;
      break;
    case listRolesModules.SUCCESS:
      draft.itemsModules = payload.items;
      draft.meta = payload.meta;
      break;
    case retrieveRoleModule.SUCCESS:
      draft.itemModule = payload;
      break;
    case createRoleModule.SUCCESS:
    case updateRoleModule.SUCCESS:
      draft.itemModule = payload.module;
      break;
    case dropdownRolesModules.SUCCESS:
      draft.dropdownModules = payload;
      break;

    // FAILURE
    case listRoles.FAILURE:
    case retrieveRole.FAILURE:
    case createRole.FAILURE:
    case updateRole.FAILURE:
    case dropdownRoles.FAILURE:
    case listRolesModules.FAILURE:
    case retrieveRoleModule.FAILURE:
    case createRoleModule.FAILURE:
    case updateRoleModule.FAILURE:
    case dropdownRolesModules.FAILURE:
    case listRolesActions.FAILURE:
    case saveRolePermission.FAILURE:
      draft.error = payload;
      break;

    // FULFILL
    case listRoles.FULFILL:
    case retrieveRole.FULFILL:
    case createRole.FULFILL:
    case updateRole.FULFILL:
    case dropdownRoles.FULFILL:
    case listRolesActions.FULFILL:
      draft.loading = false;
      break;
    case listRolesModules.FULFILL:
    case retrieveRoleModule.FULFILL:
    case createRoleModule.FULFILL:
    case updateRoleModule.FULFILL:
    case dropdownRolesModules.FULFILL:
      draft.isLoadingModule = false;
      break;
    case saveRolePermission.FULFILL:
      draft.isLoadingPermissions = false;
      break;
    case SET_FILTER_ROLE:
      draft.filter = payload;
      break;
    // no default
  }
}, initialState);

export default reducer;
