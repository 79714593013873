import { call, put, takeLatest } from 'redux-saga/effects';

import SDK from '@utils/api';
import {
  MSG_LOCATION_CREATED,
  MSG_LOCATION_UPDATED,
} from '@utils/enums/messages';
import {
  messageError,
  messageSuccess,
} from '@views/base-components/toast-messages';

import {
  listLocations,
  retrieveLocation,
  createLocation,
  updateLocation,
  dropdownLocations,
} from './actions';

export function* listLocationsSaga({ payload }) {
  try {
    yield put(listLocations.request());
    const parameters = {
      clinicId: payload.clinicId,
      search: payload.search,
      page: payload.page,
      perPage: payload.perPage,
    };
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.locations, 'getLocations'], parameters);
    yield put(listLocations.success(body));
  } catch (error) {
    yield put(listLocations.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(listLocations.fulfill());
  }
}

export function* retrieveLocationSaga({ payload: { locationId } }) {
  try {
    yield put(retrieveLocation.request());
    const parameters = { id: locationId };
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call(
      [api.locations, 'retrieveLocation'],
      parameters,
    );
    yield put(retrieveLocation.success(body));
  } catch (error) {
    yield put(retrieveLocation.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(retrieveLocation.fulfill());
  }
}

export function* createLocationSaga({ payload: { values } }) {
  try {
    yield put(createLocation.request());
    const api = yield call([SDK, 'getApi']);

    let timezone = '';
    if (values.timezone && values.timezone.length > 0) {
      // eslint-disable-next-line prefer-destructuring
      timezone = values.timezone[0];
    }

    const requestBody = {
      ...values,
      clinic: {
        id: values.clinic,
      },
      timezone,
    };

    const { body } = yield call(
      [api.locations, 'createLocation'],
      {},
      { requestBody },
    );
    yield put(createLocation.success(body));
    messageSuccess(MSG_LOCATION_CREATED);
  } catch (error) {
    yield put(createLocation.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(createLocation.fulfill());
  }
}

export function* updateLocationSaga({ payload: { values } }) {
  try {
    yield put(updateLocation.request());
    const api = yield call([SDK, 'getApi']);
    let timezone = '';
    if (values.timezone && values.timezone.length > 0) {
      // eslint-disable-next-line prefer-destructuring
      timezone = values.timezone[0];
    }
    const requestBody = {
      ...values,
      timezone,
    };
    const { body } = yield call(
      [api.locations, 'updateLocation'],
      { id: requestBody.id },
      { requestBody },
    );
    yield put(updateLocation.success(body));
    messageSuccess(MSG_LOCATION_UPDATED);
  } catch (error) {
    yield put(updateLocation.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(updateLocation.fulfill());
  }
}

export function* dropdownLocationsSaga() {
  try {
    yield put(dropdownLocations.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.locations, 'listDropdownLocations'], {});
    yield put(dropdownLocations.success(body));
  } catch (error) {
    yield put(dropdownLocations.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(dropdownLocations.fulfill());
  }
}

export default function* locationsWatcher() {
  yield takeLatest(listLocations.TRIGGER, listLocationsSaga);
  yield takeLatest(retrieveLocation.TRIGGER, retrieveLocationSaga);
  yield takeLatest(createLocation.TRIGGER, createLocationSaga);
  yield takeLatest(updateLocation.TRIGGER, updateLocationSaga);
  yield takeLatest(dropdownLocations.TRIGGER, dropdownLocationsSaga);
}
