import { call, put, takeLatest } from 'redux-saga/effects';

import SDK from '@utils/api';

import { createPhysicianReferral } from './actions';

export function* createPhysicianReferralSaga({ payload: { values } }) {
  try {
    const requestBody = {
      ...values,
      referringPhysician: values.referringPhysician.value,
    };
    yield put(createPhysicianReferral.request());
    const api = yield call([SDK, 'getApi']);
    yield call([api.external, 'createPhysicianReferral'], {}, { requestBody });
    yield put(createPhysicianReferral.success());
  } catch (error) {
    yield put(createPhysicianReferral.failure(error));
  } finally {
    yield put(createPhysicianReferral.fulfill());
  }
}

export default function* physicianReferralsWatcher() {
  yield takeLatest(
    createPhysicianReferral.TRIGGER,
    createPhysicianReferralSaga,
  );
}
