/* eslint-disable no-underscore-dangle */
import {
  LOCATION_CHANGE,
  createMatchSelector,
  push,
} from 'connected-react-router';
import { call, cancel, put, select, takeEvery } from 'redux-saga/effects';

import { ROLE_ACTIONS } from '@utils/enums/roles';
import { caslIsAllowed } from '@utils/casl/ability';
// eslint-disable-next-line import/no-extraneous-dependencies
import cloneDeep from 'clone-deep';
import { getAuthIsLoggedIn } from '@modules/auth/selectors';
import { getMeAbility } from '@modules/me/selectors';
import { matchPath } from 'react-router-dom';
import queryString from 'query-string';
import routes from 'routes/config';
import { getCurrentLocation, getFilters } from './selectors';
import { setUrlFilters, updateFiltersFromUrl } from './actions';

export const flatRoutes = (data) =>
  data.reduce((previous, element) => {
    const innerRoutes = element?.routes;
    // eslint-disable-next-line no-param-reassign
    delete element.routes;
    previous.push(element);
    innerRoutes && previous.push(...flatRoutes(innerRoutes));
    return previous;
  }, []);

export function* locationChangeSaga() {
  try {
    const paths = flatRoutes(cloneDeep(routes));
    const currentLocation = yield select(getCurrentLocation);
    const matches = paths
      .filter(({ path }) => path && path !== '*')
      .filter((path) => matchPath(currentLocation.pathname, path));

    if (!matches || matches.length === 0) {
      yield cancel();
    }

    if (currentLocation.pathname !== '/not-permissions') {
      yield call(checkURLPermissions, matches, currentLocation.pathname);
    }
    const search = yield call(setURLFilter, matches, currentLocation);
    yield call(runURLActions, matches, search);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    throw error;
  }
}

export function* setUrlFiltersSaga({ payload }) {
  try {
    const currentLocation = yield select(getCurrentLocation);
    const filters = yield select(getFilters);
    let newFilters = {};
    if (Object.keys(payload).length > 0) {
      newFilters = { ...filters, ...payload };
    }

    const qs = queryString.stringify(newFilters, {
      skipNull: true,
      skipEmptyString: true,
    });
    let path = currentLocation.pathname;

    if (qs) {
      path += `?${qs}`;
    }
    yield put(push(path));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    throw error;
  }
}

// eslint-disable-next-line require-yield
export function* checkURLPermissions(matches, pathname) {
  const isLoggedIn = yield select(getAuthIsLoggedIn);
  const ability = yield select(getMeAbility);
  const isPrivate = matches.some(({ restricted }) => restricted);
  const isPublic = matches.some(({ published }) => published);
  const currentPath = matches.find((item) =>
    matchPath(pathname, { path: item.path, exact: true, strict: true }),
  );

  if (isPrivate && !isLoggedIn) {
    yield put(push('/'));
    yield cancel();
  } else if (isPublic && isLoggedIn) {
    yield put(push(`/app/home`));
    yield cancel();
  } else if (isPrivate && isLoggedIn) {
    const isAllowed = yield call(
      caslIsAllowed,
      ability,
      ROLE_ACTIONS.Read,
      currentPath.permissions,
    );
    if (!isAllowed) {
      yield put(push(`/not-permissions`));
      yield cancel();
    }
  }
}

export function* setURLFilter(matches, currentLocation) {
  const initialSearch = queryString.parse(currentLocation.search, {
    ignoreQueryPrefix: true,
  });
  const filters = matches
    .filter(({ defaultFilters }) => defaultFilters)
    .reduce((accumulator, current) => {
      Object.assign(accumulator, current.defaultFilters);
      return accumulator;
    }, {});

  const search = { ...filters, ...initialSearch };
  for (const item in search) {
    if (search[item] === '' || search[item] === undefined) {
      delete search[item];
    }
  }

  yield put(updateFiltersFromUrl(search));
  return search;
}

export function* runURLActions(matches, search) {
  for (const match of matches) {
    const matchSelector = createMatchSelector(match.path);
    const parametersMatch = yield select(matchSelector);
    if (match.actions) {
      for (const action of match.actions) {
        yield put(action({ ...parametersMatch?.params, ...search }));
      }
    }
  }
}

export default function* navWatch() {
  yield takeEvery(LOCATION_CHANGE, locationChangeSaga);
  yield takeEvery(setUrlFilters.toString(), setUrlFiltersSaga);
}
