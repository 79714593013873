/* eslint-disable no-param-reassign */
const renderCustomValues = (values: Record<string, any>) =>
  Object.keys(values).reduce((accumulator: string[], field: string) => {
    if (field === 'scriptId') {
      return accumulator;
    }

    if (!('enabled' in values[field]) && 'value' in values[field]) {
      if (values[field].value === true || values[field].value === false) {
        values[field].value = String(values[field].value);
      } else if (
        typeof values[field].value === 'object' &&
        values[field].value
      ) {
        if (values[field].value instanceof Date) {
          values[field].value = values[field].value.toISOString();
        } else {
          values[field].value = values[field].value.toString();
        }
      }
      accumulator.push(values[field]);
    }

    return accumulator;
  }, []);

export default renderCustomValues;
