import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import SDK from '@utils/api';
import { messageError } from '@views/base-components/toast-messages';
import { getFilters } from '@modules/nav/selectors';

import { listWaitList, lastUpdateWaitList, setWaitList } from './actions';

export function* listWaitListSaga({ payload: { team } }) {
  try {
    yield put(listWaitList.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api['wait-list'], 'listWaltList'], { team });
    yield put(listWaitList.success(body));
  } catch (error) {
    yield put(listWaitList.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(listWaitList.fulfill());
  }
}

export function* lastUpdateWaitListSaga({ payload }) {
  const globalFilters = yield select(getFilters);
  if (globalFilters?.team === payload.team) {
    yield put(setWaitList(payload));
  }
}

export default function* waitListWatcher() {
  yield takeLatest(listWaitList.TRIGGER, listWaitListSaga);
  yield takeEvery(lastUpdateWaitList, lastUpdateWaitListSaga);
}
