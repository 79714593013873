import { call, put, select, takeLatest } from 'redux-saga/effects';

import { getMeAbility } from '@modules/me/selectors';

import SDK from '@utils/api';
import { messageError } from '@views/base-components/toast-messages';

import { retrieveMe } from './actions';

export function* retrieveMeSaga() {
  try {
    yield put(retrieveMe.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.users, 'getMe'], {});
    if (body) {
      const ability = yield select(getMeAbility);
      yield put(retrieveMe.success({ user: body, ability }));
    }
  } catch (error) {
    yield put(retrieveMe.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(retrieveMe.fulfill());
  }
}

export default function* meWatcher() {
  yield takeLatest(retrieveMe.TRIGGER, retrieveMeSaga);
}
