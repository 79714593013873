import produce, { Draft } from 'immer';

import { InsuranceType } from 'types/insurance';
import { InsuranceStateType } from 'types/insurance-state';
import { InsuranceTypeType } from 'types/insurance-type';
import { MetaType } from 'types/meta';
import {
  listInsurancesStates,
  retrieveInsuranceState,
  createInsuranceState,
  updateInsuranceState,
  dropdownInsurancesStates,
  listInsurances,
  retrieveInsurance,
  createInsurance,
  updateInsurance,
  listInsurancesTypes,
  dropdownInsurances,
  externalDropdownInsurancesStates,
} from './actions';
import { SET_FILTER_INSURANCE, SET_FILTER_INSURANCE_STATE } from './types';

export type InsurancesState = {
  loading: boolean;
  error: string | undefined;
  filter: string | undefined;
  item: InsuranceType | undefined;
  items: InsuranceType[];
  loadingState: boolean;
  errorStates: string | undefined;
  itemStates: InsuranceStateType | undefined;
  itemsStates: InsuranceStateType[];
  itemsTypes: InsuranceTypeType[];
  meta: MetaType | undefined;
  dropdownStates: InsuranceStateType[];
  dropdown: InsuranceType[];
};

const initialState: InsurancesState = {
  loading: false,
  error: undefined,
  filter: undefined,
  item: undefined,
  items: [],
  loadingState: false,
  errorStates: undefined,
  itemStates: undefined,
  itemsStates: [],
  itemsTypes: [],
  meta: undefined,
  dropdownStates: [],
  dropdown: [],
};

const reducer = produce((draft: Draft<InsurancesState>, { type, payload }) => {
  switch (type) {
    case listInsurancesStates.TRIGGER:
    case retrieveInsuranceState.TRIGGER:
    case createInsuranceState.TRIGGER:
    case updateInsuranceState.TRIGGER:
    case dropdownInsurancesStates.TRIGGER:
    case externalDropdownInsurancesStates.TRIGGER:
      draft.loadingState = true;
      draft.errorStates = undefined;
      break;
    case listInsurances.TRIGGER:
    case retrieveInsurance.TRIGGER:
    case createInsurance.TRIGGER:
    case updateInsurance.TRIGGER:
    case dropdownInsurances.TRIGGER:
    case listInsurancesTypes.TRIGGER:
      draft.loading = true;
      draft.error = undefined;
      break;

    // SUCCESS
    case listInsurancesStates.SUCCESS:
      draft.itemsStates = payload.items;
      draft.meta = payload.meta;
      break;
    case retrieveInsuranceState.SUCCESS:
    case createInsuranceState.SUCCESS:
    case updateInsuranceState.SUCCESS:
      draft.itemStates = payload;
      break;
    case dropdownInsurancesStates.SUCCESS:
    case externalDropdownInsurancesStates.SUCCESS:
      draft.dropdownStates = payload;
      break;
    case listInsurances.SUCCESS:
      draft.items = payload.items;
      draft.meta = payload.meta;
      break;
    case retrieveInsurance.SUCCESS:
    case createInsurance.SUCCESS:
    case updateInsurance.SUCCESS:
      draft.item = payload;
      break;
    case dropdownInsurances.SUCCESS:
      draft.dropdown = payload;
      break;
    case listInsurancesTypes.SUCCESS:
      draft.itemsTypes = payload;
      break;

    // FAILURE
    case listInsurancesStates.FAILURE:
    case retrieveInsuranceState.FAILURE:
    case createInsuranceState.FAILURE:
    case updateInsuranceState.FAILURE:
    case dropdownInsurancesStates.FAILURE:
    case externalDropdownInsurancesStates.FAILURE:
      draft.errorStates = payload;
      break;
    case listInsurances.FAILURE:
    case retrieveInsurance.FAILURE:
    case createInsurance.FAILURE:
    case updateInsurance.FAILURE:
    case dropdownInsurances.FAILURE:
    case listInsurancesTypes.FAILURE:
      draft.error = payload;
      break;

    // FULFILL
    case listInsurancesStates.FULFILL:
    case retrieveInsuranceState.FULFILL:
    case createInsuranceState.FULFILL:
    case updateInsuranceState.FULFILL:
    case dropdownInsurancesStates.FULFILL:
      draft.loadingState = false;
      break;
    case listInsurances.FULFILL:
    case retrieveInsurance.FULFILL:
    case createInsurance.FULFILL:
    case updateInsurance.FULFILL:
    case dropdownInsurances.FULFILL:
    case listInsurancesTypes.FULFILL:
      draft.loading = false;
      break;
    case SET_FILTER_INSURANCE_STATE:
    case SET_FILTER_INSURANCE:
      draft.filter = payload;
      break;
    // no default
  }
}, initialState);

export default reducer;
