const PREFIX = 'LEADS';

export const LIST_LEADS = `${PREFIX}/LIST`;
export const CREATE_LEAD = `${PREFIX}/CREATE`;
export const UPDATE_LEAD = `${PREFIX}/UPDATE`;
export const CREATE_LEAD_FROM_SCRIPT = `${PREFIX}/CREATE_FROM_SCRIPT`;
export const UPDATE_LEAD_FROM_SCRIPT = `${PREFIX}/UPDATE_FROM_SCRIPT`;
export const RETRIEVE_LEAD = `${PREFIX}/RETRIEVE`;
export const ALL_HISTORY_LEAD = `${PREFIX}/ALL_HISTORY`;
export const SET_FILTER = `${PREFIX}/SET_FILTER`;
export const SEARCH_LEAD = `${PREFIX}/SEARCH`;
export const CLEAR_LEAD = `${PREFIX}/CLEAR`;
export const BLOCKING_LEAD = `${PREFIX}/BLOCKING`;
export const UNBLOCKING_LEAD = `${PREFIX}/UNBLOCKING`;
export const RESCHEDULE = `${PREFIX}/RESCHEDULE`;
export const CHANGE_GROUPS = `${PREFIX}/CHANGE_GROUPS`;
export const CREATE_BULK_TEMPORAL = `${PREFIX}/CREATE_BULK_TEMPORAL`;
export const CREATE_TEMPORAL_NOTE = `${PREFIX}/CREATE_TEMPORAL_NOTE`;
export const CLEAR_TEMPORAL_NOTES = `${PREFIX}/CLEAR_TEMPORAL_NOTES`;
export const CREATE_NOTE = `${PREFIX}/CREATE_NOTE`;
export const VIEW_NOTES = `${PREFIX}/VIEW_NOTES`;
export const CLOSE_NOTES = `${PREFIX}/CLOSE_NOTES`;
export const CHECK_DUPLICATE_LEAD = `${PREFIX}/CHECK_DUPLICATE`;
export const MODAL_DUPLICATE_LEAD = `${PREFIX}/MODAL_DUPLICATE`;
export const ARCHIVE_LEAD = `${PREFIX}/ARCHIVE`;
export const CHANGE_GROUP = `${PREFIX}/CHANGE_GROUP`;
export const CHANGE_SUBGROUP = `${PREFIX}/CHANGE_SUBGROUP`;
export const TOGGLE_SCHEDULE = `${PREFIX}/TOGGLE_SCHEDULE`;
export const SET_SCHEDULE = `${PREFIX}/SET_SCHEDULE`;
export const UPDATE_REFERRING_PHYSICIAN_LEAD = 'UPDATE_REFERRING_PHYSICIAN';
export const SET_REFERRAL_STATUS_LEAD = `${PREFIX}/SET_REFERRAL_STATUS`;
export const UPDATE_REFERRAL_STATUS_LEAD = `${PREFIX}/UPDATE_REFERRAL_STATUS`;
export const GET_INTERCEPTION_CHANGE_GROUP = `${PREFIX}/GET_INTERCEPTION_CHANGE_GROUP`;
export const GET_ALL_RETRIEVE_LEAD_INFO = `${PREFIX}/GET_ALL_RETRIEVE_LEAD_INFO`;
export const GET_IS_OVERDUE = `${PREFIX}/GET_IS_OVERDUE`;
export const MODAL_INTERSECTION = `${PREFIX}/MODAL_INTERSECTION`;
export const SET_INTERSECTION_COMMENT = `${PREFIX}/SET_INTERSECTION_COMMENT`;
export const SET_INTERSECTION_RATING = `${PREFIX}/SET_INTERSECTION_RATING`;
export const TOGGLE_IS_PHONE_COPIED = `${PREFIX}/TOGGLE_IS_PHONE_COPIED`;
export const TOGGLE_PHONE_COPY_TOOLTIP = `${PREFIX}/TOGGLE_PHONE_COPY_TOOLTIP`;
export const TOGGLE_LOCATION_TOOLTIP = `${PREFIX}/TOGGLE_LOCATION_TOOLTIP`;

// WS
export const LOCKED_LEAD = `${PREFIX}/LOCKED`;
export const UNLOCKED_LEAD = `${PREFIX}/UNLOCKED`;
export const UPDATED_LEADS = `${PREFIX}/UPDATED`;
export const UPDATED_CHANGE_GROUPS_LEAD = `${PREFIX}/UPDATED_CHANGE_GROUPS`;
export const UPDATED_DEMOGRAPHICS_LEAD = `${PREFIX}/UPDATED_DEMOGRAPHICS`;
export const UPDATED_NOTES_LEAD = `${PREFIX}/UPDATED_NOTE`;
