import { createRoutine, bindRoutineToReduxForm } from 'redux-saga-routines';
import {
  LIST_TIPS,
  RETRIEVE_TIP,
  CREATE_TIP,
  UPDATE_TIP,
  RANDOM_TIP,
} from './types';

export const listTips = createRoutine(LIST_TIPS);
export const retrieveTip = createRoutine(RETRIEVE_TIP);
export const createTip = createRoutine(CREATE_TIP);
export const updateTip = createRoutine(UPDATE_TIP);
export const randomTip = createRoutine(RANDOM_TIP);

export const bindedCreateTip = bindRoutineToReduxForm(createTip);
export const bindedUpdateTip = bindRoutineToReduxForm(updateTip);
