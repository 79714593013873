import { createSelector } from 'reselect';

import { RootState } from '@store/type';
import { ReportsState } from './reducer';

export const getReports = ({ reports }: RootState): ReportsState => reports;

export const getGraphReports = createSelector(getReports, ({ graph }) => graph);
export const getMeReportsSelector = createSelector(
  getReports,
  ({ reports }) => reports,
);
export const getReportsPeriod = createSelector(
  getReports,
  ({ periodReport }) => periodReport,
);
export const getMeSubReportsSelector = createSelector(
  getReports,
  ({ subReports }) => subReports,
);
export const isGraphLoading = createSelector(
  getReports,
  ({ loadingGraph }) => loadingGraph,
);
export const isMeReportsLoading = createSelector(
  getReports,
  ({ loadingReport }) => loadingReport,
);
export const isSubReportsLoading = createSelector(
  getReports,
  ({ loadingSubReport }) => loadingSubReport,
);
