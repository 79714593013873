import { call, put, takeLatest } from 'redux-saga/effects';

import SDK from '@utils/api';
import {
  MSG_GROUP_CREATED,
  MSG_GROUP_DELETED,
  MSG_GROUP_UPDATED,
  MSG_SUBGROUP_CREATED,
  MSG_SUBGROUP_DELETED,
  MSG_SUBGROUP_UPDATED,
} from '@utils/enums/messages';
import {
  messageError,
  messageSuccess,
} from '@views/base-components/toast-messages';

import {
  listGroups,
  listGroupsAndSubgroups,
  createGroup,
  retrieveGroup,
  updateGroup,
  deleteGroup,
  bulkGroups,
  createSubgroup,
  retrieveSubgroup,
  updateSubgroup,
  deleteSubgroup,
} from './actions';
import {
  CREATE_GROUP,
  UPDATE_GROUP,
  DELETE_GROUP,
  CREATE_SUBGROUP,
  UPDATE_SUBGROUP,
  DELETE_SUBGROUP,
} from './types';

export function* listGroupsSaga({ payload: { id } }) {
  try {
    yield put(listGroups.request());
    const parameters = { id };
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.groups, 'listGroups'], parameters);
    yield put(listGroups.success(body));
  } catch (error) {
    yield put(listGroups.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(listGroups.fulfill());
  }
}

export function* listGroupsAndSubgroupsSaga({ payload: { id } }) {
  try {
    yield put(listGroupsAndSubgroups.request());
    const parameters = { id };
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call(
      [api.groups, 'listGroupsAndSubgroups'],
      parameters,
    );
    yield put(listGroupsAndSubgroups.success(body));
  } catch (error) {
    yield put(listGroupsAndSubgroups.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(listGroupsAndSubgroups.fulfill());
  }
}

export const createGroupAndSubgroupSaga = (type) =>
  function* _createGroupAndSubgroup({ payload: { values } }) {
    try {
      yield put({ type: `${type}/REQUEST` });
      const message =
        type === CREATE_GROUP ? MSG_GROUP_CREATED : MSG_SUBGROUP_CREATED;
      const requestBody = {
        ...values,
        default: true,
        shouldCall: values.shouldCall || false,
      };
      const api = yield call([SDK, 'getApi']);
      const { body } = yield call(
        [api.groups, 'createGroup'],
        {},
        { requestBody },
      );
      yield put({ type: `${type}/SUCCESS`, payload: body });
      messageSuccess(message);
    } catch (error) {
      yield put({ type: `${type}/FAILURE`, payload: error });
      messageError(error?.response?.body?.message);
    } finally {
      yield put({ type: `${type}/FULFILL` });
    }
  };

export const updateGroupAndSubgroupSaga = (type) =>
  function* _updateGroupAndSubgroup({ payload: { values } }) {
    try {
      yield put({ type: `${type}/REQUEST` });
      const message =
        type === UPDATE_GROUP ? MSG_GROUP_UPDATED : MSG_SUBGROUP_UPDATED;
      const requestBody = {
        ...values,
        default: true,
        shouldCall: values.shouldCall || false,
      };
      const api = yield call([SDK, 'getApi']);
      const { body } = yield call(
        [api.groups, 'updateGroup'],
        { id: requestBody.id },
        { requestBody },
      );
      yield put({ type: `${type}/SUCCESS`, payload: body });
      messageSuccess(message);
    } catch (error) {
      yield put({ type: `${type}/FAILURE`, payload: error });
      messageError(error?.response?.body?.message);
    } finally {
      yield put({ type: `${type}/FULFILL` });
    }
  };

export const deleteGroupAndSubgroupSaga = (type) =>
  function* _deleteGroupAndSubgroup({ payload: { id, parentId } }) {
    try {
      yield put({ type: `${type}/REQUEST` });
      const message =
        type === DELETE_GROUP ? MSG_GROUP_DELETED : MSG_SUBGROUP_DELETED;
      const requestBody = { enabled: false, order: undefined };
      const api = yield call([SDK, 'getApi']);
      const { body } = yield call(
        [api.groups, 'deleteGroup'],
        { id },
        { requestBody },
      );
      yield put({ type: `${type}/SUCCESS`, payload: body });
      yield parentId
        ? put(retrieveGroup({ groupId: parentId }))
        : put(listGroups());
      messageSuccess(message);
    } catch (error) {
      yield put({ type: `${type}/FAILURE`, payload: error });
      messageError(error?.response?.body?.message);
    } finally {
      yield put({ type: `${type}/FULFILL` });
    }
  };

export function* retrieveGroupSaga({ payload: { groupId } }) {
  try {
    yield put(retrieveGroup.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.groups, 'getGroup'], { id: groupId });
    yield put(retrieveGroup.success(body));
  } catch (error) {
    yield put(retrieveGroup.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(retrieveGroup.fulfill());
  }
}

export function* retrieveSubgroupSaga({ payload: { subgroupId } }) {
  try {
    yield put(retrieveSubgroup.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.groups, 'getGroup'], { id: subgroupId });
    yield put(retrieveSubgroup.success(body));
  } catch (error) {
    yield put(retrieveSubgroup.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(retrieveSubgroup.fulfill());
  }
}

export function* bulkGroupsSaga({ payload }) {
  try {
    yield put(bulkGroups.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call(
      [api.groups, 'bulkGroups'],
      {},
      { requestBody: payload },
    );
    yield put(bulkGroups.success(body));
  } catch (error) {
    yield put(bulkGroups.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(bulkGroups.fulfill());
  }
}

export default function* groupsWatcher() {
  yield takeLatest(listGroups.TRIGGER, listGroupsSaga);
  yield takeLatest(listGroupsAndSubgroups.TRIGGER, listGroupsAndSubgroupsSaga);
  yield takeLatest(
    createGroup.TRIGGER,
    createGroupAndSubgroupSaga(CREATE_GROUP),
  );
  yield takeLatest(retrieveGroup.TRIGGER, retrieveGroupSaga);
  yield takeLatest(
    updateGroup.TRIGGER,
    updateGroupAndSubgroupSaga(UPDATE_GROUP),
  );
  yield takeLatest(
    deleteGroup.TRIGGER,
    deleteGroupAndSubgroupSaga(DELETE_GROUP),
  );
  yield takeLatest(
    createSubgroup.TRIGGER,
    createGroupAndSubgroupSaga(CREATE_SUBGROUP),
  );
  yield takeLatest(retrieveSubgroup.TRIGGER, retrieveSubgroupSaga);
  yield takeLatest(
    updateSubgroup.TRIGGER,
    updateGroupAndSubgroupSaga(UPDATE_SUBGROUP),
  );
  yield takeLatest(
    deleteSubgroup.TRIGGER,
    deleteGroupAndSubgroupSaga(DELETE_SUBGROUP),
  );
  yield takeLatest(bulkGroups.TRIGGER, bulkGroupsSaga);
}
