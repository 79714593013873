import produce, { Draft } from 'immer';

import { NextPriorityType } from 'types/next-priority';
import { listWaitList, setWaitList } from './actions';

export type WaitListState = {
  loading: boolean;
  error: string | undefined;
  items: NextPriorityType[];
  lastUpdate: Date | undefined;
};

const initialState: WaitListState = {
  loading: false,
  error: undefined,
  items: [],
  lastUpdate: undefined,
};

const reducer = produce((draft: Draft<WaitListState>, { type, payload }) => {
  switch (type) {
    case listWaitList.TRIGGER:
      draft.loading = true;
      draft.error = undefined;
      break;

    // SUCCESS
    case listWaitList.SUCCESS:
    case setWaitList.toString():
      draft.items = payload.leads;
      draft.lastUpdate = payload.lastUpdate;
      break;

    // FAILURE
    case listWaitList.FAILURE:
      draft.error = payload;
      break;

    // FULFILL
    case listWaitList.FULFILL:
      draft.loading = false;
      break;
    // no default
  }
}, initialState);

export default reducer;
