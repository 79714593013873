const MSG_CREATED = 'created successfully!';
const MSG_UPDATED = 'updated successfully!';
const MSG_DELETED = 'deleted successfully!';
export const MSG_AUTH_NEW_PASSWORD = 'Successful password change!';
export const MSG_LEAD_CREATED = `Lead ${MSG_CREATED}`;
export const MSG_LEAD_UPDATED = `Lead ${MSG_UPDATED}`;
export const MSG_LEAD_RESCHEDULE = MSG_LEAD_UPDATED;
export const MSG_LEAD_CHANGE_GROUP = `Lead group successfully changed`;
export const MSG_LEAD_VOICEMAIL = MSG_LEAD_UPDATED;
export const MSG_LEAD_RESTART_TRANSFER = MSG_LEAD_UPDATED;
export const MSG_NOTE_CREATED = `Note ${MSG_CREATED}`;
export const MSG_GROUP_CREATED = `Group ${MSG_CREATED}`;
export const MSG_GROUP_UPDATED = `Group ${MSG_UPDATED}`;
export const MSG_GROUP_DELETED = `Group ${MSG_DELETED}`;
export const MSG_SUBGROUP_CREATED = `Subgroup ${MSG_CREATED}`;
export const MSG_SUBGROUP_UPDATED = `Subgroup ${MSG_UPDATED}`;
export const MSG_SUBGROUP_DELETED = `Subgroup ${MSG_DELETED}`;
export const MSG_SCRIPT_CREATED = `Script ${MSG_CREATED}`;
export const MSG_SCRIPT_UPDATED = `Script ${MSG_UPDATED}`;
export const MSG_USER_CREATED = `User ${MSG_CREATED}`;
export const MSG_USER_UPDATED = `User ${MSG_UPDATED}`;
export const MSG_USER_DELETED = `User ${MSG_DELETED}`;
export const MSG_CLINIC_CREATED = `Clinic ${MSG_CREATED}`;
export const MSG_CLINIC_UPDATED = `Clinic ${MSG_UPDATED}`;
export const MSG_LOCATION_CREATED = `Location ${MSG_CREATED}`;
export const MSG_LOCATION_UPDATED = `Location ${MSG_UPDATED}`;
export const MSG_INSURANCE_STATES_CREATED = `States ${MSG_CREATED}`;
export const MSG_INSURANCE_STATES_UPDATED = `States ${MSG_UPDATED}`;
export const MSG_INSURANCE_CREATED = `Insurance ${MSG_CREATED}`;
export const MSG_INSURANCE_UPDATED = `Insurance ${MSG_UPDATED}`;
export const MSG_SOURCE_CREATED = `Source ${MSG_CREATED}`;
export const MSG_SOURCE_UPDATED = `Source ${MSG_UPDATED}`;
export const MSG_FORM_PHYSICIAN_REFERRAL_CREATED =
  'Thank you! We will be in contact with your patient shortly and update you once they have been booked!';
export const MSG_REFERRING_PHYSICIAN_CREATED = `Referring Physician ${MSG_CREATED}`;
export const MSG_REFERRING_PHYSICIAN_UPDATED = `Referring Physician ${MSG_UPDATED}`;
export const MSG_ROLE_CREATED = `Role ${MSG_CREATED}`;
export const MSG_ROLE_UPDATED = `Role ${MSG_UPDATED}`;
export const MSG_ROLE_PERMISSION_UPDATED = `Permission ${MSG_UPDATED}`;
export const MSG_ROLE_MODULE_SAVE = `Module save successfully!`;
export const MSG_TIP_CREATED = `Tip ${MSG_CREATED}`;
export const MSG_TIP_UPDATED = `Tip ${MSG_UPDATED}`;
export const MSG_THING_CREATED = `Notice ${MSG_CREATED}`;
export const MSG_THING_UPDATED = `Notice ${MSG_UPDATED}`;
export const MSG_CAMPAIGN_CREATED = `Notice ${MSG_CREATED}`;
export const MSG_CAMPAIGN_UPDATED = `Notice ${MSG_UPDATED}`;
export const MSG_NEXTECH_SYNC_SUCCESS =
  'This lead was successfully synchronized with Nextech.';
export const MSG_NEXTECH_SYNC_FAILED =
  'This lead couldn’t be synchronized with Nextech. Please contact your supervisor.';
export const MSG_NEXTECH_SYNC_FINISHED = 'Synchronized finished.';
export const MSG_NEXT_PRIORITY_VERIFICATION = `No leads to verify <br />at this time.`;
export const MSG_NEXT_PRIORITY_LEAD = `No Leads to call <br />at this time.`;
export const MSG_COPIED_NUMBER =
  'Please make sure your outbound caller ID is set to the correct ring group.';
