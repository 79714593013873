import produce, { Draft } from 'immer';

import { authLoginSSO, authLogout, authRefreshToken } from './actions';

export type AuthState = {
  loading: boolean;
  isLoggedIn: boolean;
  error: string | undefined;
  token: string | undefined;
  refreshToken: string | undefined;
};

export const initialState: AuthState = {
  loading: false,
  isLoggedIn: false,
  error: undefined,
  token: undefined,
  refreshToken: undefined,
};

const reducer = produce((draft: Draft<AuthState>, { type, payload }) => {
  switch (type) {
    case authLoginSSO.TRIGGER:
    case authLogout.TRIGGER:
      draft.loading = true;
      draft.error = undefined;
      break;

    // SUCCESS
    case authLoginSSO.SUCCESS:
    case authRefreshToken.SUCCESS:
      draft.token = payload.accessToken;
      draft.refreshToken = payload.refreshToken;
      draft.isLoggedIn = true;
      break;
    case authLogout.SUCCESS:
      draft.token = undefined;
      draft.refreshToken = undefined;
      draft.isLoggedIn = false;
      break;

    // FAILURE
    case authLoginSSO.FAILURE:
    case authLogout.FAILURE:
    case authRefreshToken.FAILURE:
      draft.error = payload;
      draft.token = undefined;
      draft.refreshToken = undefined;
      break;

    // FULFILL
    case authLoginSSO.FULFILL:
    case authLogout.FULFILL:
    case authRefreshToken.FULFILL:
      draft.loading = false;
      break;
    // no default
  }
}, initialState);

export default reducer;
