import { PureAbility } from '@casl/ability';

export const caslCreateAbilityForUser = (permissions: any) =>
  new PureAbility(permissions);

export const caslPermissionCheck = (requiredPermissions: any, ability: any) =>
  requiredPermissions.every((permission: any) => ability.can(...permission));

export const caslIsAllowed = (ability: any, action: string, module: string) =>
  caslPermissionCheck([[action, module]], ability);
