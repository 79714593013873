import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import SDK from '@utils/api';
import { messageError } from '@views/base-components/toast-messages';
import { getFilters } from '@modules/nav/selectors';

import {
  getDashboardLayout,
  getCallWidget,
  listBirthdaysDashboard,
  listDashboardByRole,
} from './actions';

export function* getDashboardSaga() {
  try {
    yield put(getDashboardLayout.request());
    const globalFilters = yield select(getFilters);
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.dashboard, 'getDashboard'], {
      dr: globalFilters?.dr,
    });
    yield put(getDashboardLayout.success(body));
  } catch (error) {
    yield put(getDashboardLayout.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(getDashboardLayout.fulfill());
  }
}

export function* getCurrentAndAfterBirthdaySaga() {
  try {
    yield put(listBirthdaysDashboard.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.dashboard, 'getBirthdays'], {});
    yield put(listBirthdaysDashboard.success(body));
  } catch (error) {
    yield put(listBirthdaysDashboard.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(listBirthdaysDashboard.fulfill());
  }
}

export function* getCallWidgetSaga({
  payload: { id, resource, operationId, filters },
}) {
  try {
    yield put(getCallWidget.request({ id }));
    const globalFilters = yield select(getFilters);
    const parameters =
      {
        ...filters,
        dr: globalFilters?.dr,
      } || {};
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api[resource], operationId], parameters);
    yield put(getCallWidget.success({ id, body }));
  } catch (error) {
    yield put(getCallWidget.failure({ id, error }));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(getCallWidget.fulfill({ id }));
  }
}

export function* listDashboardByRoleSaga() {
  try {
    yield put(listDashboardByRole.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.dashboard, 'getAllDashboardsByRole'], {});
    yield put(listDashboardByRole.success(body));
  } catch (error) {
    yield put(listDashboardByRole.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(listDashboardByRole.fulfill());
  }
}

export default function* dashboardWatcher() {
  yield takeLatest(getDashboardLayout.TRIGGER, getDashboardSaga);
  yield takeLatest(
    listBirthdaysDashboard.TRIGGER,
    getCurrentAndAfterBirthdaySaga,
  );
  yield takeEvery(getCallWidget.TRIGGER, getCallWidgetSaga);
  yield takeLatest(listDashboardByRole.TRIGGER, listDashboardByRoleSaga);
}
