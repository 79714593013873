/* eslint-disable import/no-duplicates */
// @ts-nocheck
import { useEffect, useState, FC, PropsWithChildren } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getAuthRefreshToken } from '@modules/auth/selectors';
import { SDK } from '@utils/api';
import WS from '@utils/api/ws';
import { store } from '@store/index';
import { connectWS } from '@modules/websocket/actions';

const SdkLoader: FC<PropsWithChildren<{}>> = ({ children }) => {
  const dispatch = useDispatch();
  const token = useSelector(getAuthRefreshToken);
  const [isReady, setReady] = useState(false);

  useEffect(() => {
    (async () => {
      if (token) {
        WS.setToken(token);
        await WS.init();
        dispatch(connectWS());
      }
      await SDK.init(store);
      SDK.setToken(token);
      setReady(true);
    })();
  }, [token, dispatch]);

  if (!isReady) return;
  return children;
};

export default SdkLoader;
