/* eslint-disable camelcase */
/* eslint-disable unicorn/require-post-message-target-origin */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable unicorn/prefer-module */
/* eslint-disable unicorn/prefer-top-level-await */
/* eslint-disable import/no-import-module-exports */
import '@assets/styles/style.scss';
import { createRoot } from 'react-dom/client';
import Loadable from 'react-loadable';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';

import { updateServiceWorker } from '@modules/app/actions';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import App from './app';
import { store } from './store';

const { REACT_APP_LOGROCKET, npm_package_version, NODE_ENV } = process.env;

if (NODE_ENV === 'production') {
  LogRocket.init(`${REACT_APP_LOGROCKET}`, {
    release: npm_package_version,
  });
  setupLogRocketReact(LogRocket);
}

serviceWorkerRegistration.register({
  skipWaiting: true,
  onUpdate: async (registration) => {
    if (registration?.waiting) {
      await registration.unregister();
      registration.waiting.postMessage({ type: 'SKIP_WAITING' });
      store.dispatch(updateServiceWorker());
    }
  },
});

Loadable.preloadReady().then(() =>
  createRoot(document.querySelector('#root')).render(<App />),
);
