import { bindRoutineToReduxForm, createRoutine } from 'redux-saga-routines';
import { createAction } from 'redux-actions';

import {
  CREATE_REFERRING_PHYSICIAN,
  LIST_REFERRING_PHYSICIANS,
  MERGE_REFERRING_PHYSICIAN,
  MODAL_MERGE_REFERRING_PHYSICIAN,
  RETRIEVE_REFERRING_PHYSICIAN,
  UPDATE_REFERRING_PHYSICIAN,
  DROPDOWN_REFERRING_PHYSICIANS,
  DROPDOWN_PHYSICIAN_SPECIALTIES,
  EXTERNAL_DROPDOWN_REFERRING_PHYSICIANS,
} from './types';

export const listReferringPhysicians = createRoutine(LIST_REFERRING_PHYSICIANS);
export const retrieveReferringPhysician = createRoutine(
  RETRIEVE_REFERRING_PHYSICIAN,
);
export const createReferringPhysician = createRoutine(
  CREATE_REFERRING_PHYSICIAN,
);
export const updateReferringPhysician = createRoutine(
  UPDATE_REFERRING_PHYSICIAN,
);
export const mergeReferringPhysician = createRoutine(MERGE_REFERRING_PHYSICIAN);
export const dropdownReferringPhysicians = createRoutine(
  DROPDOWN_REFERRING_PHYSICIANS,
);

export const externalDropdownReferringPhysicians = createRoutine(
  EXTERNAL_DROPDOWN_REFERRING_PHYSICIANS,
);

export const dropdownPhysicianSpecialties = createRoutine(
  DROPDOWN_PHYSICIAN_SPECIALTIES,
);

export const toggleReferringPhysicianMergeModal = createAction(
  MODAL_MERGE_REFERRING_PHYSICIAN,
);

export const bindedCreateReferringPhysician = bindRoutineToReduxForm(
  createReferringPhysician,
);
export const bindedUpdateReferringPhysician = bindRoutineToReduxForm(
  updateReferringPhysician,
);
export const bindedMergeReferringPhysician = bindRoutineToReduxForm(
  mergeReferringPhysician,
);
