import { call, put, takeLatest } from 'redux-saga/effects';

import SDK from '@utils/api';
import { MSG_SCRIPT_CREATED, MSG_SCRIPT_UPDATED } from '@utils/enums/messages';
import { SCRIPT_TYPE_GENERAL } from '@utils/enums/type-scripts';
import {
  messageError,
  messageSuccess,
} from '@views/base-components/toast-messages';

import {
  listScripts,
  retrieveScript,
  createScript,
  updateScript,
  getGroupScript,
  generalScript,
  previewScript,
  listWidgets,
  getLeadScriptById,
} from './actions';

export function* listScriptsSaga({ payload: { id } }) {
  try {
    yield put(listScripts.request());
    const parameters = { id };
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.groups, 'listScripts'], parameters);
    yield put(listScripts.success(body));
  } catch (error) {
    yield put(listScripts.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(listScripts.fulfill());
  }
}

export function* retrieveScriptSaga({ payload: { scriptId } }) {
  try {
    yield put(retrieveScript.request());
    const parameters = { id: scriptId };
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.scripts, 'getScript'], parameters);
    yield put(retrieveScript.success(body));
  } catch (error) {
    yield put(retrieveScript.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(retrieveScript.fulfill());
  }
}

export function* createScriptSaga({ payload: { values: requestBody } }) {
  try {
    yield put(createScript.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call(
      [api.scripts, 'createScript'],
      {},
      { requestBody },
    );
    yield put(createScript.success(body));
    messageSuccess(MSG_SCRIPT_CREATED);
  } catch (error) {
    yield put(createScript.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(createScript.fulfill());
  }
}

export function* updateScriptSaga({ payload: { values: requestBody } }) {
  try {
    yield put(updateScript.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call(
      [api.scripts, 'updateScript'],
      { id: requestBody.id },
      { requestBody },
    );
    yield put(updateScript.success(body));
    messageSuccess(MSG_SCRIPT_UPDATED);
  } catch (error) {
    yield put(updateScript.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(updateScript.fulfill());
  }
}

export function* getGroupScriptSaga({ payload: { groupId } }) {
  try {
    yield put(getGroupScript.request());
    const parameters = { id: groupId };
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.groups, 'getGroupBasic'], parameters);
    yield put(getGroupScript.success(body));
  } catch (error) {
    yield put(getGroupScript.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(getGroupScript.fulfill());
  }
}

export function* generalScriptSaga({ payload: { leadId } }) {
  try {
    yield put(generalScript.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.scripts, 'getTypeScript'], {
      leadId,
      type: SCRIPT_TYPE_GENERAL,
    });
    yield put(generalScript.success(body));
  } catch (error) {
    yield put(generalScript.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(generalScript.fulfill());
  }
}

export function* previewScriptSaga({ payload: { scriptId, leadId } }) {
  try {
    yield put(previewScript.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.scripts, 'getPreview'], {
      id: scriptId,
      leadId,
    });
    yield put(previewScript.success(body));
  } catch (error) {
    yield put(previewScript.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(previewScript.fulfill());
  }
}

export function* listWidgetsSaga() {
  try {
    yield put(listWidgets.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.widgets, 'getWidgets']);
    yield put(listWidgets.success(body));
  } catch (error) {
    yield put(listWidgets.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(listWidgets.fulfill());
  }
}

export function* getLeadScriptByIdSaga({ payload: { scriptId, leadId } }) {
  try {
    yield put(getLeadScriptById.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.scripts, 'getPreview'], {
      id: scriptId,
      leadId,
    });
    yield put(getLeadScriptById.success(body));
  } catch (error) {
    yield put(getLeadScriptById.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(getLeadScriptById.fulfill());
  }
}

export default function* scriptsWatcher() {
  yield takeLatest(listScripts.TRIGGER, listScriptsSaga);
  yield takeLatest(retrieveScript.TRIGGER, retrieveScriptSaga);
  yield takeLatest(createScript.TRIGGER, createScriptSaga);
  yield takeLatest(updateScript.TRIGGER, updateScriptSaga);
  yield takeLatest(getGroupScript.TRIGGER, getGroupScriptSaga);
  yield takeLatest(generalScript.TRIGGER, generalScriptSaga);
  yield takeLatest(previewScript.TRIGGER, previewScriptSaga);
  yield takeLatest(listWidgets.TRIGGER, listWidgetsSaga);
  yield takeLatest(getLeadScriptById.TRIGGER, getLeadScriptByIdSaga);
}
