import { createRoutine } from 'redux-saga-routines';
import { createAction } from 'redux-actions';
import {
  LIST_NEXTECH,
  LIST_SYNCS_NEXTECH,
  LIST_SYNC_NEXTECH,
  LIST_SYNC_INFO,
  MODAL_SET_CLOSED,
  MODAL_OPEN_SET_NOT_FOUND,
  MODAL_SELECT_ITEM,
  MODAL_INPUT_NEXTECH_ID,
  MODAL_TRIGGER_NOT_FOUND,
  MODAL_CLEAN,
  SYNC_NEXTECH_LAST_UPDATED,
  SYNC_MANUAL_ALL,
  SYNC_MANUAL_ITEMS,
  SYNC_MANUAL_ITEMS_FINISH,
} from './types';

export const listNextech = createRoutine(LIST_NEXTECH);
export const listSyncInfo = createRoutine(LIST_SYNC_INFO);
export const listSyncsNextech = createRoutine(LIST_SYNCS_NEXTECH);
export const listSyncNextech = createRoutine(LIST_SYNC_NEXTECH);

export const modalOpenSetNotFound = createAction(MODAL_OPEN_SET_NOT_FOUND);
export const modalSetClosed = createAction(MODAL_SET_CLOSED);
export const modalSelectItem = createAction(MODAL_SELECT_ITEM);
export const modalInputNextechId = createAction(MODAL_INPUT_NEXTECH_ID);

export const modalTriggerNotFound = createAction(MODAL_TRIGGER_NOT_FOUND);
export const modalClean = createAction(MODAL_CLEAN);

export const syncNextechLastUpdated = createAction(SYNC_NEXTECH_LAST_UPDATED);
export const syncManualAll = createAction(SYNC_MANUAL_ALL);
export const syncManualItems = createAction(SYNC_MANUAL_ITEMS);
export const syncManualItemsFinish = createAction(SYNC_MANUAL_ITEMS_FINISH);
