import produce, { Draft } from 'immer';

import { LocationType } from 'types/location';
import { MetaType } from 'types/meta';
import {
  listLocations,
  retrieveLocation,
  createLocation,
  updateLocation,
  dropdownLocations,
} from './actions';
import { SET_FILTER_LOCATION } from './types';

export type LocationsState = {
  loading: boolean;
  error: string | undefined;
  item: LocationType | undefined;
  filter: string | undefined;
  items: LocationType[];
  meta: MetaType | undefined;
  dropdown: LocationType[];
};

const initialState: LocationsState = {
  loading: false,
  error: undefined,
  item: undefined,
  filter: undefined,
  items: [],
  meta: undefined,
  dropdown: [],
};

const reducer = produce((draft: Draft<LocationsState>, { type, payload }) => {
  switch (type) {
    case listLocations.TRIGGER:
    case retrieveLocation.TRIGGER:
    case createLocation.TRIGGER:
    case updateLocation.TRIGGER:
    case dropdownLocations.TRIGGER:
      draft.loading = true;
      draft.error = undefined;
      break;

    // SUCCESS
    case listLocations.SUCCESS:
      draft.items = payload.items;
      draft.meta = payload.meta;
      break;
    case retrieveLocation.SUCCESS:
    case createLocation.SUCCESS:
    case updateLocation.SUCCESS:
      draft.item = payload;
      break;
    case dropdownLocations.SUCCESS:
      draft.dropdown = payload;
      break;

    // FAILURE
    case listLocations.FAILURE:
    case retrieveLocation.FAILURE:
    case createLocation.FAILURE:
    case updateLocation.FAILURE:
    case dropdownLocations.FAILURE:
      draft.error = payload;
      break;

    // FULFILL
    case listLocations.FULFILL:
    case retrieveLocation.FULFILL:
    case createLocation.FULFILL:
    case updateLocation.FULFILL:
    case dropdownLocations.FULFILL:
      draft.loading = false;
      break;
    case SET_FILTER_LOCATION:
      draft.filter = payload;
      break;
    // no default
  }
}, initialState);

export default reducer;
