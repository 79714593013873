import { createAction } from 'redux-actions';
import { createRoutine } from 'redux-saga-routines';
import {
  GET_GRAPH_REPORTS,
  GET_ME_REPORTS,
  GET_SUB_REPORTS,
  SET_PERIOD_REPORT,
} from './types';

export const getPerformanceGraph = createRoutine(GET_GRAPH_REPORTS);
export const getMeReports = createRoutine(GET_ME_REPORTS);
export const getSubReports = createRoutine(GET_SUB_REPORTS);
export const setPeriodReport = createAction(SET_PERIOD_REPORT);
