import { createSelector } from 'reselect';

import { getLeads } from '@modules/leads/selectors';
import { getMe } from '@modules/me/selectors';

import { RootState } from '@store/type';
import { NextPriorityState } from './reducer';

export const getNextPriority = ({
  nextPriority,
}: RootState): NextPriorityState => nextPriority;

export const isLoadingNextPriority = createSelector(
  getNextPriority,
  ({ loading }) => loading,
);
export const getNextPriorityError = createSelector(
  getNextPriority,
  ({ error }) => error,
);
export const showModalEndWaitListNextPriority = createSelector(
  getNextPriority,
  ({ showModalEndWaitList }) => showModalEndWaitList,
);
export const getNextPriorityLeadId = createSelector(
  getNextPriority,
  ({ leadId }) => leadId,
);
export const getWorkNextPriority = createSelector(
  getNextPriority,
  ({ work }) => work,
);
export const showButtonNextPriority = createSelector(
  [getNextPriority, getMe, getLeads],
  ({ work }, { item: me }, { changeGroupAndSubgroup }) => {
    // TODO: Next Priority Verification
    if (me?.allowSkipNextPriority) {
      return true;
    }
    // TODO: Next Priority Lead
    if (!work) {
      return true;
    }
    return !!changeGroupAndSubgroup.successful;
  },
);
