import { call, put, takeLatest } from 'redux-saga/effects';

import SDK from '@utils/api';
import { messageError } from '@views/base-components/toast-messages';

import {
  getPerformanceGraph,
  getMeReports,
  getSubReports,
  setPeriodReport,
} from './actions';

export function* getPerformanceGraphSaga() {
  try {
    yield put(getPerformanceGraph.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.reports, 'getStatisticsGraph']);
    yield put(getPerformanceGraph.success(body));
  } catch (error) {
    yield put(getPerformanceGraph.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(getPerformanceGraph.fulfill());
  }
}

export function* getMeReportsSaga({ payload: { range = ',' } }) {
  try {
    yield put(getMeReports.request());
    yield put(setPeriodReport(range));
    const splittedRange = range.split(',');
    const parameters = {
      from: splittedRange && splittedRange[0],
      to: splittedRange && splittedRange[1],
    };
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.reports, 'getStatistics'], parameters);
    yield put(getMeReports.success(body));
  } catch (error) {
    yield put(getMeReports.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(getMeReports.fulfill());
  }
}

export function* getMeSubReportsSaga({ payload: { range = ',', tag } }) {
  try {
    yield put(getSubReports.request());
    yield put(setPeriodReport(range));
    const splittedRange = range.split(',');
    const parameters = {
      from: splittedRange && splittedRange[0],
      to: splittedRange && splittedRange[1],
      tag,
    };
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.reports, 'getStatistics'], parameters);
    yield put(getSubReports.success(body));
  } catch (error) {
    yield put(getSubReports.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(getSubReports.fulfill());
  }
}

export default function* reportsWatcher() {
  yield takeLatest(getPerformanceGraph.TRIGGER, getPerformanceGraphSaga);
  yield takeLatest(getMeReports.TRIGGER, getMeReportsSaga);
  yield takeLatest(getSubReports.TRIGGER, getMeSubReportsSaga);
}
