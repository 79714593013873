import { call, put, takeLatest } from 'redux-saga/effects';

import SDK from '@utils/api';
import {
  MSG_INSURANCE_STATES_CREATED,
  MSG_INSURANCE_STATES_UPDATED,
  MSG_INSURANCE_CREATED,
  MSG_INSURANCE_UPDATED,
} from '@utils/enums/messages';
import {
  messageError,
  messageSuccess,
} from '@views/base-components/toast-messages';

import {
  listInsurancesStates,
  retrieveInsuranceState,
  createInsuranceState,
  updateInsuranceState,
  dropdownInsurancesStates,
  listInsurances,
  retrieveInsurance,
  createInsurance,
  updateInsurance,
  dropdownInsurances,
  listInsurancesTypes,
  externalDropdownInsurancesStates,
} from './actions';

export function* listInsurancesStatesSaga({ payload }) {
  try {
    yield put(listInsurancesStates.request());
    const parameters = {
      search: payload.search,
      typeInsurance: payload.typeInsurance,
      page: payload.page,
      perPage: payload.perPage,
    };
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call(
      [api.insurances, 'listInsurancesStates'],
      parameters,
    );
    yield put(listInsurancesStates.success(body));
  } catch (error) {
    yield put(listInsurancesStates.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(listInsurancesStates.fulfill());
  }
}

export function* retrieveInsuranceStateSaga({ payload: { stateId } }) {
  try {
    yield put(retrieveInsuranceState.request());
    const parameters = { id: stateId };
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call(
      [api.insurances, 'retrieveInsuranceState'],
      parameters,
    );
    yield put(retrieveInsuranceState.success(body));
  } catch (error) {
    yield put(retrieveInsuranceState.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(retrieveInsuranceState.fulfill());
  }
}

export function* createInsuranceStateSaga({
  payload: { values: requestBody },
}) {
  try {
    yield put(createInsuranceState.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call(
      [api.insurances, 'createInsuranceState'],
      {},
      { requestBody },
    );
    yield put(createInsuranceState.success(body));
    messageSuccess(MSG_INSURANCE_STATES_CREATED);
  } catch (error) {
    yield put(createInsuranceState.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(createInsuranceState.fulfill());
  }
}

export function* updateInsuranceStateSaga({
  payload: { values: requestBody },
}) {
  try {
    yield put(updateInsuranceState.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call(
      [api.insurances, 'updateInsuranceState'],
      { id: requestBody.id },
      { requestBody },
    );
    yield put(updateInsuranceState.success(body));
    messageSuccess(MSG_INSURANCE_STATES_UPDATED);
  } catch (error) {
    yield put(updateInsuranceState.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(updateInsuranceState.fulfill());
  }
}

export function* dropdownInsurancesStatesSaga() {
  try {
    yield put(dropdownInsurancesStates.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call(
      [api.insurances, 'listDropdownInsurancesStates'],
      {},
    );
    yield put(dropdownInsurancesStates.success(body));
  } catch (error) {
    yield put(dropdownInsurancesStates.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(dropdownInsurancesStates.fulfill());
  }
}

export function* externalDropdownInsurancesStatesSaga() {
  try {
    yield put(externalDropdownInsurancesStates.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call(
      [api.external, 'externalListDropdownInsurancesStates'],
      {},
    );
    yield put(externalDropdownInsurancesStates.success(body));
  } catch (error) {
    yield put(externalDropdownInsurancesStates.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(externalDropdownInsurancesStates.fulfill());
  }
}

export function* listInsurancesSaga({ payload }) {
  try {
    yield put(listInsurances.request());
    const parameters = {
      search: payload.search,
      stateId: payload.stateId,
      page: payload.page,
      perPage: payload.perPage,
    };
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.insurances, 'getInsurances'], parameters);
    yield put(listInsurances.success(body));
  } catch (error) {
    yield put(listInsurances.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(listInsurances.fulfill());
  }
}

export function* retrieveInsuranceSaga({ payload: { insuranceId } }) {
  try {
    yield put(retrieveInsurance.request());
    const parameters = { id: insuranceId };
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call(
      [api.insurances, 'retrieveInsurance'],
      parameters,
    );
    yield put(retrieveInsurance.success(body));
  } catch (error) {
    yield put(retrieveInsurance.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(retrieveInsurance.fulfill());
  }
}

export function* createInsuranceSaga({ payload: { values } }) {
  try {
    yield put(createInsurance.request());
    const api = yield call([SDK, 'getApi']);
    const requestBody = {
      ...values,
      order: values.order && Number.parseInt(values.order, 10),
      medworkId: values.medworkId && Number.parseInt(values.medworkId, 10),
    };
    const { body } = yield call(
      [api.insurances, 'createInsurance'],
      {},
      { requestBody },
    );
    yield put(createInsurance.success(body));
    messageSuccess(MSG_INSURANCE_CREATED);
  } catch (error) {
    yield put(createInsurance.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(createInsurance.fulfill());
  }
}

export function* updateInsuranceSaga({ payload: { values } }) {
  try {
    yield put(updateInsurance.request());
    const api = yield call([SDK, 'getApi']);
    const requestBody = {
      ...values,
      order: values.order && Number.parseInt(values.order, 10),
      medworkId: values.medworkId && Number.parseInt(values.medworkId, 10),
    };
    const { body } = yield call(
      [api.insurances, 'updateInsurance'],
      { id: requestBody.id },
      { requestBody },
    );
    yield put(updateInsurance.success(body));
    messageSuccess(MSG_INSURANCE_UPDATED);
  } catch (error) {
    yield put(updateInsurance.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(updateInsurance.fulfill());
  }
}

export function* dropdownInsurancesSaga() {
  try {
    yield put(dropdownInsurances.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.insurances, 'listDropdownInsurances'], {});
    yield put(dropdownInsurances.success(body));
  } catch (error) {
    yield put(dropdownInsurances.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(dropdownInsurances.fulfill());
  }
}

export function* listInsurancesTypesSaga() {
  try {
    yield put(listInsurancesTypes.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.insurances, 'listInsurancesTypes'], {});
    yield put(listInsurancesTypes.success(body));
  } catch (error) {
    yield put(listInsurancesTypes.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(listInsurancesTypes.fulfill());
  }
}

export default function* insurancesWatcher() {
  yield takeLatest(listInsurancesStates.TRIGGER, listInsurancesStatesSaga);
  yield takeLatest(retrieveInsuranceState.TRIGGER, retrieveInsuranceStateSaga);
  yield takeLatest(createInsuranceState.TRIGGER, createInsuranceStateSaga);
  yield takeLatest(updateInsuranceState.TRIGGER, updateInsuranceStateSaga);
  yield takeLatest(
    dropdownInsurancesStates.TRIGGER,
    dropdownInsurancesStatesSaga,
  );
  yield takeLatest(listInsurances.TRIGGER, listInsurancesSaga);
  yield takeLatest(retrieveInsurance.TRIGGER, retrieveInsuranceSaga);
  yield takeLatest(createInsurance.TRIGGER, createInsuranceSaga);
  yield takeLatest(updateInsurance.TRIGGER, updateInsuranceSaga);
  yield takeLatest(dropdownInsurances.TRIGGER, dropdownInsurancesSaga);
  yield takeLatest(listInsurancesTypes.TRIGGER, listInsurancesTypesSaga);
  yield takeLatest(
    externalDropdownInsurancesStates.TRIGGER,
    externalDropdownInsurancesStatesSaga,
  );
}
