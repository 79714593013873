import { createRoutine } from 'redux-saga-routines';
import { createAction } from 'redux-actions';
import {
  GET_DASHBOARD,
  GET_WIDGET_INFO,
  CLEAR_WIDGETS,
  LIST_BIRTHDAYS_DASHBOARD,
  LIST_DASHBOARD_BY_ROLE,
} from './types';

export const getDashboardLayout = createRoutine(GET_DASHBOARD);
export const getCallWidget = createRoutine(GET_WIDGET_INFO);
export const listBirthdaysDashboard = createRoutine(LIST_BIRTHDAYS_DASHBOARD);
export const listDashboardByRole = createRoutine(LIST_DASHBOARD_BY_ROLE);

export const clearWidgets = createAction(CLEAR_WIDGETS);
