import { call, put, takeLatest } from 'redux-saga/effects';

import SDK from '@utils/api';
import { messageError } from '@views/base-components/toast-messages';

import { allList } from './actions';

export function* allListSaga() {
  try {
    yield put(allList.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call(
      [api['custom-list-filters'], 'getCustomListFilters'],
      {},
    );
    yield put(allList.success(body));
  } catch (error) {
    yield put(allList.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(allList.fulfill());
  }
}

export default function* listWatcher() {
  yield takeLatest(allList.TRIGGER, allListSaga);
}
