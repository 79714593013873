import { call, put, debounce } from 'redux-saga/effects';

import SDK from '@utils/api';
import { messageError } from '@views/base-components/toast-messages';

import { listLeadsSatisfaction } from './actions';

export function* listLeadSatisfactionSaga({ payload: parameters }) {
  try {
    yield put(listLeadsSatisfaction.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call(
      [api['lead-satisfaction'], 'getLeadSatisfaction'],
      parameters,
    );
    yield put(listLeadsSatisfaction.success(body));
  } catch (error) {
    yield put(listLeadsSatisfaction.failure(error));
    messageError(error?.response?.body?.message);
  } finally {
    yield put(listLeadsSatisfaction.fulfill());
  }
}

export default function* leadsSatisfactionWatcher() {
  yield debounce(500, listLeadsSatisfaction.TRIGGER, listLeadSatisfactionSaga);
}
