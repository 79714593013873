const WAIT_LIST_LEAD = new Set([
  'firstName',
  'lastName',
  'birthday',
  'email',
  'cellphone',
]);

const checkFieldsDuplicate = (values: Record<string, any>) =>
  Object.keys(values).reduce(
    (accumulator: { [key: string]: any }, field: string) => {
      if (WAIT_LIST_LEAD.has(values[field].demographics)) {
        // eslint-disable-next-line no-param-reassign
        accumulator[values[field].demographics] = values[field].value;
      }

      return accumulator;
    },
    {},
  );

export default checkFieldsDuplicate;
