import { FC } from 'react';
import { Col, Row } from 'reactstrap';

type ToastIconProperties = { icon: string; message: string };

const ToastIcon: FC<ToastIconProperties> = ({ icon, message }) => (
  <Row className="align-items-center">
    <Col md={3}>
      <i className={`${icon} ui__toast-icon `} />
    </Col>
    <Col md={9}>
      <h6>
        <b>{message}</b>
      </h6>
    </Col>
  </Row>
);
export default ToastIcon;
