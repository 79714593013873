import { Action, combineReducers } from 'redux';
import { connectRouter, RouterState } from 'connected-react-router';
import { FormStateMap, reducer as form } from 'redux-form';
import { persistReducer } from 'redux-persist';
import { PersistPartial } from 'redux-persist/lib/persistReducer';

import auth, {
  AuthState,
  initialState as initialAuthState,
} from '@modules/auth/reducer';
import me, {
  MeState,
  initialState as initialMeState,
} from '@modules/me/reducer';
import clinics, { ClinicsState } from '@modules/clinics/reducer';
import groups, { GroupsState } from '@modules/groups/reducer';
import insurances, { InsurancesState } from '@modules/insurances/reducer';
import leads, { LeadsState } from '@modules/leads/reducer';
import locations, { LocationsState } from '@modules/locations/reducer';
import nav, { NavState } from '@modules/nav/reducer';
import reports, { ReportsState } from '@modules/reports/reducer';
import sources, { SourceState } from '@modules/sources/reducer';
import scripts, { ScriptsState } from '@modules/scripts/reducer';
import timezones, { TimezonesState } from '@modules/timezones/reducer';
import users, { UsersState } from '@modules/users/reducer';
import external, { ExternalState } from '@modules/external/reducer';
import leadsSatisfaction, {
  LeadsSatisfactionState,
} from '@modules/leads-satisfaction/reducer';
import physicianReferral, {
  PhysicianReferralsState,
} from '@modules/physician-referral/reducer';
import referringPhysicians, {
  ReferringPhysiciansState,
} from '@modules/referring-physician/reducer';
import roles, { RolesState } from '@modules/roles/reducer';
import tips, { TipsState } from '@modules/tips/reducer';
import things, { ThingsState } from '@modules/things/reducer';
import dashboard, { DashboardState } from '@modules/dashboard/reducer';
import nextech, { NextechState } from '@modules/nextech/reducer';
import campaigns, { CampaignState } from '@modules/campaigns/reducer';
import list, { ListState } from '@modules/list/reducer';
import app, { AppState } from '@modules/app/reducer';
import idle, { IdleState } from '@modules/idle/reducer';
import nextPriority, {
  NextPriorityState,
} from '@modules/next-priority/reducer';
import waitList, { WaitListState } from '@modules/wait-list/reducer';

import { authPersistConfig, rootPersistConfig } from './persistor';
import routerHistory from './history';

export type ReducerType = {
  app: AppState;
  router: RouterState;
  form: FormStateMap;
  auth: AuthState & PersistPartial;
  clinics: ClinicsState;
  groups: GroupsState;
  insurances: InsurancesState;
  leads: LeadsState;
  locations: LocationsState;
  me: MeState;
  nav: NavState;
  reports: ReportsState;
  scripts: ScriptsState;
  sources: SourceState;
  timezones: TimezonesState;
  users: UsersState;
  external: ExternalState;
  leadsSatisfaction: LeadsSatisfactionState;
  physicianReferral: PhysicianReferralsState;
  referringPhysicians: ReferringPhysiciansState;
  roles: RolesState;
  tips: TipsState;
  things: ThingsState;
  dashboard: DashboardState;
  nextech: NextechState;
  campaigns: CampaignState;
  list: ListState;
  idle: IdleState;
  nextPriority: NextPriorityState;
  waitList: WaitListState;
};

const appReducer = combineReducers<ReducerType>({
  // external
  form,
  router: connectRouter(routerHistory),
  // app
  app,
  auth: persistReducer(authPersistConfig, auth),
  nav,
  clinics,
  me,
  groups,
  leads,
  locations,
  insurances,
  sources,
  scripts,
  reports,
  timezones,
  users,
  external,
  leadsSatisfaction,
  physicianReferral,
  referringPhysicians,
  roles,
  tips,
  things,
  dashboard,
  nextech,
  campaigns,
  list,
  idle,
  nextPriority,
  waitList,
});

const actions = new Set(['CLEAR_STORE']);

const rootReducer = (state: ReducerType | undefined, action: Action) => {
  if (actions.has(action.type)) {
    const newState = {
      auth: { ...state?.auth, ...initialAuthState },
      me: { ...state?.me, ...initialMeState },
    } as ReducerType;
    return appReducer(newState, action);
  }

  return appReducer(state, action);
};

export default persistReducer(rootPersistConfig, rootReducer);
