import produce, { Draft } from 'immer';

import { ListType } from 'types/list';
import { allList } from './actions';

export type ListState = {
  loading: boolean;
  error: string | undefined;
  items: ListType[];
};

const initialState: ListState = {
  loading: false,
  error: undefined,
  items: [],
};

const reducer = produce((draft: Draft<ListState>, { type, payload }) => {
  switch (type) {
    case allList.TRIGGER:
      draft.loading = true;
      draft.error = undefined;
      break;

    // SUCCESS
    case allList.SUCCESS:
      draft.items = payload;
      break;

    // FAILURE
    case allList.FAILURE:
      draft.error = payload;
      break;

    // FULFILL
    case allList.FULFILL:
      draft.loading = false;
      break;
    // no default
  }
}, initialState);

export default reducer;
