import { FC } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ConnectedRouter } from 'connected-react-router';
import { ToastContainer } from 'react-toastify';
import { ErrorBoundary } from 'react-error-boundary';

import Routes from 'routes';
import routesConfig from 'routes/config';
import { persistor, store } from 'store';
import history from 'store/history';

import ScreenLoading from '@views/base-components/screen-loading';
import SdkLoader from '@views/components/sdk-loader';
import ErrorCallback from '@views/screens/error-callback';

import { GoogleOAuthProvider } from '@react-oauth/google';

const { REACT_APP_GOOGLE_AUTH_CLIENT_ID } = process.env;
const GOOGLE_CLIENT_ID = REACT_APP_GOOGLE_AUTH_CLIENT_ID || '';

const App: FC = () => (
  <ErrorBoundary fallbackRender={ErrorCallback}>
    <Provider store={store}>
      <PersistGate loading={<ScreenLoading />} persistor={persistor}>
        <SdkLoader>
          <ToastContainer />
          <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
            <ConnectedRouter history={history}>
              <Routes store={store} routes={routesConfig} />
            </ConnectedRouter>
          </GoogleOAuthProvider>
        </SdkLoader>
      </PersistGate>
    </Provider>
  </ErrorBoundary>
);

export default App;
