/* eslint-disable no-underscore-dangle */
import io, { Socket } from 'socket.io-client';
import { eventChannel } from 'redux-saga';

const { REACT_APP_WS_BASE_URL } = process.env;

type WSType = {
  _socket: Socket | undefined;
  _token: string | boolean;
  init: () => void;
  connect: () => Promise<any>;
  setToken: (token: string | boolean) => void;
  getWs: () => any;
  createChannel: () => any;
};

const WS: WSType = {
  _socket: undefined,
  _token: false,
  init: async () => {
    try {
      if (!WS._socket) {
        const socket = await WS.connect();
        WS._socket = socket;
      } else if (WS._socket.disconnected) {
        WS._socket.connect();
      }
    } catch {
      return WS._socket;
    }
  },
  connect: async () => {
    if (!WS._token) {
      return;
    }

    WS._socket = io(String(REACT_APP_WS_BASE_URL), {
      transports: ['websocket'],
      auth: (callback) => {
        callback({ token: WS._token });
      },
    });

    return new Promise((resolve) => {
      WS._socket?.on('connect', () => {
        resolve(WS._socket);
      });
    });
  },
  setToken: (token: string | boolean) => {
    if (token) {
      WS._token = token;
    }
  },
  getWs: () => {
    if (WS._socket) return WS._socket;
  },
  createChannel: () =>
    eventChannel((emit) => {
      const handleMessage = (data: any) => emit(data);
      WS._socket?.on('message', handleMessage);

      return () => {
        WS._socket?.off('message', handleMessage);
        WS._socket?.off('disconnect', handleMessage);
        WS._socket?.disconnect();
      };
    }),
};

export default WS;
