import { createTransform } from 'redux-persist';
import localStorage from 'redux-persist/lib/storage';
import localForage from 'localforage';

import { caslCreateAbilityForUser } from '@utils/casl/ability';

const isDebug = process.env.NODE_ENV !== 'production';

const SetTransform = createTransform(
  (inboundState: any) => inboundState,
  (outboundState: any) => {
    const ability = outboundState.ability
      ? caslCreateAbilityForUser(outboundState.ability.A)
      : undefined;
    return { ...outboundState, ability };
  },
  { whitelist: ['me'] },
);

localForage.config({
  name: `medwork-${process.env.NODE_ENV}`,
  storeName: `medwork-${process.env.NODE_ENV}`,
});

export const rootPersistConfig = {
  key: 'root',
  storage: localForage,
  debug: isDebug,
  whitelist: ['me'],
  transforms: [SetTransform],
};

export const authPersistConfig = {
  key: 'auth',
  storage: localStorage,
  debug: isDebug,
  blacklist: ['auth'],
};
