import { bindRoutineToReduxForm, createRoutine } from 'redux-saga-routines';
import { createAction } from 'redux-actions';

import { CREATE_PHYSICIAN_REFERRAL, SET_SHOW_THANK_YOU_PAGE } from './types';

export const createPhysicianReferral = createRoutine(CREATE_PHYSICIAN_REFERRAL);
export const setShowThankYouPage = createAction(SET_SHOW_THANK_YOU_PAGE);

export const bindedCreatePhysicianReferral = bindRoutineToReduxForm(
  createPhysicianReferral,
);
